import { handleActions } from 'redux-actions';
import { ProgressPage } from 'constants';
import {
  ADD_TO_USERCHOICE_CATEGORIES,
  QUANTITY_CHANGE_TO_USERCHOICE_CATEGORY,
  REGISTRATION_ORDER_DATA,
  FETCH_USER_REGISTRATION_DETAILS,
  CHANGE_REGISTRATION_PROGRESS,
  DELETE_REGISTRATION_ORDER,
  CALCULATE_REGISTRATION_CART_SUMMARY,
  UPDATE_REGISTRATION_FETCHED_STATUS,
  GET_CALCULATED_REGISTRATION_CART,
  FETCHING_REGISTRATION_CART_SUMMARY,
  REMOVE_FEES_FROM_CART,
  RESET_CART_ORDER,
  GET_USER_REGISTERED_PARTICIPANTS_DATA,
} from './types';

const initialState = {
  userChoice: {
    categories: [],
  },
  registrationCartSummary: {
    lineItems: [],
    subTotal: {
      currency: 'PHP',
      amountInCent: 0,
      type: '',
    },
    discounts: [],
    total: {
      currency: 'PHP',
      amountInCent: 0,
      type: '',
    },
    fees: [],
    donations: [],
    taxes: [],
  },
  userRegisteredParticipantsData: [],
  fetchingRegistrationCartSummary: false,
  successGetRegistrationCartSummary: true,
  registrationDetails: {
    id: null,
    progress: ProgressPage.numberOfRegistrants,
    paymentStatus: null,
    orderState: null,
    lineItems: [],
    paymentInformation: [],
  },
  registrationCartSummaryFetched: false,
  registrationDetailsFetched: false,
  errors: [],
};

// eslint-disable-next-line
const ACTION_HANDLERS = {
  [ADD_TO_USERCHOICE_CATEGORIES]: {
    next: (state, action) => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        categories: [...state.userChoice.categories, ...action.payload],
      },
    }),
  },
  [QUANTITY_CHANGE_TO_USERCHOICE_CATEGORY]: {
    next: (state, action) => ({
      ...state,
      userChoice: {
        ...state.userChoice,
        categories: [
          ...state.userChoice.categories.filter((category) => action.payload.id !== category.id),
          action.payload,
        ],
      },
    }),
  },
  // [UPSERT_REGISTRANT_DATA]: {
  //   next: (state, action) => {
  //     // let lineItemItems = state?.registrationDetails?.lineItems?.items ?? [];

  //     // const indexOfRegistrantData = action?.payload?.lineItemId ? lineItemItems?.findIndex((item) => item?.id === action?.payload?.lineItemId) : null

  //     // if(indexOfRegistrantData !== null) {
  //     //   lineItemItems = [
  //     //     ...lineItemItems.splice(indexOfRegistrantData, 1),
  //     //     {
  //     //       ...lineItemItems[indexOfRegistrantData],
  //     //       userData
  //     //     }
  //     //   ]
  //     // }

  //     return {
  //       ...state,
  //       registrationDetails: {
  //         ...(state?.registrationDetails ?? {}),
  //         lineItems: {
  //           ...(state?.registrationDetails?.lineItems ?? []),
  //           items: [
  //             ...(state?.registrationDetails?.lineItems?.items ?? []),
  //             // ...(action?.payload?.lineItemId &&  (lineItemItems ?? []).find((item) => item?.id === action?.payload?.lineItemId)
  //             // ),
  //             ...([action?.payload] ?? {}),
  //           ],
  //         },
  //       },
  //       registrationDetailsFetched: true,
  //     };
  //   },
  // },
  [REGISTRATION_ORDER_DATA]: {
    next: (state, action) => ({
      ...state,
      registrationDetails: {
        ...state.registrationDetails,
        ...action.payload,
      },
      registrationDetailsFetched: true,
    }),
  },
  [FETCH_USER_REGISTRATION_DETAILS]: {
    next: (state, action) => ({
      ...state,
      registrationDetails: {
        ...action.payload,
      },
      registrationDetailsFetched: true,
    }),
  },
  [RESET_CART_ORDER]: {
    next: (state, action) => ({
      ...initialState,
    }),
  },
  [CHANGE_REGISTRATION_PROGRESS]: {
    next: (state, action) => ({
      ...state,
      registrationDetails: {
        ...state.registrationDetails,
        progress: action.payload,
      },
      registrationDetailsFetched: true,
    }),
  },
  [DELETE_REGISTRATION_ORDER]: {
    next: (state, action) => ({
      ...initialState,
    }),
  },
  [UPDATE_REGISTRATION_FETCHED_STATUS]: {
    next: (state, action) => ({
      ...state,
      registrationDetailsFetched: action.payload,
    }),
  },
  [CALCULATE_REGISTRATION_CART_SUMMARY]: {
    next: (state, action) => ({
      ...state,
      registrationCartSummary: action.payload,
    }),
  },
  [GET_CALCULATED_REGISTRATION_CART]: {
    next: (state, action) => ({
      ...state,
      registrationCartSummary: action.payload.cartData,
      fetchingRegistrationCartSummary: false,
      successGetRegistrationCartSummary: action.payload.success || false,
    }),
  },
  [REMOVE_FEES_FROM_CART]: {
    next: (state, action) => ({
      ...state,
      registrationCartSummary: {
        ...state.registrationCartSummary,
        fees: [],
      },
      fetchingRegistrationCartSummary: false,
      successGetRegistrationCartSummary: action.payload.success || false,
    }),
  },
  [FETCHING_REGISTRATION_CART_SUMMARY]: {
    next: (state, action) => ({
      ...state,
      fetchingRegistrationCartSummary: action.payload.state,
      successGetRegistrationCartSummary: action.payload.stateSuccess || false,
    }),
  },
  [GET_USER_REGISTERED_PARTICIPANTS_DATA]: {
    next: (state, action) => ({
      ...state,
      userRegisteredParticipantsData: action.payload.list || [],
    }),
  },
};

export default handleActions(ACTION_HANDLERS, initialState);
