import { AgeGap } from 'constants/index';

export const JuniorAge = 17;

export const determineAgeGroup = (age = 1, rangeGap = AgeGap.FIVE) => {
  if (age <= JuniorAge) {
    return {
      startRange: JuniorAge,
      endRange: 8,
    };
  }

  if (age > 17 && age < 21) {
    return {
      startRange: 18,
      endRange: rangeGap === AgeGap.FIVE ? 24 : 29,
    };
  }

  const startRange = Math.floor((age - 1) / rangeGap) * rangeGap;
  const endRange = startRange + rangeGap - 1;

  return {
    startRange,
    endRange,
  };
};

// Examples
// console.log(determineAgeGroup(24, 5)); // Output: "20 to 24"
// console.log(determineAgeGroup(25, 5)); // Output: "25 to 29"
// console.log(determineAgeGroup(32, 5)); // Output: "30 to 34"
// console.log(determineAgeGroup(19, 5)); // Output: "19 to 24"
// console.log(determineAgeGroup(17, 5)); // Output: "18 and below"
// console.log(determineAgeGroup(18, 10)); // Output: "18 to 27"
// console.log(determineAgeGroup(49, 10)); // Output: "40 to 49"
// console.log(determineAgeGroup(17, 10)); // Output: "18 and below"
// console.log(determineAgeGroup(60, 10)); // Output: "60 to 69"
