import { AppConstants, AttestationValues, Attestations, PRECEDENCE_CONSTANTS } from 'constants/index';

export const getAttestationByKey = (key = Attestations.PRIVACY_POLICY) => {
  return Object.keys(Attestations).includes(key)
    ? AttestationValues[key]
    : AttestationValues[Attestations.PRIVACY_POLICY];
};

export const getPrecedingForConstant = (data) => {
  return PRECEDENCE_CONSTANTS(data)?.[AppConstants.DATA_SHARING_AGREEMENT] || null;
};

export const getConstantValue = (appConstants, constantName, data) => {
  const precedenceValue = getPrecedingForConstant(data);

  return `${precedenceValue ? `${precedenceValue} \n\n ` : ''}${appConstants?.[constantName] || ''}`;
};
