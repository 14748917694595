import * as yup from 'yup';

export function createYupSchema(schema, config) {
  const { fieldId, validationType, validations = [] } = config;
  if (!yup[validationType]) {
    return schema;
  }
  let validator = yup[validationType]();
  validations.forEach((validation) => {
    const { params, type } = validation;
    const parsedParams = typeof params === 'string' ? JSON.parse(params) : params;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...parsedParams);
  });
  schema[fieldId] = validator;
  return schema;
}
