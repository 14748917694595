import { userErrorsArray, userSuccessArray } from 'modules/user/constants';
import { eventErrorsArray } from 'modules/events/constants';

const toasts = [...userErrorsArray, ...userSuccessArray, ...eventErrorsArray];

export const toastMapper = (errorType) => toasts.find((error) => error.type === errorType);

export const checkAlertType = (alertType) => {
  const stringType = alertType?.replaceAll('_', ' ') || '';

  const toastType = stringType?.toLowerCase() || '';
  if (toastType.includes('info')) return 'info';
  if (toastType.includes('error')) return 'error';
  if (toastType.includes('success')) return 'success';
  if (toastType.includes('warning')) return 'warning';

  return 'error';
};
