import { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Card, Button, Typography, CardHeader, CardContent } from '@mui/material';

import { updateUserData } from 'modules/user/actions';
import Iconify from 'components/Iconify';
import AddressForm from 'components/AddressForm';

// ----------------------------------------------------------------------

CheckoutBillingShippingAddress.propTypes = {
  billingShippingAddress: PropTypes.object,
};

export default function CheckoutBillingShippingAddress({ billingShippingAddress, setEnableNextCard, cardNumber }) {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);

  const handleEditClick = useCallback(() => {
    setEditable((prev) => !prev);
  }, []);

  const handleAddressFormSubmit = useCallback(async (values) => {
    const response = await dispatch(updateUserData({ ...values, primaryAddress: false, billingShippingAddress: true }));
    return response;
  }, []);

  useEffect(() => {
    if (billingShippingAddress?.addressLine1) {
      setEnableNextCard((prev) => (cardNumber === prev ? Number(prev) + 1 : prev));
      setEditable(false);
    }
  }, [billingShippingAddress, cardNumber]);

  const additionalActionOnSave = useCallback(() => {
    setEnableNextCard((prev) => (cardNumber === prev ? Number(prev) + 1 : prev));
    setEditable(false);
  }, []);

  return (
    <Card sx={{ mb: 3 }}>
      <CardHeader
        title="Billing & Shipping Address"
        action={
          <>
            {billingShippingAddress?.state && (
              <Button
                size="small"
                startIcon={<Iconify icon={!editable ? 'eva:edit-fill' : 'ant-design:close-circle-outlined'} />}
                onClick={handleEditClick}
              >
                {editable ? 'Close' : 'Edit'}
              </Button>
            )}
          </>
        }
      />
      <CardContent>
        {!editable && billingShippingAddress?.state ? (
          <BillingShippingAddress billingShippingAddress={billingShippingAddress} />
        ) : (
          <AddressForm
            address={billingShippingAddress}
            additionalActionOnSave={additionalActionOnSave}
            handleOnSubmit={handleAddressFormSubmit}
            columnView
          />
        )}
      </CardContent>
    </Card>
  );
}

const BillingShippingAddress = ({ billingShippingAddress }) => {
  return (
    <>
      {/* Street Address */}
      <Typography variant="subtitle2" gutterBottom>
        Street:&nbsp;
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
          {billingShippingAddress?.addressLine1 || ''}
        </Typography>
      </Typography>

      {/* Barangay Address */}
      <Typography variant="subtitle2" gutterBottom>
        Barangay:&nbsp;
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
          {billingShippingAddress?.barangay || ''}
        </Typography>
      </Typography>

      {/* City Address */}
      <Typography variant="subtitle2" gutterBottom>
        City:&nbsp;
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
          {billingShippingAddress?.city || ''}
        </Typography>
      </Typography>

      {/* State Address */}
      <Typography variant="subtitle2" gutterBottom>
        State:&nbsp;
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
          {billingShippingAddress?.state || ''}
        </Typography>
      </Typography>

      {/* Region Address */}
      <Typography variant="subtitle2" gutterBottom>
        Region:&nbsp;
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
          {billingShippingAddress?.region || ''}
        </Typography>
      </Typography>

      {/* Country Address */}
      <Typography variant="subtitle2" gutterBottom>
        Country:&nbsp;
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
          {billingShippingAddress?.country || ''}
        </Typography>
      </Typography>
    </>
  );
};
