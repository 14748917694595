// @mui
import { Box, Skeleton, Grid } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonCart() {
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          display: 'grid',
          columnGap: 1.2,
          rowGap: 0,
          gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
        }}
      >
        <Skeleton variant="rectangular" width="90%" sx={{ height: 50, borderRadius: 2, m: 1 }} />
        <Skeleton variant="rectangular" width="90%" sx={{ height: 50, borderRadius: 2, m: 1 }} />
        <Skeleton variant="rectangular" width="90%" sx={{ height: 50, borderRadius: 2, m: 1 }} />
        <Skeleton variant="rectangular" width="90%" sx={{ height: 50, borderRadius: 2, m: 1 }} />
        <Skeleton variant="rectangular" width="90%" sx={{ height: 50, borderRadius: 2, m: 1 }} />
        <Skeleton variant="rectangular" width="90%" sx={{ height: 50, borderRadius: 2, m: 1 }} />
        <Skeleton variant="rectangular" width="90%" sx={{ height: 50, borderRadius: 2, m: 1 }} />
        <Skeleton variant="rectangular" width="90%" sx={{ height: 50, borderRadius: 2, m: 1 }} />
        <Skeleton variant="rectangular" width="90%" sx={{ height: 50, borderRadius: 2, m: 1 }} />
      </Box>
    </Grid>
  );
}
