// organization
export const GET_ORGANIZATION = 'organization/GET_ORGANIZATION';
export const IS_FETCHED_ORGANIZATION_DATA = 'organization/IS_FETCHED_ORGANIZATION_DATA';
export const CREATE_ORGANIZATION = 'organization/CREATE_ORGANIZATION';
export const DELETE_ORGANIZATION = 'organization/DELETE_ORGANIZATION';
export const FETCH_ORGANIZATION_DASHBOARD_DATA = 'organization/FETCH_ORGANIZATION_DASHBOARD_DATA';
export const IS_FETCHED_ORGANIZATION_DASHBOARD_DATA = 'organization/IS_FETCHED_ORGANIZATION_DASHBOARD_DATA';
export const UPDATE_ORGANIZATION_DATA = 'organization/UPDATE_ORGANIZATION_DATA';
export const SET_UPDATING_ORGANIZATION = 'organization/SET_UPDATING_ORGANIZATION';

export const GET_USER_LIST_OF_ORGANIZATIONS = 'organization/GET_USER_LIST_OF_ORGANIZATIONS';
export const UPDATE_USER_STATUS_ON_SPECIFIC_EVENT = 'organization/UPDATE_USER_STATUS_ON_SPECIFIC_EVENT';
export const REMOVE_USER_TO_AN_EVENT = 'organization/REMOVE_USER_TO_AN_EVENT';
export const ADD_USER_TO_AN_ORGANIZATION = 'organization/ADD_USER_TO_AN_ORGANIZATION';
export const ADD_USER_TO_AN_EVENT = 'organization/ADD_USER_TO_AN_EVENT';
export const REMOVE_USER_TO_AN_ORGANIZATION = 'organization/REMOVE_USER_TO_AN_ORGANIZATION';
export const SET_SELECTED_ORGANIZATION_ID = 'organization/SET_SELECTED_ORGANIZATION_ID';
export const SET_SELECTED_EVENT_ID = 'organization/SET_SELECTED_EVENT_ID';
export const GET_ORGANIZATION_STAFF_LIST = 'organization/GET_ORGANIZATION_STAFF_LIST';
export const REMOVE_SELECTED_ORGANIZATION_ID = 'organization/REMOVE_SELECTED_ORGANIZATION_ID';
export const REMOVE_SELECTED_ORGANIZATION_EVENT_ID = 'organization/REMOVE_SELECTED_ORGANIZATION_EVENT_ID';
export const RESET_SELECTED_ORGANIZATION = 'organization/RESET_SELECTED_ORGANIZATION';
export const SET_ORGANIZATION_STAFF = 'organization/SET_ORGANIZATION_STAFF';
export const ORGANIZATION_STAFF_IS_FETCHED = 'organization/ORGANIZATION_STAFF_IS_FETCHED';
export const UPDATE_STAFF_DETAILS = 'organization/UPDATE_STAFF_DETAILS';
export const UPDATE_STAFF_EVENT_ROLE = 'organization/UPDATE_STAFF_EVENT_ROLE';
export const GET_STAFF_EVENTS_LIST = 'organization/GET_STAFF_EVENTS_LIST';
export const FETCHING_ORGANIZATION_STAFF = 'organization/FETCHING_ORGANIZATION_STAFF';
// events
export const SET_IS_FETCHED_ORGANIZATION_EVENTS = 'organization/SET_IS_FETCHED_ORGANIZATION_EVENTS';
export const FETCH_ORGANIZATION_EVENTS = 'organization/FETCH_ORGANIZATION_EVENTS';
export const SEARCH_ORGANIZATION_STAFFS = 'organization/SEARCH_ORGANIZATION_STAFFS';
export const CREATE_NEW_EVENT = 'organization/CREATE_NEW_EVENT';
export const UPDATE_AN_EVENT = 'organization/UPDATE_AN_EVENT';
export const DELETE_AN_EVENT = 'organization/DELETE_AN_EVENT';
export const ADD_EVENT_TO_EVENT_LISTS = 'organization/ADD_EVENT_TO_EVENT_LISTS';

// others
export const UPDATE_IMAGE_LINK = 'others/UPDATE_IMAGE_LINK';
export const UPDATE_FILE_LINK = 'others/UPDATE_FILE_LINK';
export const GET_FILE_LINK_BY_ID = 'others/GET_FILE_LINK_BY_ID';
