import { createSelector } from 'reselect';
import { getLocalStorageItem, hasCreatorRole } from 'utils/index';
import { localStorageVariable, OrganizationStaffRoleType } from 'constants/index';
import { userOrganizationsSelector, userSubscriptionSelector } from 'modules/user/selector';
import { eventStaffsSelector } from 'modules/event/selector';

export const rootSelector = createSelector(
  (state) => state.organization,
  (organization) => organization
);

export const isInitializedSelector = createSelector(rootSelector, (organization) => organization?.isInitialized);

export const selectedOrganizationIdSelector = createSelector(
  rootSelector,
  (organization) =>
    organization?.selectedOrganizationId || getLocalStorageItem(localStorageVariable.selectedOrganizationId)
);

export const selectedEventIdSelector = createSelector(
  rootSelector,
  (organization) => organization?.selectedEventId || getLocalStorageItem(localStorageVariable.selectedEventId)
);

export const selectedOrganizationSelector = createSelector(rootSelector, (organization) => organization.organization);

export const organizationDataSelector = createSelector(
  selectedOrganizationSelector,
  (organization) => organization?.data
);

export const fetchedOrgIdSelector = createSelector(organizationDataSelector, (data) => data?.id || '');

export const isUserOrgAdminSelector = createSelector(
  selectedOrganizationSelector,
  (organization) => organization?.isUserAdmin
);

export const organizationProfilePhotoSelector = createSelector(
  organizationDataSelector,
  (data) => data?.organizationProfilePhotoURLs?.items?.[0] || {}
);

export const selectedOrganizationsListSelector = createSelector(
  rootSelector,
  (organization) => organization?.organizationsList
);

export const organizationStaffsSelector = createSelector(
  rootSelector,
  (organization) => organization?.organizationStaffs
);

export const organizationStaffMemberListSelector = createSelector(organizationStaffsSelector, (staffsList) =>
  staffsList?.filter((staff) => staff?.roles?.some((role) => role?.role === OrganizationStaffRoleType.MEMBER))
);

export const organizationStaffAdminListSelector = createSelector(organizationStaffsSelector, (staffsList) =>
  staffsList?.filter((staff) =>
    staff?.roles?.some(
      (role) => role?.role === OrganizationStaffRoleType.ADMIN || role?.role === OrganizationStaffRoleType.CREATOR
    )
  )
);

export const organizationStaffSelector = createSelector(
  rootSelector,
  (organization) => organization?.organizationStaff || {}
);

export const isOrganizationStaffCreatorSelector = createSelector(organizationStaffSelector, (staff) =>
  hasCreatorRole(staff?.roles)
);

export const staffEventListSelector = createSelector(organizationStaffSelector, (staff) => staff?.staffEventList || []);

export const isUserAnOrganizationAdminSelector = createSelector(
  [selectedOrganizationIdSelector, userOrganizationsSelector],
  (id, organizations) => {
    const isAdmin = organizations
      ?.find((organization) => organization?.organization?.id === id)
      ?.roles?.some(
        (role) => role?.role === OrganizationStaffRoleType.ADMIN || role?.role === OrganizationStaffRoleType.CREATOR
      );
    return isAdmin || false;
  }
);

export const organizationStaffIsFetchedSelector = createSelector(
  rootSelector,
  (organization) => organization.organizationStaffIsFetched
);
export const organizationDataIsFetchedSelector = createSelector(
  rootSelector,
  (organization) => organization?.organizationDataFetched
);

export const selectedEventSelector = createSelector(rootSelector, (organization) => organization?.event);

export const getUserOrganizationByIdSelector = (id) =>
  createSelector(userOrganizationsSelector, (userOrganizations) =>
    userOrganizations?.find((organization) => organization?.organizationId === id)
  );

export const isOrganizationDashboardDataFetchedSelector = createSelector(
  rootSelector,
  (organization) => organization?.isDashboardFetched
);
export const organizationDashboardDataSelector = createSelector(
  rootSelector,
  (organization) => organization?.dashboard
);
export const organizationOdooDataSelector = createSelector(
  rootSelector,
  (organization) => organization?.organization?.odooData
);

// Events
export const organizationEventsSelector = createSelector(rootSelector, (organization) => organization.events);

export const organizationSubscriptionSelector = createSelector(organizationDataSelector, (data) => {
  return data?.subscription;
});

export const organizationNameSelector = createSelector(organizationDataSelector, (data) => {
  return data?.organizationName;
});

export const organizationSubscriptionAllowableEventsSelector = createSelector(
  organizationSubscriptionSelector,
  (subscription) => {
    return subscription?.events;
  }
);

export const organizationExceedsEventCreationLimitsSelector = createSelector(
  [organizationSubscriptionAllowableEventsSelector, organizationEventsSelector],
  (allowable, events) => {
    return (events?.list?.length || 0) >= allowable;
  }
);

export const organizationSubscriptionAllowableStaffSelector = createSelector(
  organizationSubscriptionSelector,
  (subscription) => {
    return subscription?.organizationStaff;
  }
);

export const organizationExceedsStaffCreationLimitsSelector = createSelector(
  [organizationSubscriptionAllowableStaffSelector, organizationStaffsSelector],
  (allowable, staffs) => {
    return (staffs?.length || 0) >= allowable;
  }
);

export const eventSubscriptionAllowableStaffSelector = createSelector(userSubscriptionSelector, (subscription) => {
  return subscription?.eventStaffs;
});

export const eventExceedsStaffCreationLimitsSelector = createSelector(
  [eventSubscriptionAllowableStaffSelector, eventStaffsSelector],
  (allowable, staffs) => {
    return (staffs?.length || 0) >= allowable;
  }
);
