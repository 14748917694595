import { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { upsertRegistrantData } from 'modules/registration/actions';
import { sortForms, sortFormsByLatestUpdate } from 'utils';
import { defaultCategoryForm } from 'constants';

export const useRegistrationFormHook = ({ lineItemCategory, category, comesFromAdmin = false }) => {
  const dispatch = useDispatch();
  const [openForm, setOpenForm] = useState(1); // Open first collapsable

  const registrantsData = lineItemCategory?.registrantData?.items || [];

  const sortedCategoryFormByFormNumber = sortForms(
    category?.form?.items?.length ? category?.form?.items : [defaultCategoryForm]
  );

  // TODO display only one Form and sort it to most updated as sometimes setting up for form in admins side has some issues in deleting
  // FIXME Redo this logic once multiple forms are implemented

  const categoryForms = [sortFormsByLatestUpdate(sortedCategoryFormByFormNumber)?.[0]];

  const categoryFormFields = useCallback(
    (form) => form?.formFields?.sort((a, b) => Number(a?.orderNumber || 0) - Number(b?.orderNumber || 0)) || [],
    []
  );

  const onSubmitActionHandler = useCallback(
    async (values, valuesHasChanged, formNumber, registrantDataId, teamTitleHasChanged) => {
      await dispatch(
        upsertRegistrantData(
          values,
          valuesHasChanged,
          formNumber,
          lineItemCategory?.id || '',
          category?.id,
          registrantDataId,
          teamTitleHasChanged,
          registrantsData?.map((item) => item?.id),
          comesFromAdmin
        )
      ).then(() => {
        setOpenForm((prevVal) => (categoryForms?.length <= prevVal + 1 ? prevVal + 1 : 1));
      });
    },
    [dispatch, lineItemCategory, category, comesFromAdmin]
  );

  return {
    onSubmitActionHandler,
    registrantsData,
    openForm,
    setOpenForm,
    categoryFormFields,
    categoryForms,
  };
};
