// @mui
import { Grid, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonEvents() {
  return (
    <Grid container spacing={3}>
      {[...Array(9)].map((val, index) => (
        <Grid key={index} item xs={12} sm={6} md={4}>
          <Skeleton variant="rectangular" width="65%" sx={{ paddingTop: '90%', borderRadius: 2 }} />
        </Grid>
      ))}
    </Grid>
  );
}
