import * as Yup from 'yup';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from 'components/Iconify';
import { Routes } from 'constants';
import { getPath } from 'utils/routes';
import { signInUser } from 'modules/user/actions';

const emptyFunc = () => {};

export const LoginForm = ({
  userEmail,
  hasOwnRedirect = false,
  shouldRedirectTo = emptyFunc(),
  showForgotPassword = true,
  showConfirmEmail = true,
  forgotPasswordRedirect = null,
  confirmEmailRedirect = null,
  noAutoFocus = false,
  redirectPath = '',
}) => {
  const { id: eventId = null, title: eventName = null } = useParams();

  const locationParams = { eventId, eventName };

  const dispatch = useDispatch();

  const [reduxEmail] = useState(userEmail);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setErrors] = useState('');

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().min(8, 'Should be greater than 8 characters!').required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: reduxEmail || '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, setSubmitting) => {
      const response = await dispatch(
        signInUser(values.email, values.password, hasOwnRedirect, locationParams, { redirectPath })
      );
      setErrors(response.payload.type === 'Error' ? response.payload.message : '');
      await shouldRedirectTo();
      setSubmitting(false);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const showRedirectLinks = showForgotPassword || showConfirmEmail;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            autoFocus={!reduxEmail && !noAutoFocus}
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            autoFocus={Boolean(reduxEmail)}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          {/* TODO remember me functionality */}
          {/* <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          /> */}
          {showRedirectLinks && (
            <>
              {showForgotPassword && (
                <Link
                  component={RouterLink}
                  variant="subtitle2"
                  {...(forgotPasswordRedirect
                    ? { onClick: forgotPasswordRedirect }
                    : { to: getPath(Routes.FORGOTPASSWORD) })}
                  underline="hover"
                >
                  Forgot password?
                </Link>
              )}
              {showConfirmEmail && (
                <Link component={RouterLink} variant="subtitle2" to={getPath(Routes.VERIFICATION)} underline="hover">
                  Confirm Email here!
                </Link>
              )}
            </>
          )}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!isValid}
        >
          Sign In
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
};

LoginForm.propTypes = {
  userEmail: PropTypes.string,
};
