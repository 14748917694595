import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, Dialog, Typography, DialogContent, DialogActions, Button } from '@mui/material';
import { EventPage } from 'pages/EventPage';
import { getEventById } from 'modules/events/actions';
import { eventDataSelector } from 'modules/events/selector';
import { useCallback } from 'react';
import { fetchedEventIdSelector } from 'modules/event/selector';

// ----------------------------------------------------------------------

PreviewEvent.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function PreviewEvent({ open, onClose }) {
  const dispatch = useDispatch();

  const event = useSelector(eventDataSelector);
  const eventId = useSelector(fetchedEventIdSelector);

  const handleGetEventById = useCallback(async () => {
    if (eventId) {
      await dispatch(getEventById(eventId));
    }
  }, [dispatch, eventId]);

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <Stack spacing={2} sx={{ p: 2.5, pb: 1 }}>
        <Typography variant="h6">
          <Typography component="span">Event Preview</Typography>
        </Typography>
      </Stack>
      <DialogContent>
        <EventPage event={event} getEventById={handleGetEventById} orgEventId={eventId} isPreview />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
