import moment from 'moment-timezone';
import { EVENT_STATUS } from '../../../constants/common/index';

export const createRacebibPreFixCreator = (distance) => {
  const stringDistance = distance.toString();
  let prefix = stringDistance.substring(0, 1);

  // Distance is all numbers
  const isNumeric = /^\d+$/.test(stringDistance);
  if (isNumeric) {
    prefix =
      stringDistance?.length >= 3
        ? stringDistance.substring(0, 3)
        : stringDistance?.length === 2
        ? stringDistance.substring(0, 2)
        : stringDistance.substring(0, 1);
  } else if (distance?.length) {
    const abbreviation = stringDistance
      .match(/\b(\w)/g)
      .join('')
      .toUpperCase();
    prefix = abbreviation;
  }

  return prefix;
};

export const doesArrayContainObject = (array, obj) => {
  // eslint-disable-next-line
  for (let i = 0; i < array.length; i++) {
    if (areObjectsEqual(array[i], obj)) {
      return true;
    }
  }
  return false;
};

export const areObjectsEqual = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }
  // eslint-disable-next-line
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const checkEventAvailability = (
  eventDate,
  regEndDate,
  categories,
  slotsAvailabilityIsSameForAllCategories,
  options
) => {
  // 1. Check event Availability for registration
  // 1.1 Date of event (PAST, INCOMING)
  // 1.2 Stocks Availability
  // 1.3 Prices availability - not applicable as of the moment

  const response = {
    isAvailable: false,
    status: EVENT_STATUS.CLOSE,
  };

  const registrationEndDate = getRegistrationEndDate(regEndDate, eventDate);

  // 1.1 Date availability
  const { value } = statusChecker(registrationEndDate, eventDate);
  if (value === EVENT_STATUS.CLOSE || value === EVENT_STATUS.DONE) {
    response.isAvailable = false;
    response.status = value;
    return response;
  }

  if (options?.categoryId) {
    const availableSlot = totalSlotsAndRegistrants(
      categories,
      slotsAvailabilityIsSameForAllCategories,
      options?.categoryId
    )?.availableSlots;
    response.isAvailable = availableSlot > 0;
    response.status = availableSlot > 0 ? EVENT_STATUS.OPEN : EVENT_STATUS.NO_SLOTS_AVAILABLE;
    return response;
  }

  // 1.2 Stocks Availability
  const categoriesAvailability = categories?.map((category) =>
    isCategorySlotsAvailable(categories, category, slotsAvailabilityIsSameForAllCategories)
  );

  if (categories && categories?.length && !categoriesAvailability?.includes(true)) {
    response.isAvailable = false;
    response.status = EVENT_STATUS.NO_SLOTS_AVAILABLE;
    return response;
  }

  return {
    isAvailable: true,
    status: EVENT_STATUS.OPEN,
  };
};

export const getRegistrationEndDate = (registrationEndDate, dateOfEvent) => {
  return moment(registrationEndDate || dateOfEvent);
};

export const getRegistrationStartDate = (registrationStartDate, eventCreaedAt) => {
  return moment(registrationStartDate || eventCreaedAt);
};

export const statusChecker = (regEndDate, eventDate) => {
  const registrationEndDate = getRegistrationEndDate(regEndDate, eventDate);

  const dateOfEventDuration = moment.duration(moment(eventDate).diff(moment()));
  const dateOfEventDurationInHours = dateOfEventDuration.asHours();
  const regEndAndTodayDiff = moment.duration(registrationEndDate.diff(moment()));
  const regEndAndTodayDiffInHours = regEndAndTodayDiff.asHours();

  if (dateOfEventDurationInHours <= 0) return { value: EVENT_STATUS.DONE, color: 'info' };
  if (regEndAndTodayDiffInHours > 0) return { value: EVENT_STATUS.OPEN, color: 'success' };
  return { value: EVENT_STATUS.CLOSE, color: 'error' };
};

export const isCategorySlotsAvailable = (categories, category, slotsAvailabilityIsSameForAllCategories) => {
  const test =
    totalSlotsAndRegistrants(categories, slotsAvailabilityIsSameForAllCategories, category?.id)?.availableSlots > 0;
  return test;
};

export const slotsAvailabilityCalculator = (slots) =>
  Number.parseInt(slots?.totalSlotsAvailable || 0, 10) - Number.parseInt(slots?.totalRegistrants || 0, 10);

export const totalSlotsAvailableCalculator = (categories, slotsAvailabilityIsSameForAllCategories) => {
  if (slotsAvailabilityIsSameForAllCategories) {
    return totalSlotsAndRegistrants(categories, slotsAvailabilityIsSameForAllCategories, categories?.[0]?.id)
      ?.availableSlots; // Total slots is same for all categories
  }

  return categories
    ?.map(
      (category) =>
        totalSlotsAndRegistrants(categories, slotsAvailabilityIsSameForAllCategories, category?.id)?.availableSlots
    )
    ?.reduce((acc, item) => acc + item, 0);
};

export const getTheSmallestSlotsCount = (categories) =>
  Math.min(
    ...(categories?.length ? categories?.map((cat) => Number.parseInt(cat?.slots?.totalSlotsAvailable, 10)) : [])
  );

export const getCategoryTotalSlots = (categories, slotSameForAllCat, categoryId) => {
  if (slotSameForAllCat) {
    return getTheSmallestSlotsCount(categories);
  }

  if (categoryId && !slotSameForAllCat) {
    return categories?.find((cat) => cat?.id === categoryId)?.slots?.totalSlotsAvailable;
  }

  return categories?.[0]?.slots?.totalSlotsAvailable;
};

export const totalSlotsAndRegistrants = (categories, slotSameForAllCat, categoryId) => {
  const totalRegistrants = slotSameForAllCat
    ? getTotalRegistrantsForAllCategories(categories)
    : getTotalRegistrantsForCategory(categories, categoryId);
  const totalSlots = getCategoryTotalSlots(categories, slotSameForAllCat, categoryId);
  const availableSlots = totalSlots - totalRegistrants;

  return {
    totalRegistrants,
    totalSlots,
    availableSlots,
  };
};
export const getTotalRegistrantsForCategory = (categories, categoryId = '') => {
  const total = categories?.find((cat) => cat?.id === categoryId)?.slots?.totalRegistrants;
  return total;
};

export const getTotalRegistrantsForAllCategories = (categories = []) => {
  const total = categories?.reduce((acc, cat) => acc + Number.parseInt(cat?.slots?.totalRegistrants || 0, 10), 0);
  return total;
};

export const getOrganizerProfile = (organizers, Type) => {
  const type = Array.isArray(Type) ? Type : [Type];
  const filteredByType = organizers?.items?.filter((item) => item?.roles?.some((role) => type?.includes(role)));

  if (type?.length === 1) {
    return filteredByType?.[0]?.user || null;
  }

  return filteredByType;
};
