// Values should have ERROR | INFO | SUCCESS | WARNING
export const EventErrorTypes = {
  fetchingEventsError: 'FETCHING_EVENTS_ERROR',
  searchingEventsError: 'FETCHING_EVENTS_ERROR',
};

export const EventErrorMessages = {
  searchingEventsErrorMessage: "We're having trouble searching for events, Please refresh the page or try again later",
  fetchingEventsErrorMessage: "We're having trouble fetching Events, Please refresh the page or try again later",
};

export const eventErrorsArray = [
  {
    type: EventErrorTypes.fetchingEventsError,
    message: EventErrorMessages.fetchingEventsErrorMessage,
  },
  {
    type: EventErrorTypes.searchingEventsError,
    message: EventErrorMessages.searchingEventsErrorMessage,
  },
];
