import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './DashboardNavbar';
// import DashboardSidebar from './DashboardSidebar';
import { NavbarVertical } from './navbar/NavbarVertical';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

export default function DashboardLayout({ children: Component, shouldShowNavBar = true }) {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      {/* <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} /> */}
      {shouldShowNavBar && <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />}
      <MainStyle>{Component}</MainStyle>
    </RootStyle>
  );
}

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
