import { Scopes, Roles, Subscription, SubscriptionType } from 'constants/index';

// Key: e.g. ADMIN, MEMBER
// Value: e.g. Admin. Member
export const roleObjectCreator = (role, isRoleKey = false) => {
  const { scope, descriptions } = getRoleScope(role, isRoleKey);
  return {
    role: isRoleKey ? getRoleByKeyOrValue(null, role).value : role,
    scope: [scope],
    descriptions,
  };
};

export const getRoleScope = (role, isRoleKey = false) => {
  const returnVal = {
    scope: Scopes[Roles.MEMBER].type,
    descriptions: Scopes[Roles.MEMBER].description,
  };
  if (!role) {
    return returnVal;
  }
  let setRole = role;

  if (isRoleKey) {
    setRole = getRoleByKeyOrValue(null, role).value;
  }

  returnVal.scope = Scopes[setRole].type;
  returnVal.descriptions = Scopes[setRole].description;

  return returnVal;
};

export const getRoleByKeyOrValue = (roleValue, roleKey = null) =>
  Object.entries(Roles)
    ?.map(([key, value]) => ({ key, value }))
    ?.find((item) => (roleKey ? item.key === roleKey : item.value === roleValue));
