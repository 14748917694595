import { defaultCategoryForm } from 'constants/index';
import { handleActions } from 'redux-actions';
import {
  FETCH_EVENT_DASHBOARD_DATA,
  IS_EVENT_DASHBOARD_DATA_FETCHED,
  IS_EVENT_DASHBOARD_DATA_FETCHING,
  EVENT_DATA_STATE,
  FETCH_EVENT_DATA,
  RESET_SELECTED_EVENT,
  FETCH_EVENT_REGISTRANTS,
  IS_FETCHING_EVENT_REGISTRANTS,
  GET_EVENT_REGISTRATION_ORDER_DETAILS,
  IS_EVENT_REGISTRATION_ORDER_DETAILS_FETCHED,
  FETCH_EVENT_STAFFS,
  SET_FETCHING_STATE,
  GET_EVENT_STAFF,
  EVENT_STAFF_FETCHING_STATE,
  UPDATE_STAFF_EVENT_ROLE,
  FETCH_EVENT_CATEGORIES,
  FETCH_EVENT_CATEGORY,
  CREATE_NEW_CATEGORY,
  UPDATE_DRAFT_FORMS,
  UPDATE_CATEGORY_FORMS,
  RESET_DRAFT_FORMS,
  UPDATE_CATEGORY_WAVIER,
  PUBLISH_EVENT,
} from './types';

export const initialState = {
  event: {
    data: {
      categories: {
        items: [],
      },
    },
    odooData: null,
    isUserAdmin: false,
  },
  registrants: {
    list: [],
    nextToken: null,
  },
  categories: [],
  category: null,
  categoryForms: null,
  draftCategoryForms: null,
  orderRegistrationDetails: null,
  dashboard: {
    registrantsCount: 0,
    totalAmountAccumulated: 0,
    totalNumberOfCategories: 0,
    balances: {
      currentBalance: 0,
      totalCashout: 0,
    },
    registrantsOverview: [],
  },
  staffs: [],
  staff: null,
  isStaffFetched: false,
  isStaffFetchSuccess: false,
  orderRegistrationDetailsFetched: false,
  eventDataIsFetchedSuccess: false,
  eventDataIsFetching: false,
  isEventRegistrationsFetching: false,
  isEventStaffsFetched: false,
  isEventStaffsFetchSuccess: false,
  isEventDashboardDataFetched: false,
  isEventDashboardDataFetching: false,
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [FETCH_EVENT_DASHBOARD_DATA]: {
    next: (state, action) => ({
      ...state,
      dashboard: action.payload || initialState.dashboard,
    }),
  },
  [FETCH_EVENT_DATA]: {
    next: (state, action) => {
      return {
        ...state,
        event: {
          data: action.payload.data || null,
          odooData: action.payload.odooData || null,
          roles: action.payload?.roles || [],
          isUserAdmin: action.payload.isUserAdmin || false,
        },
      };
    },
  },
  [EVENT_DATA_STATE]: {
    next: (state, action) => ({
      ...state,
      eventDataIsFetchedSuccess: action.payload.success || false,
      eventDataIsFetching: action.payload.isFetching || false,
    }),
  },
  [IS_EVENT_DASHBOARD_DATA_FETCHED]: {
    next: (state, action) => ({
      ...state,
      isEventDashboardDataFetched: action.payload || false,
    }),
  },
  [IS_EVENT_DASHBOARD_DATA_FETCHING]: {
    next: (state, action) => ({
      ...state,
      isEventDashboardDataFetching: action.payload || false,
    }),
  },
  [RESET_SELECTED_EVENT]: {
    next: (state, action) => ({
      ...initialState,
    }),
  },
  [FETCH_EVENT_REGISTRANTS]: {
    next: (state, action) => ({
      ...state,
      registrants: {
        list: action.payload?.list || [],
        ...(action.payload?.nextToken && { nextToken: action.payload?.nextToken }),
      },
      isEventRegistrationsFetching: true,
    }),
  },
  [IS_FETCHING_EVENT_REGISTRANTS]: {
    next: (state, action) => ({
      ...state,
      isEventRegistrationsFetching: action.payload,
    }),
  },
  [GET_EVENT_REGISTRATION_ORDER_DETAILS]: {
    next: (state, action) => ({
      ...state,
      orderRegistrationDetails: action.payload || null,
      orderRegistrationDetailsFetched: true,
    }),
  },
  [IS_EVENT_REGISTRATION_ORDER_DETAILS_FETCHED]: {
    next: (state, action) => ({
      ...state,
      orderRegistrationDetailsFetched: action.payload,
    }),
  },
  [FETCH_EVENT_STAFFS]: {
    next: (state, action) => ({
      ...state,
      staffs: action.payload,
    }),
  },
  [SET_FETCHING_STATE]: {
    next: (state, action) => ({
      ...state,
      isEventStaffsFetched: action.payload?.isFetched || false,
      isEventStaffsFetchSuccess: action.payload?.isSuccess || false,
    }),
  },
  [GET_EVENT_STAFF]: {
    next: (state, action) => ({
      ...state,
      staff: action.payload,
    }),
  },
  [EVENT_STAFF_FETCHING_STATE]: {
    next: (state, action) => ({
      ...state,
      isStaffFetched: action.payload.isFetched || false,
      isStaffFetchSuccess: action.payload.isSuccess || false,
    }),
  },
  [UPDATE_STAFF_EVENT_ROLE]: {
    next: (state, action) => ({
      ...state,
      staff: {
        ...state.staff,
        ...(action?.payload?.roles?.length && { roles: action?.payload?.roles }),
      },
    }),
  },
  [FETCH_EVENT_CATEGORIES]: {
    next: (state, action) => ({
      ...state,
      categories: action?.payload,
    }),
  },
  [FETCH_EVENT_CATEGORY]: {
    next: (state, action) => {
      return {
        ...state,
        category: action?.payload || {},
        categoryForms: action?.payload?.form?.items?.[0] || defaultCategoryForm, // Expected to have only one form for now // TODO more forms upcoming
        draftCategoryForms: null,
      };
    },
  },
  [UPDATE_DRAFT_FORMS]: {
    next: (state, action) => {
      return {
        ...state,
        draftCategoryForms: action?.payload,
      };
    },
  },
  [RESET_DRAFT_FORMS]: {
    next: (state, action) => {
      return {
        ...state,
        draftCategoryForms: null,
      };
    },
  },
  [UPDATE_CATEGORY_FORMS]: {
    next: (state, action) => {
      return {
        ...state,
        draftCategoryForms: action?.payload?.success ? null : state.draftCategoryForms,
      };
    },
  },
  [UPDATE_CATEGORY_WAVIER]: {
    next: (state, action) => {
      return {
        ...state,
      };
    },
  },
  [CREATE_NEW_CATEGORY]: {
    next: (state, action) => ({
      ...state,
      event: {
        ...state.event,
        data: {
          ...state?.event?.data,
          ...(action?.payload?.length && { categories: action?.payload || [] }),
        },
      },
    }),
  },
  [PUBLISH_EVENT]: {
    next: (state, action) => ({
      ...state,
      event: {
        ...state.event,
        data: {
          ...state.event.data,
          ...(action.payload?.success && { published: action.payload?.published }),
        },
      },
    }),
  },
};

export default handleActions(ACTION_HANDLERS, initialState);
