import { useState, useCallback } from 'react';
import { Link, Typography, Stack } from '@mui/material';
import { Attestations } from 'constants/index';
import GeneralModal from 'components/GeneralModal';
import { getAttestationByKey } from 'utils/index';
import Markdown from 'components/Markdown';

const TermsAndPrivacyPolicy = ({
  preText = 'By registering ',
  returnTermOnly = false,
  returnPrivacyOnly = false,
  buttonOneText = 'Agree',
  otherSx = { mt: 3 },
}) => {
  const [openModal, setOpenModal] = useState(null);

  const handleOpenModalPolicy = useCallback(() => {
    setOpenModal(getAttestationByKey(Attestations.PRIVACY_POLICY));
  }, []);

  const handleOpenModalService = useCallback(() => {
    setOpenModal(getAttestationByKey(Attestations.TERMS_OF_SERVICE));
  }, []);

  return (
    <>
      {openModal?.markDownValue && (
        <GeneralModal
          open={Boolean(openModal?.markDownValue)}
          id="attestation-modal-display"
          title={openModal?.label}
          message={openModal?.message}
          content={
            <Stack sx={{ paddingTop: 2 }}>
              <Markdown children={openModal?.markDownValue} />{' '}
            </Stack>
          }
          buttonOneText={buttonOneText}
          buttonOneClick={() => setOpenModal(null)}
        />
      )}

      <Typography variant="body2" align="center" sx={{ color: 'text.secondary', ...otherSx }}>
        {preText}, I agree to PickPacer's&nbsp;
        {returnTermOnly ? (
          <Link underline="always" color="text.primary" href="#" onClick={handleOpenModalService}>
            Terms of Service
          </Link>
        ) : returnPrivacyOnly ? (
          <Link underline="always" color="text.primary" href="#" onClick={handleOpenModalPolicy}>
            Privacy Policy
          </Link>
        ) : (
          <>
            <Link underline="always" color="text.primary" href="#" onClick={handleOpenModalService}>
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link underline="always" color="text.primary" href="#" onClick={handleOpenModalPolicy}>
              Privacy Policy
            </Link>
            .{' '}
          </>
        )}
      </Typography>
    </>
  );
};

export default TermsAndPrivacyPolicy;
