/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "aiChatApi",
            "endpoint": "https://swipdxg6db.execute-api.ap-southeast-1.amazonaws.com/production",
            "region": "ap-southeast-1"
        },
        {
            "name": "paymentsApi",
            "endpoint": "https://qlk8k6603l.execute-api.ap-southeast-1.amazonaws.com/production",
            "region": "ap-southeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://kqmji45i6bh2lndjshqsgbrmee.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5nmborkhrjeijffsozpnewvsam",
    "aws_cognito_identity_pool_id": "ap-southeast-1:5740a587-e418-4625-85de-53dadaad46ea",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_eha9ujCRO",
    "aws_user_pools_web_client_id": "7ekquhoadb30gm7310fe2shco",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "organization-bucketd7611-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
