import { generatePath } from 'react-router-dom';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getPath } from 'utils';
import { Routes } from 'constants/index';
import { categoriesSelector, eventContactInfoSelector, eventPayoutOptionDataSelector } from 'modules/event/selector';
import { TabNames } from 'sections/organization/event/setup/general/EventGeneral';

const usePublishEventHook = () => {
  const params = useParams();
  const categories = useSelector(categoriesSelector);
  const payout = useSelector(eventPayoutOptionDataSelector);
  const eventContactInfo = useSelector(eventContactInfoSelector);

  const parameters = {
    organizationId: params?.organizationId || 'none', // To address Error
    selectedEventId: params?.selectedEventId || 'none', // To address Error
  };

  const items = [
    {
      name: 'noCategory',
      title: 'No category added',
      message: 'You must add at least one category',
      link: generatePath(getPath(Routes.EVENT_CATEGORIES_GENERAL_SETUP), {
        ...parameters,
        addNewCategoryModal: true,
      }),
      display: !categories?.length,
    },
    {
      name: 'noPayout',
      title: 'No Payout Information added',
      message: 'You must provide your Payout account',
      link: `${generatePath(getPath(Routes.EVENT_SETUP_PAYOUT), parameters)}#${TabNames.PAYOUT}`,
      display: !payout,
    },
    {
      name: 'noContactInformation',
      title: 'No contact information added',
      message: 'You must provide at least one contact information',
      link: `${generatePath(getPath(Routes.EVENT_SETUP_CONTACT), parameters)}#${TabNames.CONTACT}`,
      display: !eventContactInfo,
    },
  ];
  return items?.filter((item) => item.display);
};

export default usePublishEventHook;
