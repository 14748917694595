import { handleActions } from 'redux-actions';
import { GET_USER_RACES, GET_USER_RACES_FETCHING } from './types';

export const initialState = {
  list: [],
  nextToken: null,
  isFetching: false,
  isFetchSuccess: false,
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [GET_USER_RACES]: {
    next: (state, action) => ({
      ...state,
      list: action.payload || [],
      isFetching: false,
      isFetchSuccess: true,
    }),
  },
  [GET_USER_RACES_FETCHING]: {
    next: (state, action) => ({
      ...state,
      isFetching: true,
      isFetchSuccess: false,
    }),
  },
};

export default handleActions(ACTION_HANDLERS, initialState);
