import { handleActions } from 'redux-actions';
import { ProfileView } from 'constants/index';
import {
  INITIALIZE_APP,
  SET_PROFILE_VIEW,
  SET_FEATURE_TOGGLES,
  SET_APP_CONSTANTS,
  SET_USER_ADMIN_EMAIL,
  SET_USER_ADMIN_ROLES,
} from './types';

export const initialState = {
  isInitialized: false,
  profile: ProfileView.user,
  featureToggles: null,
  constants: null,
  admin: null,
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [INITIALIZE_APP]: {
    next: (state, action) => ({
      ...state,
      isInitialized: action.payload || false,
    }),
  },
  [SET_PROFILE_VIEW]: {
    next: (state, action) => ({
      ...state,
      profile: action.payload || ProfileView.user,
    }),
  },
  [SET_FEATURE_TOGGLES]: {
    next: (state, action) => ({
      ...state,
      featureToggles: action.payload || null,
    }),
  },
  [SET_APP_CONSTANTS]: {
    next: (state, action) => ({
      ...state,
      constants: action.payload || null,
    }),
  },
  [SET_USER_ADMIN_EMAIL]: {
    next: (state, action) => ({
      ...state,
      admin: {
        ...state.admin,
        email: action.payload,
      },
    }),
  },
  [SET_USER_ADMIN_ROLES]: {
    next: (state, action) => ({
      ...state,
      admin: {
        ...state.admin,
        roles: action.payload,
      },
    }),
  },
};

export default handleActions(ACTION_HANDLERS, initialState);
