import { useState, useCallback } from 'react';
import { useParams, generatePath, useHistory } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import { Slide, Button, IconButton, ClickAwayListener, Stack, Tooltip, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getPath } from 'utils';
import { Routes, ProfileView } from 'constants/index';
import { useDispatch, useSelector } from 'react-redux';
import { resetSelectedOrganization } from 'modules/organization/actions';
import { publishEvent, resetSelectedEvent } from 'modules/event/actions';
import { RedirectModal } from 'sections/general/RedirectModal';
import { organizationNameSelector, selectedEventIdSelector } from 'modules/organization/selector';
import { logoutUser } from 'modules/user/actions';
import { isAdminPortalSelector, profileSelector } from 'modules/app/selector';
import PublishEventModal from 'sections/organization/event/PublishEventModal';
import usePublishEventHook from 'hooks/usePublishEventHook';
import Iconify from 'components/Iconify';
import {
  eventNameSelector,
  fetchedEventIdSelector,
  isEventPublishedSelector,
  isUserAdminSelector,
} from 'modules/event/selector';
import { createAlert } from 'modules/alerts/actions';
import GeneralModal from 'components/GeneralModal';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import useResponsive from 'hooks/useResponsive';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 200;
const APPBAR_DESKTOP = 92;

const AdminNavigationBannerStyled = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  justifyContent: 'flex-end',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

const OrganizationRouteScope = {
  ORGANIZATIONS: 'organizations',
  ORGANIZATION: 'organization',
  EVENT: 'event',
};

export default function AdminNavigationBanner() {
  const dispatch = useDispatch();

  const isDesktop = useResponsive('up', 'sm');
  const params = useParams();

  const organizationId = params?.organizationId;
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isConfirmUnpublishEvent, setConfirmUnpublishEvent] = useState(false);
  const [isPublishErrorOpen, setPublishErrorOpen] = useState(false);
  const [isPublishOpen, setPublishOpen] = useState(false);
  const [openConfirmSwitchModal, setOpenConfirmModal] = useState(false);

  const organizationName = useSelector(organizationNameSelector);
  const profile = useSelector(profileSelector);
  const isAdminPortal = useSelector(isAdminPortalSelector);
  const isEventPublished = useSelector(isEventPublishedSelector);
  const isUserEventAdmin = useSelector(isUserAdminSelector);
  const eventName = useSelector(eventNameSelector);

  const lackingItems = usePublishEventHook();

  const handleOnClickPublish = useCallback(() => {
    if (isEventPublished) {
      return setConfirmUnpublishEvent((prev) => !prev);
    }
    if (lackingItems?.length) {
      setPublishErrorOpen((prev) => !prev);
    } else {
      setPublishOpen((prev) => !prev);
    }
  }, [lackingItems, isEventPublished]);

  const handlePublishEvent = useCallback(async () => {
    setPublishOpen(false);
    setConfirmUnpublishEvent(false);
    setIsPublishing(true);
    const response = await dispatch(publishEvent(!isEventPublished));
    if (response.payload.success) {
      dispatch(createAlert('CUSTOM_SUCCESS', 'Updated successfully'));
    } else {
      dispatch(createAlert('CUSTOM_ERROR', 'Unsuccessful updating, please try again later or contact support'));
    }
    setIsPublishing(false);
  }, [dispatch, isEventPublished]);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBackToOrganizations = useCallback(() => {
    dispatch(resetSelectedOrganization());
  }, [dispatch]);

  const handleBackToOrganization = useCallback(() => {
    dispatch(resetSelectedEvent());
  }, [dispatch, history, organizationId]);

  const handleStopImpersonating = useCallback(() => {
    dispatch(logoutUser(false));
  }, [dispatch]);

  const shouldDisplayPublishButton =
    profile === ProfileView.organization && params?.organizationId && params?.selectedEventId && isUserEventAdmin;

  const breadcrumbsLinks = [
    {
      name: 'Organizations',
      href: getPath(Routes.ORGANIZATIONS_DASHBOARD),
      handleClick: handleBackToOrganizations,
      scope: OrganizationRouteScope.ORGANIZATION,
    },
    {
      name: organizationName,
      href: generatePath(getPath(Routes.ORGANIZATION_EVENTS), {
        organizationId: params?.organizationId || 'dummy',
      }),
      handleClick: handleBackToOrganization,
      scope: OrganizationRouteScope.ORGANIZATION,
    },
    {
      name: 'events',
      href: generatePath(getPath(Routes.ORGANIZATION_EVENTS), {
        organizationId: params?.organizationId || 'dummy',
      }),
      handleClick: handleBackToOrganization,
      scope: OrganizationRouteScope.EVENT,
    },
    { name: eventName, scope: OrganizationRouteScope.EVENT },
  ].filter((route) => {
    if (!params?.organizationId) {
      return route.scope === OrganizationRouteScope.ORGANIZATIONS;
    }

    if (params?.organizationId && !params?.selectedEventId) {
      return route.scope === OrganizationRouteScope.ORGANIZATION;
    }

    return route;
  });

  const isInOrganizationsView = !breadcrumbsLinks?.length;

  const viewAdminNavigation = () => {
    if (!breadcrumbsLinks?.length) {
      return null;
    }

    return (
      <HeaderBreadcrumbs
        links={breadcrumbsLinks}
        action={
          shouldDisplayPublishButton && (
            <LoadingButton
              variant={isEventPublished ? 'outlined' : 'contained'}
              color={isEventPublished ? 'success' : 'error'}
              onClick={handleOnClickPublish}
              loading={isPublishing}
            >
              {isEventPublished ? 'Unpublish event' : 'Publish event'}
            </LoadingButton>
          )
        }
        actionSX={{
          pl: isDesktop ? 2 : 0,
        }}
        sx={{ mb: isDesktop ? 5 : 0 }}
        inActiveListSX={{ maxWidth: 200 }}
      />
    );
  };

  return (
    <>
      {openConfirmSwitchModal && <RedirectModal setOpen={setOpenConfirmModal} isOpen={openConfirmSwitchModal} />}

      {isPublishOpen && (
        <GeneralModal
          open={isPublishOpen}
          id={`publish-modal-display-id`}
          title="Publish Event Confirmation"
          message="Once this event is published this will be available and ready to accept registrants"
          buttonTwoText="Confirm"
          buttonTwoClick={handlePublishEvent}
          buttonOneText="Cancel"
          buttonOneClick={() => setPublishOpen((prev) => !prev)}
        />
      )}

      {isConfirmUnpublishEvent && (
        <GeneralModal
          open={isConfirmUnpublishEvent}
          id={`unpublish-modal-display-id`}
          title="Unpublish event confirmation"
          message="Once this event is unpublished this will be unavailable and cannot accept registrants anymore."
          buttonTwoText="Confirm"
          buttonTwoClick={handlePublishEvent}
          buttonOneText="Cancel"
          buttonOneClick={() => setConfirmUnpublishEvent((prev) => !prev)}
        />
      )}

      {isPublishErrorOpen && <PublishEventModal setOpen={handleOnClickPublish} open={isPublishErrorOpen} />}

      {isDesktop && (
        // 22px if no publish/unpublish button
        <Stack spacing={2} direction="row" height={shouldDisplayPublishButton ? 40 : 22}>
          {' '}
          {viewAdminNavigation()}
          {isAdminPortal && (
            <Button variant="contained" color="error" onClick={handleStopImpersonating}>
              Stop impersonating
            </Button>
          )}
        </Stack>
      )}

      {!isDesktop && !isInOrganizationsView && (
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Stack spacing={2} direction="row" height={40}>
              {!isOpen && (
                <Tooltip title="Open Admin Navigation">
                  <IconButton onClick={handleOpen}>
                    <Iconify icon="iconoir:page-down" width={30} height={30} />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>

            <Slide direction="down" in={isOpen} mountOnEnter unmountOnExit>
              <AdminNavigationBannerStyled>
                <Stack spacing={2} direction="column">
                  {profile === ProfileView.organization && (
                    <Box sx={{ display: 'flex', direction: 'left' }}>{viewAdminNavigation()}</Box>
                  )}
                  {isAdminPortal && (
                    <Button variant="contained" color="error" onClick={handleStopImpersonating}>
                      Stop impersonating
                    </Button>
                  )}
                </Stack>
              </AdminNavigationBannerStyled>
            </Slide>
          </div>
        </ClickAwayListener>
      )}
    </>
  );
}
