export const formatDistanceValue = (metric = true, distance) => {
  const isNumber = !Number.isNaN(parseFloat(distance));

  return isNumber ? `${distance}${metric || metric === null ? 'km' : 'miles'}` : distance;
};

export const appendZeros = (num) => {
  if (num < 10) {
    return `000${num}`;
  }

  if (num < 100) {
    return `00${num}`;
  }

  if (num < 1000) {
    return `0${num}`;
  }

  return `${num}`;
};
