import { createAction } from 'redux-actions';
import fetch from 'utils/fetch';
import { Logger } from 'aws-amplify';
import { createBrowserHistory } from 'history';
import moment from 'moment-timezone';
import {
  OrganizationStaffRoleType,
  Routes,
  Status,
  OrganizationRole,
  ResponseStatus,
  defaultFormId,
  defaultWaiver,
  defaultCategoryForm,
  AppConstants,
} from 'constants/index';
import { push } from 'connected-react-router';
import {
  FETCH_EVENT_DASHBOARD_DATA,
  IS_EVENT_DASHBOARD_DATA_FETCHED,
  EVENT_DATA_STATE,
  FETCH_EVENT_DATA,
  RESET_SELECTED_EVENT,
  FETCH_EVENT_REGISTRANTS,
  IS_FETCHING_EVENT_REGISTRANTS,
  GET_EVENT_REGISTRATION_ORDER_DETAILS,
  IS_EVENT_REGISTRATION_ORDER_DETAILS_FETCHED,
  UPDATE_EVENT_DATA,
  SET_UPDATING_EVENT,
  UPDATE_DYNAMO_EVENT_DATA,
  PUBLISH_EVENT,
  FETCH_EVENT_STAFFS,
  SET_FETCHING_STATE,
  REMOVE_USER_TO_AN_EVENT,
  GET_EVENT_STAFF,
  EVENT_STAFF_FETCHING_STATE,
  ADD_STAFF_TO_EVENT,
  UPDATE_STAFF_EVENT_ROLE,
  FETCH_EVENT_CATEGORIES,
  FETCH_EVENT_CATEGORY,
  CREATE_NEW_CATEGORY,
  DELETE_NEW_CATEGORY,
  UPDATE_CATEGORY,
  UPDATE_DRAFT_FORMS,
  UPDATE_CATEGORY_FORMS,
  REMOVE_CATEGORY_FORM,
  UPSERT_CATEGORY_FORM,
  UPDATE_CATEGORY_DATA,
  RESET_DRAFT_FORMS,
  UPDATE_CATEGORY_DATA_PRIZES,
  CREATE_CATEGORY_KIT,
  UPSERT_CATEGORY_SLOTS,
  GET_EVENT_REGISTRANTS,
  UPDATE_CATEGORY_WAVIER,
  UPSERT_CATEGORY_WAIVER,
  REMOVE_CATEGORY_WAIVER,
  // others
  // UPDATE_IMAGE_LINK,
} from 'modules/event/types';
import {
  updateAnEvent as updateAnEventMutationFunction,
  updateEvent as updateEventMutation,
  createUserOrganizationEventJunction as createUserOrganizationEventJunctionMutation,
  createNewCategory as createNewCategoryMutation,
  deleteCategory as deleteCategoryMutation,
  updateRegistrationPrice as updateRegistrationPriceMutation,
  createRegistrationPrice as createRegistrationPriceMutation,
  updateForm as updateFormMutations,
  createForm as createFormMutations,
  deleteForm as deleteFormMutations,
  deleteMockUpData as deleteMockUpDataMutation,
  updateMockUpData as updateMockUpDataMutation,
  createMockUpData as createMockUpDataMutation,
  updateEventCategory as updateEventCategoryMutations,
  createRaceKit as createRaceKitMutations,
  updateRaceKit as updateRaceKitMutations,
  updateCategorySlotsInventory as updateCategorySlotsInventoryMutations,
} from 'graphql/mutations';
import {
  getPath,
  roleObjectCreator,
  hasNumber,
  getNumericChars,
  categoryFeePriceCreator,
  sortFormFieldsByIndex,
  removeEmptyFieldsInObj,
  getDistanceTypeAndValueFromForm,
  registrantsDataFormatter,
} from 'utils/index';
import {
  fetchEventPortalDashboardData as fetchEventPortalDashboardDataQueries,
  getUserOrganizationEventJunction as getUserOrganizationEventJunctionQueries,
} from 'graphql/queries';
import {
  getEvent as getEventDataQueries,
  registrantsByEvent as registrantsByEventQueries,
  getEventRegistrationOrder as getEventRegistrationOrderQueries,
  listUserOrganizationEventJunctions as listUserOrganizationEventJunctionsQueries,
  listEventCategories as listEventCategoriesCustomQueries,
  getEventCategory as getEventCategoryCustomQueries,
  organizationEventJunctionByUser as organizationEventJunctionByUserCustomQueries,
} from 'graphql/customQueries';
import { createAlert } from 'modules/alerts/actions';
import {
  selectedEventIdSelector,
  isUserOrgAdminSelector,
  selectedOrganizationIdSelector,
  fetchedOrgIdSelector,
} from 'modules/organization/selector';
import {
  registrantsSelector,
  eventDataSelector,
  eventStaffsSelector,
  fetchedEventIdSelector,
  eventDataDataSelector,
  categoryRegPrice,
  draftCategoryFormsSelector,
  hasSameFormForAllCategoriesSelector,
  categoriesSelector,
  categorySelector,
  slotsAvailabilityIsSameForAllCategoriesSelector,
  distancesSelector,
  hasSameRouteForAllCatSelector,
  isWaiverFormSameForAllSelector,
} from 'modules/event/selector';
import { removeSelectedEventId, removeStaffEvents, updateStaffEventRole } from 'modules/organization/actions';
import { userIdSelector } from 'modules/user/selector';
import { createRacebibPreFixCreator } from 'utils/common/helpers/categoryHelper';
import { constantsSelector } from 'modules/app/selector';

const logger = new Logger('user/actions');

export const browserHistory = createBrowserHistory({ forceRefresh: true });

export const fetchEventData = createAction(
  FETCH_EVENT_DATA,
  async (eventId, eventData, options) => async (dispatch, getState) => {
    const state = getState();
    const userId = userIdSelector(state);
    const isOrgAdmin = isUserOrgAdminSelector(state);
    const fetchedOrgId = fetchedOrgIdSelector(state);
    const existingEventData = eventDataSelector(state);
    try {
      // Redux update only
      if (eventData && (Object.keys(eventData?.data)?.length || Object.keys(eventData?.odooData)?.length)) {
        const data = {
          ...existingEventData,
          data: { ...(existingEventData?.data && existingEventData?.data), ...eventData?.data },
          odooData: {
            ...(existingEventData?.odooData && existingEventData?.odooData),
            ...(eventData?.odooData && eventData?.odooData),
          },
        };

        return data;
      }
      dispatch(setIsFetchedEventDataState(true, false));

      const promises = [];
      // 1. Event data
      promises.push(
        await fetch.graphql({
          query: getEventDataQueries,
          variables: {
            id: eventId,
            organizationId: fetchedOrgId,
          },
        })
      );

      // 2. User Junction
      const filter = { eventId: { eq: eventId } };

      promises.push(
        await fetch.graphql({
          query: organizationEventJunctionByUserCustomQueries,
          variables: {
            userId,
            filter,
          },
        })
      );

      const response = await Promise.all(promises);

      const event = response?.[0];
      const userEventJunction = response?.[1]; // expecting only one item

      const fetchEventPortalDataResponse = event?.data?.getEvent || null;

      const fetchOrganizationEventJunctionByUserResponse =
        userEventJunction?.data?.organizationEventJunctionByUser?.items?.[0];

      const shouldResetEventId =
        (!fetchOrganizationEventJunctionByUserResponse && !isOrgAdmin) || !fetchEventPortalDataResponse;

      if (shouldResetEventId) {
        dispatch(resetSelectedEvent());
        dispatch(push(getPath(Routes.Page403)));

        return {
          data: null,
          odooData: null,
          isUserAdmin: false,
        };
      }

      // 3. Event Categories
      if (fetchEventPortalDataResponse?.categories?.items?.length) {
        await dispatch(fetchEventCategories('', fetchEventPortalDataResponse?.categories?.items, true));
      }

      // If Organization Admin or Just an Event Admin user should automatically be an admin to the event
      const isUserAdmin =
        isOrgAdmin ||
        fetchOrganizationEventJunctionByUserResponse?.roles?.some(
          (role) => role?.role === OrganizationStaffRoleType.ADMIN || role?.role === OrganizationStaffRoleType.CREATOR
        );

      const roles = isOrgAdmin
        ? [OrganizationStaffRoleType.ADMIN, OrganizationStaffRoleType.CREATOR]
        : fetchOrganizationEventJunctionByUserResponse?.roles || [];

      dispatch(setIsFetchedEventDataState(false, true));
      return {
        data: fetchEventPortalDataResponse,
        odooData: fetchEventPortalDataResponse?.odooData || null,
        isUserAdmin,
        roles,
      };
    } catch (err) {
      dispatch(setIsFetchedEventDataState(false, true));
      dispatch(
        createAlert('CUSTOM_ERROR', `We have trouble fetching your event dashboard data! Please try again later.`)
      );
      return null;
    }
  }
);

export const fetchEventCategories = createAction(
  FETCH_EVENT_CATEGORIES,
  async (eventId = '', items = [], isReduxDataUpdateOnly = false) =>
    async (dispatch, getState) => {
      const state = getState();
      const selectedEventId = eventId || fetchedEventIdSelector(state);
      try {
        if (isReduxDataUpdateOnly && items.length > 0) {
          return items;
        }

        const filter = { eventId: { eq: selectedEventId } };
        const response = await fetch.graphql({
          query: listEventCategoriesCustomQueries,
          variables: { filter, limit: 100 },
        });

        if (response?.data?.errors?.length > 0) {
          dispatch(createAlert('CUSTOM_ERROR', `We have trouble fetching categories! Please try again later.`));
          return []; // return empty array
        }

        return response?.data?.listEventCategories?.items || [];
      } catch (err) {
        dispatch(createAlert('CUSTOM_ERROR', `We have trouble fetching categories! Please try again later.`));
        return [];
      }
    }
);

export const getEventCategoryById = createAction(
  FETCH_EVENT_CATEGORY,
  async (categoryId = '') =>
    async (dispatch, getState) => {
      const state = getState();
      try {
        if (!categoryId) {
          return null;
        }
        const response = await fetch.graphql({
          query: getEventCategoryCustomQueries,
          variables: { id: categoryId },
        });

        if (response?.data?.errors?.length > 0) {
          dispatch(createAlert('CUSTOM_ERROR', `We have trouble fetching category! Please try again later.`));
          return null; // return empty object
        }

        return response?.data?.getEventCategory || {};
      } catch (err) {
        dispatch(createAlert('CUSTOM_ERROR', `We have trouble fetching category! Please try again later.`));
        return null;
      }
    }
);

export const setIsFetchedEventDataState = createAction(EVENT_DATA_STATE, async (isFetching, success) => async () => ({
  isFetching,
  success,
}));

export const fetchEventPortalDashboardData = createAction(
  FETCH_EVENT_DASHBOARD_DATA,
  async (eventId) => async (dispatch, getState) => {
    const state = getState();
    const selectedEventId = selectedEventIdSelector(state);
    try {
      dispatch(setIsFetchedEventPortalDashboardData(false));
      const response = await fetch.graphql({
        query: fetchEventPortalDashboardDataQueries,
        variables: {
          id: eventId || selectedEventId, // TODO
        },
      });

      const fetchEventPortalDashboardDataResponse = JSON.parse(response?.data?.fetchEventPortalDashboardData);
      return fetchEventPortalDashboardDataResponse;
    } catch (err) {
      dispatch(setIsFetchedEventPortalDashboardData(true));
      dispatch(
        createAlert('CUSTOM_ERROR', `We have trouble fetching your event dashboard data! Please try again later.`)
      );
      return null;
    }
  }
);

export const fetchEventRegistrants = createAction(
  FETCH_EVENT_REGISTRANTS,
  async (eventId = '', ownFilter = {}, updatedDataObject, options = null) =>
    async (dispatch, getState) => {
      const state = getState();
      const selectedEventId = selectedEventIdSelector(state);
      const registrants = registrantsSelector(state);

      const nextToken = options?.nextToken;
      const onlyPaidOnes = options?.onlyPaidOnes === undefined; // True as default

      try {
        // UI Update only
        if (updatedDataObject?.regId && registrants?.some((reg) => reg?.id === updatedDataObject?.regId)) {
          return {
            list: registrants?.reduce((acc, registrant) => {
              let newRegData = registrant;
              if (registrant?.id === updatedDataObject?.regId) {
                newRegData = {
                  ...registrant,
                  ...updatedDataObject?.updatedData,
                };
              }
              acc.push(newRegData);
              return acc;
            }, []),
          };
        }

        dispatch(setIsFetchingEventRegistrants(false));

        const dynamoEventId = eventId || selectedEventId;
        const filter = {
          ...ownFilter,
          ...(onlyPaidOnes && !ownFilter?.bibNumber?.contains && { bibNumber: { attributeExists: true } }),
        };

        const response = await fetch.graphql({
          query: registrantsByEventQueries,
          variables: nextToken ? { eventId: dynamoEventId, filter, nextToken } : { eventId: dynamoEventId, filter },
        });

        const fetchEventPortalDashboardDataResponse = response?.data?.registrantsByEvent?.items || [];

        const newList = nextToken
          ? [...registrants, ...fetchEventPortalDashboardDataResponse]
          : fetchEventPortalDashboardDataResponse;
        return {
          list: newList,
          nextToken: response?.data?.registrantsByEvent?.nextToken || null,
        };
      } catch (err) {
        dispatch(setIsFetchingEventRegistrants(true));
        dispatch(
          createAlert('CUSTOM_ERROR', `We have trouble fetching your event dashboard data! Please try again later.`)
        );
        return null;
      }
    }
);

export const getRegistrationOrderDetails = createAction(
  GET_EVENT_REGISTRATION_ORDER_DETAILS,
  async (id, fromUpdate = false) =>
    async (dispatch, _) => {
      try {
        // To disable closing of modal in UI after updating
        if (!fromUpdate) {
          dispatch(setIstRegistrationOrderDetailsFetched(false));
        }
        const response = await fetch.graphql({
          query: getEventRegistrationOrderQueries,
          variables: { id },
        });
        const getRegistrationOrderDetailsResponse = response?.data?.getEventRegistrationOrder || {};
        if (Object.keys(getRegistrationOrderDetailsResponse)?.length) {
          return getRegistrationOrderDetailsResponse;
        }
        dispatch(setIstRegistrationOrderDetailsFetched(true));
        return null;
      } catch (err) {
        dispatch(setIstRegistrationOrderDetailsFetched(true));
        dispatch(createAlert('CUSTOM_ERROR', `We have trouble fetching registrant's data! Please try again later.`));
        return null;
      }
    }
);

export const setIstRegistrationOrderDetailsFetched = createAction(
  IS_EVENT_REGISTRATION_ORDER_DETAILS_FETCHED,
  async (status) => async () => status
);

export const setIsFetchingEventRegistrants = createAction(
  IS_FETCHING_EVENT_REGISTRANTS,
  async (status) => async () => status
);

export const setIsFetchedEventPortalDashboardData = createAction(
  IS_EVENT_DASHBOARD_DATA_FETCHED,
  async (status) => async () => status
);

export const resetSelectedEvent = createAction(RESET_SELECTED_EVENT, () => (dispatch, getState) => {
  dispatch(removeSelectedEventId());
  return null;
});

// UPDATE DATA FOR ALL CONNECTION AND THIRD PARTIES
export const updateEventData = createAction(UPDATE_EVENT_DATA, async (eventData) => async (dispatch, getState) => {
  const state = getState();
  dispatch(setUpdatingEvent(true));
  try {
    const selectedEventId = selectedEventIdSelector(state);
    if (!eventData?.id && !selectedEventId) {
      console.log('event data id must be provided');
      return false;
    }

    const args = { eventId: eventData?.id || selectedEventId, eventData: JSON.stringify(eventData) };

    const response = await fetch.graphql({
      query: updateAnEventMutationFunction,
      variables: { args },
    });

    const updateAnEventResponse = JSON.parse(response?.data?.updateAnEvent);

    if (updateAnEventResponse?.data?.dynamoData || updateAnEventResponse?.data?.odooData) {
      dispatch(
        fetchEventData(selectedEventId, {
          data: eventData || {},
        })
      );
    }

    dispatch(setUpdatingEvent(false));
    dispatch(createAlert('CUSTOM_SUCCESS', `We're successfully your event data`));
    return updateAnEventResponse;
  } catch (error) {
    dispatch(createAlert('CUSTOM_ERROR', `We're having trouble updating your event data`));
    dispatch(setUpdatingEvent(false));
    return false;
  }
});

export const updateDynamoEventData = createAction(
  UPDATE_DYNAMO_EVENT_DATA,
  async (eventData) => async (dispatch, getState) => {
    const state = getState();
    dispatch(setUpdatingEvent(true));
    try {
      const selectedEventId = selectedEventIdSelector(state);
      if (!eventData?.id && !selectedEventId) {
        console.log('event data id must be provided');
        return false;
      }

      const updateEventInput = {
        id: eventData?.id || selectedEventId,
        ...(eventData?.dateOfEvent && { dateOfEvent: eventData?.dateOfEvent }),
        ...(eventData?.eventName && { eventName: eventData?.eventName }),
        ...(eventData?.eventShortDescription && { eventShortDescription: eventData?.eventShortDescription }),
        ...(eventData?.additionalNotes && { additionalNotes: eventData?.additionalNotes }),
        ...(typeof eventData?.hasSameRouteForAllCat === 'boolean' && {
          hasSameRouteForAllCat: eventData?.hasSameRouteForAllCat,
        }),
        ...(eventData?.registrationStart && { registrationStart: eventData?.registrationStart }),
        ...(eventData?.registrationEnd && { registrationEnd: eventData?.registrationEnd }),
        ...(typeof eventData?.metricUsedIsKm === 'boolean' && { metricUsedIsKm: eventData?.metricUsedIsKm }),
        ...(typeof eventData?.hasSameFormForAllCategories === 'boolean' && {
          hasSameFormForAllCategories: eventData?.hasSameFormForAllCategories,
        }),
        ...(typeof eventData?.canHaveMultipleRegistrantsInOneOrder === 'boolean' && {
          canHaveMultipleRegistrantsInOneOrder: eventData?.canHaveMultipleRegistrantsInOneOrder,
        }),
        ...(typeof eventData?.slotsAvailabilityIsSameForAllCategories === 'boolean' && {
          slotsAvailabilityIsSameForAllCategories: eventData?.slotsAvailabilityIsSameForAllCategories,
        }),
        ...(typeof eventData?.isWaiverFormSameForAll === 'boolean' && {
          isWaiverFormSameForAll: eventData?.isWaiverFormSameForAll,
        }),
        ...(eventData?.eventType && { eventType: eventData?.eventType }),
        ...(eventData?.eventInstance && { eventInstance: eventData?.eventInstance }),
        ...(eventData?.rulesAndRegulations && { rulesAndRegulations: eventData?.rulesAndRegulations }),
        // Address
        ...(eventData?.addressLine1 && { addressLine1: eventData?.addressLine1 }),
        ...(eventData?.addressLine2 && { addressLine2: eventData?.addressLine2 }),
        ...(eventData?.barangay && { barangay: eventData?.barangay }),
        ...(eventData?.city && { city: eventData?.city }),
        ...(eventData?.state && { state: eventData?.state }),
        ...(eventData?.region && { region: eventData?.region }),
        ...(eventData?.country && { country: eventData?.country }),
        ...(eventData?.deliveryOptions?.length && { deliveryOptions: eventData?.deliveryOptions }),
        ...(eventData?.contactInfo?.length && { contactInfo: eventData?.contactInfo }),
        ...(eventData?.payoutOption && { payoutOption: eventData?.payoutOption }),
      };

      const response = await fetch.graphql({
        query: updateEventMutation,
        variables: { input: updateEventInput },
      });

      const updateEventResponse = response?.data?.updateEvent;

      if (updateEventResponse) {
        dispatch(
          fetchEventData(selectedEventId, {
            data: updateEventInput || {},
          })
        );
      }

      dispatch(setUpdatingEvent(false));
      dispatch(createAlert('CUSTOM_SUCCESS', `We're successfully updated your event data`));
      return updateEventResponse;
    } catch (error) {
      dispatch(createAlert('CUSTOM_ERROR', `We're having trouble updating your event data`));
      dispatch(setUpdatingEvent(false));
      return false;
    }
  }
);

export const setUpdatingEvent = createAction(SET_UPDATING_EVENT, (status) => () => status);

export const updateStaffEventRoleForEventPortal = createAction(
  UPDATE_STAFF_EVENT_ROLE,
  async (id, role) => async (dispatch) => {
    const { payload } = await dispatch(updateStaffEventRole(id, role, true));

    if (!payload?.success) {
      return null;
    }

    return { roles: payload?.roles };
  }
);

export const fetchEventStaffList = createAction(
  FETCH_EVENT_STAFFS,
  async (addedFilter = {}, filterRemovedUserIds = []) =>
    async (dispatch, getState) => {
      const state = getState();

      const eventStaffs = eventStaffsSelector(state);
      const selectedEventId = selectedEventIdSelector(state);
      try {
        if (filterRemovedUserIds.length) {
          return eventStaffs?.filter((staff) => !filterRemovedUserIds.includes(staff?.id));
        }

        if (!selectedEventId?.length) {
          return [];
        }

        // TODO sort by date ascending
        const filter = { eventId: { eq: selectedEventId }, ...addedFilter };

        dispatch(fetchingEventStatus(false, false));
        const list = await fetch.graphql({
          query: listUserOrganizationEventJunctionsQueries,
          variables: { filter, limit: 1000 },
        });
        dispatch(fetchingEventStatus(true, true));
        return list?.data?.listUserOrganizationEventJunctions?.items || [];
      } catch (err) {
        dispatch(createAlert('CustomAlert', `We can't get list of staffs for this event as of the moment`));
        dispatch(fetchingEventStatus(true, false));
        return [];
      }
    }
);

export const fetchingEventStatus = createAction(SET_FETCHING_STATE, (isFetched, isSuccess) => ({
  isFetched,
  isSuccess,
}));

export const removeStaffsToAnEvent = createAction(REMOVE_USER_TO_AN_EVENT, async (ids = []) => async (dispatch) => {
  if (!ids.length) {
    return {
      success: false,
    };
  }

  const response = await dispatch(removeStaffEvents(ids, true));

  if (response?.payload?.success) {
    await dispatch(fetchEventStaffList(null, ids));
  }

  return {
    success: true,
  };
});

export const getEventStaff = createAction(GET_EVENT_STAFF, async (id = '') => async (dispatch) => {
  if (!id) {
    return {
      data: null,
      success: false,
    };
  }

  try {
    dispatch(fetchingEventStaffState(false, false));
    const staff = await fetch.graphql({
      query: getUserOrganizationEventJunctionQueries,
      variables: { id },
    });
    dispatch(fetchingEventStaffState(true, true));
    return staff?.data?.getUserOrganizationEventJunction || null;
  } catch (err) {
    dispatch(fetchingEventStaffState(false, false));
    dispatch(createAlert('CUSTOM_ERROR', `We can't get this staff as of the moment`));
    return null;
  }
});

export const fetchingEventStaffState = createAction(EVENT_STAFF_FETCHING_STATE, (isFetched, isSuccess) => ({
  isFetched,
  isSuccess,
}));

export const addStaffToAnEvent = createAction(
  ADD_STAFF_TO_EVENT,
  async (userData = null, shouldTriggerFetchEventList = true) =>
    async (dispatch, getState) => {
      const state = getState();
      const selectedOrganizationId = selectedOrganizationIdSelector(state);
      const selectedEventId = selectedEventIdSelector(state);
      try {
        if (!userData || !selectedEventId || !selectedOrganizationId || !userData?.email || !userData?.userId) {
          return null;
        }
        const useToStore = {
          userEmail: userData?.email,
          userId: userData?.userId,
          status: Status.APPROVED,
          eventId: selectedEventId,
          organizationId: selectedOrganizationId,
          roles: [roleObjectCreator(userData?.role || OrganizationRole.MEMBER)],
        };

        // 1. Check if this user was not added yet
        const data = await dispatch(fetchEventStaffList({ userEmail: { eq: `${userData.email}` } }));
        const isExistingWithCurrentEvent = data?.payload?.length > 0;

        if (isExistingWithCurrentEvent) {
          dispatch(createAlert('CUSTOM_WARNING', 'This user is already in the organization!'));
          return {
            data: null,
            status: ResponseStatus.ALREADY_CREATED,
          };
        }

        const response = await fetch.graphql({
          query: createUserOrganizationEventJunctionMutation,
          variables: { input: useToStore },
        });

        const resData = response?.data?.createUserOrganizationEventJunction || null;

        if (resData && shouldTriggerFetchEventList) {
          dispatch(createAlert('CUSTOM_SUCCESS', 'We successfully added a new staff to your organization'));
          await dispatch(fetchEventStaffList());
        } else {
          dispatch(createAlert('CustomAlert', `We can't add a new staff for this organization as of the moment"`));
        }

        return {
          data: resData,
          status: ResponseStatus.SUCCESSFULLY_CREATED,
        };
      } catch (err) {
        dispatch(createAlert('CustomAlert', `We can't add a new staff for this organization as of the moment"`));
        return [];
      }
    }
);

export const createNewCategory = createAction(
  CREATE_NEW_CATEGORY,
  async (categoryData = null, shouldTriggerFetchEventList = true) =>
    async (dispatch, getState) => {
      const state = getState();
      const selectedOrganizationId = selectedOrganizationIdSelector(state);
      const selectedEventId = selectedEventIdSelector(state);
      const eventData = eventDataSelector(state);
      const categories = categoriesSelector(state);
      const slotsAvailabilityIsSameForAllCategories = slotsAvailabilityIsSameForAllCategoriesSelector(state);
      const isWaiverFormSameForAll = isWaiverFormSameForAllSelector(state);
      const hasSameRouteForAllCat = hasSameRouteForAllCatSelector(state);
      const hasSameFormForAllCategories = hasSameFormForAllCategoriesSelector(state);
      const appConstants = constantsSelector(state);

      const categoryIdToFollow = categories?.[0]?.id;
      const hasToPopulateDefault =
        hasSameFormForAllCategories ||
        hasSameRouteForAllCat ||
        isWaiverFormSameForAll ||
        slotsAvailabilityIsSameForAllCategories;

      try {
        if (!categoryData || !selectedEventId || !selectedOrganizationId) {
          return null;
        }

        let forms = [defaultCategoryForm];
        let waivers = [{ body: appConstants?.[AppConstants.DEFAULT_WAIVER] || defaultWaiver }];
        let totalSlotsAvailable = 100;
        let routeImageIds = [];

        if (hasToPopulateDefault && categoryIdToFollow) {
          const categoryResponse = await fetch.graphql({
            query: getEventCategoryCustomQueries,
            variables: { id: categoryIdToFollow },
          });
          const categoryData = categoryResponse?.data?.getEventCategory || {};

          if (categoryData?.id) {
            if (hasSameFormForAllCategories) {
              forms = categoryData?.form?.items || [defaultCategoryForm];
            }

            if (hasSameRouteForAllCat) {
              routeImageIds = categoryData?.routeImageURL?.items || [];
            }

            if (isWaiverFormSameForAll) {
              waivers = categoryData?.waiverForm?.items || [{ body: defaultWaiver }];
            }

            if (slotsAvailabilityIsSameForAllCategories) {
              totalSlotsAvailable = categoryData?.slots?.totalSlotsAvailable || 100;
            }
          }
        }

        let distance = '';

        if (categoryData?.distanceSelect !== 'Other') {
          if (hasNumber(categoryData?.distanceSelect)) {
            distance = `${getNumericChars(categoryData?.distanceSelect)}`;
          } else {
            distance = `${categoryData?.distanceSelect}`;
          }
        } else {
          distance = `${getNumericChars(categoryData?.distance)}`;
        }

        const categoryToStoreData = {
          organizationId: selectedOrganizationId,
          eventId: selectedEventId,
          distance,
          categoryType: categoryData?.type || '',
          categoryValue: categoryData?.typeValue || '',
          prices: JSON.stringify([
            categoryFeePriceCreator(categoryData?.fee, undefined, eventData?.data?.dateOfEvent, moment().format()),
          ]),
          waivers: JSON.stringify(waivers),
          totalSlotsAvailable,
          forms: JSON.stringify(forms),
          routeImageIds: JSON.stringify(routeImageIds),
        };

        const response = await fetch.graphql({
          query: createNewCategoryMutation,
          variables: { args: categoryToStoreData },
        });

        const resData = response?.data?.createNewCategory || null;

        if (resData) {
          await dispatch(fetchEventCategories());
        }

        if (resData && shouldTriggerFetchEventList) {
          dispatch(createAlert('CUSTOM_SUCCESS', 'We successfully added a new category to this event'));
        } else {
          dispatch(createAlert('CustomAlert', `We can't add a new category for this event as of the moment`));
        }

        return {
          data: resData,
          status: ResponseStatus.SUCCESSFULLY_CREATED,
        };
      } catch (err) {
        dispatch(createAlert('CustomAlert', `We can't add a new category for this event as of the moment`));
        return [];
      }
    }
);

export const deleteCategory = createAction(
  DELETE_NEW_CATEGORY,
  async (categoryId = '') =>
    async (dispatch, getState) => {
      const state = getState();
      const categories = categoriesSelector(state);

      try {
        if (!categoryId) {
          return null;
        }
        const shouldUpdatePublishState = categories?.length === 1 && categories?.find((cat) => cat?.id === categoryId);

        const promises = [];

        // Do not add anything before this promises as this is used below response
        promises.push(
          await fetch.graphql({
            query: deleteCategoryMutation,
            variables: { args: { categoryId } },
          })
        );

        if (shouldUpdatePublishState) {
          promises.push(await dispatch(publishEvent(false)));
        }

        const [response] = await Promise.all(promises);
        const resData = response?.data?.deleteCategory;

        if (resData) {
          await dispatch(fetchEventCategories());
        }

        return {
          success: Boolean(resData),
        };
      } catch (err) {
        dispatch(createAlert('CustomAlert', `We can't add a new staff for this organization as of the moment"`));
        return {
          success: false,
        };
      }
    }
);

export const updateRegistrationFee = createAction(
  UPDATE_CATEGORY,
  async (categoryId = '', data = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const categoryPrice = categoryRegPrice(state);
      const event = eventDataDataSelector(state);
      try {
        if (!categoryId || !Object.keys(data)?.length) {
          return null;
        }

        let resData = null;

        // Check if this is for update or create
        if (categoryPrice?.categoryId) {
          const response = await fetch.graphql({
            query: updateRegistrationPriceMutation,
            variables: { input: { categoryId, ...data } },
          });

          resData = response?.data?.updateRegistrationPrice;
        } else {
          if (!event?.id) {
            return null;
          }
          const createResponse = await fetch.graphql({
            query: createRegistrationPriceMutation,
            variables: { input: { categoryId, eventId: event?.id, ...data } },
          });

          resData = createResponse?.data?.createRegistrationPrice;
        }

        if (resData) {
          await dispatch(getEventCategoryById(categoryId));
          dispatch(createAlert('SUCCESS', `Updated!`));
        }

        return {
          success: Boolean(resData),
        };
      } catch (err) {
        dispatch(createAlert('CustomAlert', `We can't update this category as of the moment"`));
        return {
          success: false,
        };
      }
    }
);

export const updateDraftCategoryForms = createAction(
  UPDATE_DRAFT_FORMS,
  async (udpatedForms = []) =>
    async (dispatch, getState) => {
      return udpatedForms?.map((form) => ({ ...form, formFields: sortFormFieldsByIndex(form?.formFields) })); // TODO sort form by index also
    }
);

export const resetDraftForm = createAction(RESET_DRAFT_FORMS);

export const updateCategoryForm = createAction(
  UPDATE_CATEGORY_FORMS,
  async (updateAllCategories = false, forceCategoryForm = null) =>
    async (dispatch, getState) => {
      const state = getState();
      let draftCategoryForms = draftCategoryFormsSelector(state);
      const category = categorySelector(state);
      const categories = categoriesSelector(state);

      if (forceCategoryForm) {
        draftCategoryForms = forceCategoryForm;
      }

      try {
        const promises = [];
        const promisesCreate = [];

        // 2. Update waiver
        // 2.1 Update all Categories Forms
        if (updateAllCategories) {
          // Update all Categories
          categories?.forEach(async (category) => {
            const waiver = category?.form?.items;
            const categoryId = category?.id;

            if (waiver?.length && categoryId) {
              // 1. remove all waiver
              waiver?.forEach(async (form) => {
                promises.push(await dispatch(removeCategoryForm(form?.id)));
              });
            }

            // 2. insert new form for a each category
            promisesCreate.push(
              draftCategoryForms?.map(async (draftForm, index) => {
                const data = {
                  formFields: draftForm?.formFields || [],
                  eventId: category?.eventId,
                  categoryId: category?.id,
                  formOrderNumber: draftForm?.formOrderNumber || index,
                };
                return await dispatch(upsertCategoryForm(data));
              })
            );
          });
        }

        // 2.2 Update only Category Form
        if (!updateAllCategories && category?.id && category?.eventId) {
          // 2. insert new form for a each category
          promisesCreate.push(
            draftCategoryForms?.map(async (draftForm, index) => {
              const data = {
                ...(draftForm?.id && !draftForm?.id?.includes(defaultFormId) ? { id: draftForm?.id } : {}),
                formFields: draftForm?.formFields || [],
                eventId: category?.eventId,
                categoryId: category?.id,
                formOrderNumber: draftForm?.formOrderNumber || index,
              };
              return await dispatch(upsertCategoryForm(data));
            })
          );
        }

        await Promise.all(promises);
        await Promise.all(promisesCreate);

        // Get Category

        if (category?.id) {
          await Promise.all([
            await dispatch(fetchEventCategories()),
            await dispatch(getEventCategoryById(category?.id)),
          ]);
        }

        dispatch(createAlert('SUCCESS', `Sucessfully Updated`));

        return {
          success: true,
        };
      } catch (err) {
        dispatch(createAlert('CustomAlert', `We can't update this category form as of the moment"`));
        return {
          success: false,
        };
      }
    }
);

export const updateCategoryWavier = createAction(
  UPDATE_CATEGORY_WAVIER,
  async (isWaiverFormSameForAllCat = false, waiverFormData) =>
    async (dispatch, getState) => {
      const state = getState();
      const categories = categoriesSelector(state);
      const category = categorySelector(state);

      try {
        const promises = [];
        const promisesCreate = [];

        // 2. Update waiver
        // 2.1 Update all Categories Forms
        if (isWaiverFormSameForAllCat) {
          // Update all Categories
          categories?.forEach(async (category) => {
            const waivers = category?.waiverForm?.items;

            if (waivers?.length) {
              // 1. remove
              promises.push(waivers?.map(async (item) => await dispatch(removeCategoryWaiver(item?.id))));
            }

            // 2. insert new waiver for a each category
            promisesCreate.push(
              await dispatch(
                upsertCategoryWaiver({
                  belongsTo: category?.id,
                  eventId: category?.event?.id,
                  title: 'waiver',
                  body: waiverFormData?.body,
                })
              )
            );
          });
        }

        // 2.2 Update only Category Form
        if (!isWaiverFormSameForAllCat && waiverFormData?.id && waiverFormData?.eventId && waiverFormData?.belongsTo) {
          // 2. insert new form for a each category
          promises.push(await dispatch(upsertCategoryWaiver(waiverFormData)));
        }

        await Promise.all(promises);
        await Promise.all(promisesCreate);

        // Get Category

        if (category?.id) {
          await Promise.all([
            await dispatch(fetchEventCategories()),
            await dispatch(getEventCategoryById(category?.id)),
          ]);
        }

        dispatch(createAlert('SUCCESS', `Successfully Updated`));

        return {
          success: true,
        };
      } catch (err) {
        dispatch(createAlert('CustomAlert', `We can't update this category waiver as of the moment"`));
        return {
          success: false,
        };
      }
    }
);

export const upsertCategoryWaiver = createAction(UPSERT_CATEGORY_WAIVER, async (waiverForm = null) => async () => {
  try {
    if (!waiverForm) {
      return;
    }

    let upsertResponse = null;
    const wavierId = waiverForm?.id;
    // Update
    if (wavierId) {
      const { body, description, eventId, title, type } = waiverForm;
      upsertResponse = await fetch.graphql({
        query: updateMockUpDataMutation,
        variables: { input: { body, description, eventId, title, type, id: wavierId } },
      });
    }

    // Create
    else if (!wavierId) {
      upsertResponse = await fetch.graphql({
        query: createMockUpDataMutation,
        variables: { input: waiverForm },
      });
    }

    return {
      success: Boolean(upsertResponse),
    };
  } catch (err) {
    return {
      success: false,
    };
  }
});

export const removeCategoryWaiver = createAction(REMOVE_CATEGORY_WAIVER, async (waiverId = '') => async () => {
  try {
    if (!waiverId) {
      return;
    }

    const removeResponse = await fetch.graphql({
      query: deleteMockUpDataMutation,
      variables: { input: { id: waiverId } },
    });

    return {
      success: Boolean(removeResponse),
    };
  } catch (err) {
    return {
      success: false,
    };
  }
});

export const upsertCategoryForm = createAction(
  UPSERT_CATEGORY_FORM,
  async (formData = null) =>
    async (dispatch, getState) => {
      const state = getState();

      try {
        if (!formData) {
          return;
        }

        const categoryId = formData?.categoryId;
        const eventId = formData?.eventId;
        const formOrderNumber = formData?.formOrderNumber;
        const formDataNeededFields = eventId && categoryId && formOrderNumber;

        const toUpdate = formData?.id && !formData?.id?.includes(defaultFormId);

        let upsertResponse = null;

        // Update
        if (toUpdate && formDataNeededFields) {
          upsertResponse = await fetch.graphql({
            query: updateFormMutations,
            variables: { input: { ...formData, id: formData?.id } },
          });
        }

        // Create
        else if (formDataNeededFields && !formData?.id) {
          upsertResponse = await fetch.graphql({
            query: createFormMutations,
            variables: { input: { ...formData } },
          });
        }

        return {
          success: Boolean(upsertResponse),
        };
      } catch (err) {
        return {
          success: false,
        };
      }
    }
);

export const removeCategoryForm = createAction(REMOVE_CATEGORY_FORM, async (formId = '') => async () => {
  try {
    if (!formId) {
      return;
    }

    const removeResponse = await fetch.graphql({
      query: deleteFormMutations,
      variables: { input: { id: formId } },
    });

    return {
      success: Boolean(removeResponse),
    };
  } catch (err) {
    return {
      success: false,
    };
  }
});

export const updateCategoryData = createAction(UPDATE_CATEGORY_DATA, async (id, values) => async () => {
  try {
    if (!values || !id) {
      return;
    }

    const { distanceSelect, ...restValues } = values;

    const { distance, categoryValue } = getDistanceTypeAndValueFromForm({
      distanceSelect,
      formCategoryValue: values?.categoryValue,
      categoryType: values?.categoryType,
      distanceValue: values?.distance,
    });

    const data = {
      id,
      ...restValues,
      categoryValue,
      distance,
      raceBibPreFix: createRacebibPreFixCreator(distance),
    };

    const removeResponse = await fetch.graphql({
      query: updateEventCategoryMutations,
      variables: { input: removeEmptyFieldsInObj(data) },
    });

    return {
      success: Boolean(removeResponse),
    };
  } catch (err) {
    return {
      success: false,
    };
  }
});

export const updateCategoryDatayPrizes = createAction(
  UPDATE_CATEGORY_DATA_PRIZES,
  async ({ id, items }) =>
    async () => {
      try {
        if (!items.length || !id) {
          return;
        }

        const constructedUpdateData = items?.reduce((acc, item) => {
          const itemValue = {
            prizeValue: item?.prizeValue || [],
            prizeCategory: {
              prizeCategoryType: 'Rank',
              prizeCategoryValue: item?.rank,
            },
          };
          return [...acc, itemValue];
        }, []);
        const updatePrizes = await fetch.graphql({
          query: updateEventCategoryMutations,
          variables: { input: { id, categoryPrizes: constructedUpdateData } },
        });

        return {
          success: Boolean(updatePrizes),
        };
      } catch (err) {
        return {
          success: false,
        };
      }
    }
);

export const upsertCategoryKit = createAction(CREATE_CATEGORY_KIT, async (data = null) => async () => {
  try {
    const {
      categoryId,
      selectedEventId: eventId,
      kitType = '',
      description,
      displaySequence,
      optionName = '',
      optionValues = [],
      id = '',
    } = data;

    if (!categoryId || !eventId || displaySequence === undefined) {
      return { id: '' };
    }

    const input = {
      ...(id ? { id } : {}),
      categoryId,
      eventId,
      kitType,
      description,
      displaySequence,
      optionName,
      optionValues,
    };

    const upsertCategoryKit = await fetch.graphql({
      query: id ? updateRaceKitMutations : createRaceKitMutations,
      variables: { input },
    });

    return {
      id: upsertCategoryKit?.data?.createRaceKit?.id,
    };
  } catch (err) {
    return {
      id: '',
    };
  }
});

export const updateSlotsCategory = createAction(
  UPSERT_CATEGORY_SLOTS,
  async (id, data = null, hasSlotForAllCategories = false) =>
    async (dispatch, getState) => {
      const state = getState();
      const categories = categoriesSelector(state);

      try {
        const { totalSlotsAvailable: totalSlotsAvailableData } = data;
        const totalSlotsAvailable = Number.parseInt(totalSlotsAvailableData, 10);

        if (!totalSlotsAvailable || !id) {
          return { success: false };
        }

        let inputData = [];

        if (hasSlotForAllCategories) {
          inputData = categories?.reduce(
            (acc, category) => [...acc, { categoryId: category?.id, totalSlotsAvailable }],
            []
          );
        } else {
          inputData = [
            {
              categoryId: id,
              totalSlotsAvailable,
            },
          ];
        }

        return await Promise.all(
          inputData.map(
            async (input) =>
              await fetch.graphql({
                query: updateCategorySlotsInventoryMutations,
                variables: { input },
              })
          )
        )
          .then(async () => {
            await Promise.all([await dispatch(getEventCategoryById(id))]);
            return { success: true };
          })
          .catch(() => ({ success: false }));
      } catch (err) {
        return {
          success: false,
        };
      }
    }
);

export const getAllRegistrants = createAction(
  GET_EVENT_REGISTRANTS,
  async (addedFilter = {}, onlyPaidOnes = true, parsingOptions = {}, formatter = null, otherParams = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const selectedEventId = selectedEventIdSelector(state);
      const distances = distancesSelector(state);

      // Get All means this do the recursive by 500 query

      const limit = otherParams?.limit || 200;

      try {
        const eventId = selectedEventId;
        const filter = {
          ...(onlyPaidOnes && { bibNumber: { attributeExists: true } }),
          ...addedFilter,
        };
        const func = async (nextToken = '') =>
          await fetch.graphql({
            query: registrantsByEventQueries,
            variables: nextToken ? { eventId, filter, nextToken, limit } : { eventId, filter, limit },
          });

        const list = await recursiveFunc({
          func: func,
          queryName: 'registrantsByEvent',
          responseFormatter: formatter || registrantsDataFormatter,
          distances,
          parsingOptions,
        });

        return {
          data: list || [],
          success: true,
        };
      } catch (err) {
        return {
          data: [],
          success: false,
        };
      }
    }
);

const recursiveFunc = async ({
  func,
  queryName,
  nextToken = '',
  list = [],
  distances = null,
  responseFormatter = null,
  parsingOptions = {},
}) => {
  const response = await func(nextToken);
  const token = response?.data?.[queryName]?.nextToken;
  const toAppendItems = response?.data?.[queryName]?.items || [];

  let newListToAppend = toAppendItems;
  if (toAppendItems?.length && responseFormatter) {
    newListToAppend = responseFormatter(newListToAppend, distances, parsingOptions);
  }

  const newList = [...list, ...newListToAppend];

  if (!token || !toAppendItems?.length) {
    return newList;
  }

  return recursiveFunc({ func, queryName, nextToken: token, list: newList, responseFormatter });
};

export const publishEvent = createAction(PUBLISH_EVENT, async (published) => async (_, getState) => {
  const state = getState();
  const selectedEventId = selectedEventIdSelector(state);
  try {
    const event = await fetch.graphql({
      query: updateEventMutation,
      variables: { input: { id: selectedEventId, published } },
    });
    if (event?.data?.updateEvent?.id) {
      return { success: true, published };
    }
    return { success: false };
  } catch (error) {
    logger.info('Updating event Error ', error);
    return { success: false };
  }
});
