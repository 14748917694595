import { useCallback } from 'react';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Card, Stack, Typography, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFEditor } from 'components/hook-form';
import { eventDataDataSelector } from 'modules/event/selector';
import { updateDynamoEventData } from 'modules/event/actions';
import { filterWithObjectFieldInObject } from 'utils/index';
import moment from 'moment-timezone';

// ----------------------------------------------------------------------

export const EventPrimaryPageHashes = {
  PRIMARY_INFORMATION: 'primary-information',
};
// ----------------------------------------------------------------------

export default function AdditionalNotes() {
  const history = useHistory();
  const dispatch = useDispatch();
  const UpdateUserSchema = Yup.object().shape({
    additionalNotes: Yup.string()
      .min(25, 'Additional notes should be at least 25 Characters')
      .max(7000, 'Additional notes should not be More than 7000 Characters')
      .required('Additional notes is required'),
  });

  const eventData = useSelector(eventDataDataSelector);

  const defaultValues = {
    additionalNotes: eventData?.additionalNotes || '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    watch,
    reset,
  } = methods;

  const values = watch();
  const shouldDisableButton = errors?.length || !isDirty;

  const eventDate = moment(values.dateOfEvent);
  const time = moment('23:59:59', 'HH:mm');
  const dateOfEventValue = moment(eventDate)
    .set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second'),
    })
    .format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');

  const onSubmit = useCallback(
    async (values) => {
      const reconstructedValues = filterWithObjectFieldInObject(values, eventData);
      const response = await dispatch(updateDynamoEventData({ id: eventData?.id, ...reconstructedValues }));
      const successResponse = Boolean(response?.payload?.success);
      if (successResponse) {
        reset(values);
      }
      return { success: successResponse || false };
    },
    [history, dispatch, filterWithObjectFieldInObject, eventData, dateOfEventValue]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Additional Notes
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Card sx={{ p: 3 }} id={EventPrimaryPageHashes.PRIMARY_INFORMATION}>
            <Alert severity="info" sx={{ mt: 2, mb: 2 }}>
              You can add some additional information for your event through here. You can preview it through the
              preview button
            </Alert>
            <Stack sx={{ mt: 3 }}>
              <RHFEditor simple name="additionalNotes" />
            </Stack>
            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting} disabled={shouldDisableButton}>
                  Save Changes
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        </FormProvider>
      </Grid>
    </Grid>
  );
}
