import { useCallback } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileView, Routes } from 'constants/index';
import { getPath } from 'utils/routes';
import GeneralModal from 'components/GeneralModal';
import { profileSelector } from 'modules/app/selector';
import { setProfileView } from 'modules/app/actions';
import { resetSelectedOrganization } from 'modules/organization/actions';
import { resetSelectedEvent } from 'modules/event/actions';

export const RedirectModal = ({ setOpen, isOpen = false }) => {
  const profile = useSelector(profileSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const isInOrganizationProfile = profile === ProfileView.organization;

  const handleCloseClick = () => setOpen((prev) => !prev);

  const handleRedirectSwitchModal = useCallback(() => {
    const setInProfile = profile !== ProfileView.organization ? ProfileView.organization : ProfileView.user;
    if (setInProfile === ProfileView.user) {
      dispatch(resetSelectedOrganization());
      dispatch(resetSelectedEvent());
    }
    dispatch(setProfileView(setInProfile));
    history.push(getPath(setInProfile === ProfileView.organization ? Routes.ORGANIZATIONS_DASHBOARD : Routes.EVENTS));
    handleCloseClick();
  }, [profile, dispatch, history]);

  return (
    <GeneralModal
      open={Boolean(isOpen)}
      id="switch-modal-display"
      title="Confirm Switch"
      message={`Confirmation to switch profile view from ${
        isInOrganizationProfile ? 'organization to user' : 'user to organization'
      }`}
      buttonOneText="Cancel"
      buttonTwoText="Switch"
      buttonOneClick={handleCloseClick}
      buttonTwoClick={handleRedirectSwitchModal}
    />
  );
};

RedirectModal.propTypes = {
  setOpen: PropTypes.func,
  isOpen: PropTypes.bool,
};
