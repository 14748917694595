import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { resetRegistration, changeRegistrationProgress } from 'modules/registration/actions';
import { registrationDetailLineItemsSelector } from 'modules/registration/selector';
import {
  Dialog,
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ProgressPage } from 'constants';

// ----------------------------------------------------------------------

ModalProgressAlert.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default function ModalProgressAlert({ open, onClose, onContinue }) {
  const dispatch = useDispatch();
  const registrationDetailLineItem = useSelector(registrationDetailLineItemsSelector);

  // TODO
  const listOfRegistrants = useMemo(() => {
    return registrationDetailLineItem?.items?.map((item) => `${item}`) || [];
  }, [registrationDetailLineItem]);

  const handleResetClick = useCallback(async () => {
    dispatch(changeRegistrationProgress(ProgressPage.numberOfRegistrants));
    onClose();
    await dispatch(resetRegistration());
  }, []);

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Your last progress with this event</DialogTitle>

      <DialogContent>
        <DialogContentText id="registration-progress-alert-dialog-description">
          We've detected tha you have a pending registration with this event. Would you like to continue?
          {listOfRegistrants.map((item, index) => (
            <Typography key={index} variant="body2" sx={{ color: 'text.secondary' }}>
              {`${item}`}
            </Typography>
          ))}
        </DialogContentText>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button variant="contained" onClick={onContinue}>
          Continue with my progress
        </Button>
        <LoadingButton type="submit" variant="outlined" loading={false} onClick={handleResetClick}>
          Reset Progress
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
