import { useState, useEffect } from 'react';
import { regions, provinces as states, cities, barangays } from 'select-philippines-address';

function useAddressHook({ address }) {
  const [loading, setLoading] = useState(true);
  const initialState = {
    country: address?.country || 'Philippines',
    region: address?.region || '',
    regionCode: address?.regionCode || '',
    addressLine1: address?.addressLine1 || '',
    state: address?.state || '',
    stateCode: address?.stateCode || '',
    barangay: address?.barangay || '',
    barangayCode: address?.barangayCode || '',
    city: address?.city || '',
    cityCode: address?.cityCode || '',
  };
  const [constructedAddress, setConstructedAddress] = useState(initialState);

  useEffect(() => {
    if (address?.region && address?.country === 'Philippines') {
      // let addressData = initialState
      setLoading(true);
      (async () => {
        await regions().then(async (response) => {
          const region = response?.find((region) => region?.region_name === address?.region);
          const regionName = region?.region_name;
          const regionCode = region?.region_code;
          if (regionCode) {
            setConstructedAddress((prev) => ({
              ...prev,
              region: regionName,
              regionCode,
            }));

            await states(regionCode)?.then(async (stateResponse) => {
              const state = stateResponse?.find((state) => state?.province_name === address?.state);
              const stateName = state?.province_name || '';
              const stateCode = state?.province_code || '';
              if (stateCode) {
                setConstructedAddress((prev) => ({
                  ...prev,
                  stateCode,
                  state: stateName,
                }));

                await cities(stateCode)?.then(async (cityResponse) => {
                  const city = cityResponse?.find((city) => city?.city_name === address?.city);
                  const cityName = city?.city_name || '';
                  const cityCode = city?.city_code || '';

                  if (cityCode) {
                    setConstructedAddress((prev) => ({
                      ...prev,
                      cityCode,
                      city: cityName,
                    }));

                    await barangays(cityCode)?.then((barangayResponse) => {
                      const barangay = barangayResponse?.find((barangay) => barangay?.brgy_name === address?.barangay);
                      const barangayName = barangay?.brgy_name || '';
                      const barangayCode = barangay?.brgy_code || '';

                      if (barangayCode) {
                        setConstructedAddress((prev) => ({
                          ...prev,
                          barangayCode,
                          barangay: barangayName,
                          addressLine1: address?.addressLine1,
                        }));
                      }
                    });
                  }
                });
              }
            });
          }
        });
        setLoading(false);
      })();
    } else {
      setLoading(false);
    }
  }, [address]);

  return {
    loading,
    address: constructedAddress,
  };
}

export default useAddressHook;
