import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  IconButton,
} from '@mui/material';
import { decreaseUserChoiceCategoryById, increaseUserChoiceCategoryById } from 'modules/registration/actions';
import { userChoiceCategoryQuantityByIdSelector } from 'modules/registration/selector';
import { getActiveLatestPrice, fCurrency, formatDistanceValue, totalSlotsAndRegistrants } from 'utils';
// components
import { ReturnType } from 'constants/index';
import Iconify from 'components/Iconify';
import { TableHeadCustom } from 'components/table';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'category', label: 'Category', width: 150 },
  { id: 'quantity', label: 'Quantity' },
  { id: 'price', label: 'Price' },
  { id: 'totalPrice', label: 'Total Price', align: 'right' },
  { id: '' },
];

const IncrementerStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(0.5),
  padding: theme.spacing(0.5, 0.75),
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`,
}));

// ----------------------------------------------------------------------

SignupCategoryList.propTypes = {
  categories: PropTypes.array.isRequired,
  onDecreaseQuantity: PropTypes.func,
  onIncreaseQuantity: PropTypes.func,
  eventImages: PropTypes.array,
  metricUsedIsKm: PropTypes.bool,
  canHaveMultipleRegistrantsInOneOrder: PropTypes.bool,
  registrationOrderTotalQuantity: PropTypes.number,
};

export default function SignupCategoryList({
  categories,
  metricUsedIsKm,
  eventImages,
  canHaveMultipleRegistrantsInOneOrder,
  registrationOrderTotalQuantity,
  slotsAvailabilityIsSameForAllCategories,
}) {
  return (
    <TableContainer sx={{ minWidth: 720 }}>
      <Table>
        <TableHeadCustom headLabel={TABLE_HEAD} />

        <TableBody>
          {categories.map((category) => (
            <SignupCategoryListRow
              key={category.id}
              category={category}
              metricUsedIsKm={metricUsedIsKm}
              eventImages={eventImages}
              slots={
                totalSlotsAndRegistrants(categories, slotsAvailabilityIsSameForAllCategories, category?.id)
                  ?.availableSlots || 0
              }
              canHaveMultipleRegistrantsInOneOrder={canHaveMultipleRegistrantsInOneOrder}
              registrationOrderTotalQuantity={registrationOrderTotalQuantity}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// ----------------------------------------------------------------------

// SignupCategoryListRow.propTypes = {
//   onDecrease: PropTypes.func,
//   onIncrease: PropTypes.func,
//   category: PropTypes.shape({
//     available: PropTypes.number,
//     color: PropTypes.string,
//     cover: PropTypes.string,
//     name: PropTypes.string,
//     price: PropTypes.number,
//     quantity: PropTypes.number,
//     size: PropTypes.string,
//   }),
// };

function SignupCategoryListRow({
  category,
  metricUsedIsKm,
  registrationOrderTotalQuantity,
  canHaveMultipleRegistrantsInOneOrder,
  slots,
}) {
  const {
    distance,
    categoryType,
    categoryValue,
    allowableQuantityPerOrder,
    regPrice: { prices },
    id,
  } = category;

  const totalCategoryQuantityById = useSelector(userChoiceCategoryQuantityByIdSelector(id));
  const priceToDisplay = getActiveLatestPrice(prices, ReturnType.ValueOnlyWithCurrencyFormat);

  return (
    <TableRow>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack spacing={0.5}>
          <Typography noWrap variant="subtitle2" sx={{ maxWidth: 240 }} color="red">
            <b>
              {formatDistanceValue(metricUsedIsKm, distance)}&nbsp; ({categoryValue || 'Open'})
            </b>
          </Typography>

          <Stack direction="column" alignItems="left">
            <Typography variant="body2">
              <Box component="span" sx={{ color: 'text.secondary' }}>
                type:&nbsp;
              </Box>
              {categoryType || '-'}
            </Typography>

            {/* <Divider orientation="" sx={{ mx: 1, height: 16 }} /> */}

            <Typography variant="body2">
              <Box component="span" sx={{ color: 'text.secondary' }}>
                sub:&nbsp;
              </Box>
              {categoryValue || 'Open'}
            </Typography>
          </Stack>
        </Stack>
      </TableCell>

      <TableCell>
        <Incrementer
          quantity={totalCategoryQuantityById || 0}
          allowableQuantityPerOrder={!canHaveMultipleRegistrantsInOneOrder ? 1 : allowableQuantityPerOrder || 5}
          registrationOrderTotalQuantity={registrationOrderTotalQuantity}
          canHaveMultipleRegistrantsInOneOrder={canHaveMultipleRegistrantsInOneOrder}
          categoryId={id}
          slots={slots}
        />
      </TableCell>

      <TableCell>{priceToDisplay?.[0]?.type ?? 'Free'}</TableCell>

      <TableCell align="right">
        {fCurrency((priceToDisplay?.[0]?.amountInCent || 0) * totalCategoryQuantityById, priceToDisplay?.[0]?.currency)}
      </TableCell>
    </TableRow>
  );
}

// ----------------------------------------------------------------------

Incrementer.propTypes = {
  allowableQuantityPerOrder: PropTypes.number,
  quantity: PropTypes.number,
  categoryId: PropTypes.string,
  slots: PropTypes.number,
};

function Incrementer({
  allowableQuantityPerOrder,
  quantity,
  canHaveMultipleRegistrantsInOneOrder,
  registrationOrderTotalQuantity,
  categoryId,
  slots,
}) {
  const dispatch = useDispatch();

  const onDecrease = useCallback(
    (id) => {
      dispatch(decreaseUserChoiceCategoryById(id));
    },
    [dispatch]
  );

  const onIncrease = useCallback(
    (id) => {
      dispatch(increaseUserChoiceCategoryById(id));
    },
    [dispatch]
  );

  return (
    <Box sx={{ width: 96, textAlign: 'right' }}>
      <IncrementerStyle>
        <IconButton
          size="small"
          color="inherit"
          onClick={() => onDecrease(categoryId)}
          disabled={
            !slots ||
            quantity === 0 ||
            (!canHaveMultipleRegistrantsInOneOrder && registrationOrderTotalQuantity >= 1 && quantity === 0)
          }
        >
          <Iconify icon={'eva:minus-fill'} width={16} height={16} />
        </IconButton>

        {quantity}

        <IconButton
          size="small"
          color="inherit"
          onClick={() => onIncrease(categoryId)}
          disabled={
            !slots ||
            (canHaveMultipleRegistrantsInOneOrder && quantity >= allowableQuantityPerOrder) ||
            (!canHaveMultipleRegistrantsInOneOrder && registrationOrderTotalQuantity >= 1)
          }
        >
          <Iconify icon={'eva:plus-fill'} width={16} height={16} />
        </IconButton>
      </IncrementerStyle>

      {slots && slots > 0 ? (
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          Allowable: {allowableQuantityPerOrder}
        </Typography>
      ) : (
        <Typography variant="caption" sx={{ color: 'red' }}>
          No Slots Available
        </Typography>
      )}
    </Box>
  );
}
