import { RHFMultiCheckbox, RHFCheckbox } from './RHFCheckbox';
import { RHFUploadAvatar, RHFUploadSingleFile, RHFUploadMultiFile } from './RHFUpload';
import {
  DefaultValueField,
  DynamicForm,
  FieldIdField,
  LabelField,
  MaxValueField,
  MinValueField,
  PlaceholderField,
  isEmailField,
  isNumberOnlyField,
  isRequiredField,
  OptionsField,
} from './DynamicForm';

import FormProvider from './FormProvider';

import RHFSwitch from './RHFSwitch';
import RHFSelect from './RHFSelect';
import RHFEditor from './RHFEditor';
import RHFTextField from './RHFTextField';
import RHFRadioGroup from './RHFRadioGroup';
import RHFAutocompleteTextField from './RHFAutocompleteTextField';

export {
  RHFSelect,
  RHFSwitch,
  RHFEditor,
  RHFTextField,
  RHFRadioGroup,
  FormProvider,
  RHFUploadAvatar,
  RHFUploadSingleFile,
  RHFUploadMultiFile,
  RHFMultiCheckbox,
  RHFCheckbox,
  DynamicForm,
  RHFAutocompleteTextField,
};

export const fieldTypes = {
  Checkbox: 'Checkbox',
  TextBox: 'TextBox',
  Radio: 'Radio',
  Select: 'Select',
};

export const fieldMap = {
  TextBox: RHFTextField,
  Radio: RHFRadioGroup,
  Checkbox: RHFMultiCheckbox,
  Select: RHFSelect,
};

export const FieldProperties = {
  LABEL: 'label',
  PLACEHOLDER: 'placeholder',
  OPTIONS: 'options',
  DEFAULT_VALUE: 'defaultValue',
  IS_NUMBER_ONLY: 'isNumberOnly',
  IS_EMAIL: 'isEmailField',
  TYPE_EMAIL: 'email',
  TYPE_NUMBER: 'number',
  IS_REQUIRED: 'isRequired',
  REQUIRED: 'required',
  TYPE: 'type',
  MIN_VALUE: 'min',
  MAX_VALUE: 'max',
  FIELD_ID: 'fieldId',
  ORDER_NUMBER: 'orderNumber',
  VALIDATION_TYPE: 'validationType',
  VALIDATIONS: 'validations',
};

export const fieldTypeDefaultProperties = [
  [FieldProperties.LABEL],
  [FieldProperties.FIELD_ID],
  [FieldProperties.PLACEHOLDER],
  [FieldProperties.DEFAULT_VALUE],
  [FieldProperties.IS_REQUIRED],
];

export const fieldTypePropertiesMap = {
  [FieldProperties.LABEL]: LabelField,
  [FieldProperties.FIELD_ID]: FieldIdField,
  [FieldProperties.PLACEHOLDER]: PlaceholderField,
  [FieldProperties.DEFAULT_VALUE]: DefaultValueField,
  [FieldProperties.IS_REQUIRED]: isRequiredField,
  [FieldProperties.MAX_VALUE]: MaxValueField,
  [FieldProperties.MIN_VALUE]: MinValueField,
  [FieldProperties.IS_NUMBER_ONLY]: isNumberOnlyField,
  [FieldProperties.IS_EMAIL]: isEmailField,
  [FieldProperties.OPTIONS]: OptionsField,
};

export const dependencyType = {
  REQUIRED: 'required',
  DISABLED: 'disabled',
};

export const fieldTypePropertiesDependecies = {
  [FieldProperties.IS_EMAIL]: [
    {
      type: dependencyType.DISABLED,
      field: dependencyType.MAX_VALUE,
      parentValue: true, // If Is Email goes true run this command
      typeTOCheck: FieldProperties.TYPE_EMAIL,
    },
    {
      type: [dependencyType.DISABLED],
      field: [dependencyType.MIN_VALUE],
    },
  ],
};

export const fieldTypeProperties = {
  [fieldTypes.TextBox]: [
    ...fieldTypeDefaultProperties,
    [FieldProperties.IS_NUMBER_ONLY],
    [FieldProperties.IS_EMAIL],
    [FieldProperties.MIN_VALUE],
    [FieldProperties.MAX_VALUE],
  ],
  [fieldTypes.Radio]: [
    [FieldProperties.LABEL],
    [FieldProperties.FIELD_ID],
    [FieldProperties.OPTIONS],
    [FieldProperties.DEFAULT_VALUE],
    [FieldProperties.IS_REQUIRED],
  ],
  [fieldTypes.Checkbox]: [
    [FieldProperties.LABEL],
    [FieldProperties.FIELD_ID],
    [FieldProperties.OPTIONS],
    // [FieldProperties.DEFAULT_VALUE], // TODO fix this issue if these feature release
    [FieldProperties.IS_REQUIRED], // TODO fix this issue if these feature release
  ],
  [fieldTypes.Select]: [
    [FieldProperties.LABEL],
    [FieldProperties.FIELD_ID],
    [FieldProperties.OPTIONS],
    [FieldProperties.DEFAULT_VALUE],
    [FieldProperties.IS_REQUIRED],
  ],
};

export const disableFieldOnLabelMapping = [fieldTypes.Checkbox];
export const needsLabelFieldsMapping = [fieldTypes.Checkbox, fieldTypes.Radio];
export const uneditableFormFieldsNames = [
  'firstName',
  'lastName',
  'age',
  'gender',
  'phoneNumber',
  'emergencyContactName',
  'emergencyContactNumber',
];
