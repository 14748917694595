import { useCallback, useState, useMemo, useEffect } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { generatePath, useParams } from 'react-router-dom';
import { Grid, Button, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ProgressPage, PAYMENT_OPTIONS, CheckoutErrorType, Routes, AppConstants } from 'constants/index';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Iconify from 'components/Iconify';
import { getPath, titleUrlFormatter } from 'utils';
import useAuth from 'hooks/useAuth';
import { FormProvider } from 'components/hook-form';
import GeneralModal from 'components/GeneralModal';
import {
  updateEventRegistrationOrder,
  preCheckoutChecker,
  getCalculatedRegistrationCart,
} from 'modules/registration/actions';
import { constantsSelector } from 'modules/app/selector';
import { registrationDetailsSelector } from 'modules/registration/selector';
import RegistrationCartSummary from './RegistrationCartSummary';
import CheckoutBillingShippingAddress from './CheckoutBillingShippingAddress';
import CheckoutPaymentMethods from './CheckoutPaymentMethods';

export default function CheckoutPayment({ event, isCartSummaryFetchedSuccess }) {
  const [enableNextCard, setEnableNextCard] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [previewOrder, setPreviewOrder] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const history = useHistory();
  const { id: eventId, title: eventName } = useParams();
  const dispatch = useDispatch();

  const { user } = useAuth();

  const registrationDetails = useSelector(registrationDetailsSelector);
  const appConstants = useSelector(constantsSelector);

  const billingShippingAddress = user?.address?.billingShippingAddress;

  const appPaymentOptions = appConstants?.[AppConstants.PAYMENT_OPTIONS];
  const checkPaymentOptionsAvailable = appPaymentOptions ? JSON.parse(appPaymentOptions || '{}') : PAYMENT_OPTIONS;

  const handleBackClick = useCallback(async () => {
    await dispatch(updateEventRegistrationOrder({ progress: ProgressPage.summary }));
  }, [dispatch]);

  const PaymentSchema = Yup.object().shape({
    payment: Yup.string().required('Payment is required!'),
  });

  const defaultValues = {
    delivery: 'onsite',
    payment: '',
  };

  const methods = useForm({
    resolver: yupResolver(PaymentSchema),
    defaultValues,
  });

  const { handleSubmit, watch } = methods;

  const watchPayment = watch('payment');
  const watchDelivery = watch('delivery');

  const shouldEnableButton = Boolean(watchPayment) && enableNextCard === 2 && isCartSummaryFetchedSuccess; // 2 as of the moment once delivery is implemented then complete the logic

  const handleRedirectPreview = useCallback(async () => {
    if (watchPayment && watchDelivery) {
      setPreviewOrder((prev) => !prev);
    }
  }, [watchPayment, watchDelivery]);

  useEffect(() => {
    (async () => {
      if (previewOrder && watchPayment) {
        await dispatch(getCalculatedRegistrationCart(registrationDetails?.id, watchPayment));
      }
    })();
  }, [watchPayment, previewOrder, registrationDetails]);

  const onSubmit = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const payload = {
        paymentMethod: watchPayment,
        deliveryOption: watchDelivery,
      };
      const data = await dispatch(preCheckoutChecker(payload));
      if (data?.payload?.type === CheckoutErrorType.SlotsAvailability) {
        setModalData({
          title: 'Slot availability issue',
          message:
            data?.payload?.message ||
            'Slot availability is less than what is required, remove some of slots or try again later',
          id: 'slots-availability',
          buttonOneText: 'Back to event',
          // buttonTwoText: 'Remove Some',
          buttonOneClick: () => {
            history.push(generatePath(getPath(Routes.EVENT), { title: titleUrlFormatter(eventName), id: eventId }));
            setModalOpen(false);
          },
          // buttonTwoClick: async () => {
          //   setLoadingModal(true);
          //   await dispatch(updateEventRegistrationOrder({ progress: ProgressPage.summary }));
          //   setLoadingModal(false);
          //   setModalOpen(false);
          // },
        });
        setModalOpen(true);
      } else if (data?.payload?.type === CheckoutErrorType.PriceChange) {
        setModalData({
          title: 'Price Changes',
          message:
            data?.payload?.message || "There's a sudden change of price, These might be caused by price validity date.",
          id: 'price-change',
          buttonOneText: 'Refresh Cart',
          buttonTwoText: 'Back to event',
          buttonOneClick: async () => {
            setLoadingModal(true);
            await dispatch(getCalculatedRegistrationCart());
            setLoadingModal(false);
            setModalOpen(false);
          },
          buttonTwoClick: () => {
            history.push(generatePath(getPath(Routes.EVENT), { title: titleUrlFormatter(eventName), id: eventId }));
            setModalOpen(false);
          },
        });
        setModalOpen(true);
      } else if (data?.payload?.redirect?.includes('https')) {
        setModalOpen(true);
        setModalData({
          title: "Redirecting (Don't refresh the page)",
          message: `We will redirect you to ${data?.payload?.type?.toUpperCase()} in 3 seconds...`,
          id: 'redirect-modal',
        });
        setTimeout(() => {
          setModalOpen(true);
          window.location.href = data?.payload?.redirect;
        }, 3500);
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  }, [watchPayment, watchDelivery]);

  return (
    <>
      {modalData?.title && modalOpen && <GeneralModal {...modalData} open={modalOpen} loading={loadingModal} />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Button
              color="inherit"
              onClick={previewOrder ? handleRedirectPreview : handleBackClick}
              startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}
            >
              Back
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          {!previewOrder && (
            <CheckoutBillingShippingAddress
              billingShippingAddress={billingShippingAddress}
              setEnableNextCard={setEnableNextCard}
              cardNumber={1}
            />
          )}
          <FormProvider methods={methods} onSubmit={handleSubmit(() => {})}>
            {/* {!previewOrder && (
              <CheckoutDelivery
                onApplyShipping={handleApplyShipping}
                deliveryOptions={deliveryOptions || defaultDeliveryOptions}
                enableNextCard={enableNextCard}
                cardNumber={2}
                setEnableNextCard={setEnableNextCard}
              />
            )}
            )} */}
            <CheckoutPaymentMethods
              cardOptions={[]} // CARDS_OPTIONS
              paymentOptions={checkPaymentOptionsAvailable}
              enableNextCard={enableNextCard}
              isPayNowStep={previewOrder}
              cardNumber={2}
              setEnableNextCard={setEnableNextCard}
            />
          </FormProvider>
        </Grid>

        <Grid item xs={12} md={4}>
          <RegistrationCartSummary isPayNowStep={previewOrder} isCheckoutPage />
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            loading={isSubmitting}
            disabled={!shouldEnableButton}
            onClick={!previewOrder ? handleRedirectPreview : onSubmit}
          >
            {previewOrder ? 'Pay now' : 'Preview Payment'}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
