import { useCallback, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Card, Stack, Typography } from '@mui/material';
import { RHFUploadSingleFile } from 'components/hook-form';
import { useS3Hook } from 'hooks/useS3hook';
import { S3FileType, S3ImageSizes, AcceptedFiles } from 'constants/index';
// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

export default function CoverImage({ setValue, name, image: mainImage, belongsToId = '' }) {
  const imageRef = useRef('');

  const { handleDrop: handleDropS3, getImage, isComplete, imageUrlRef, progress: uploadProgress } = useS3Hook();

  // Photo URL
  useEffect(() => {
    if (mainImage?.urlImage && !imageRef.current) {
      (async () => {
        const item = await getImage(
          mainImage?.urlImage,
          S3FileType.profile,
          { S3FileName: mainImage?.urlImage },
          S3ImageSizes.original
        );
        setValue(name, item);
        imageRef.current = item;
      })();
    } else if (imageRef.current) {
      setValue(name, imageUrlRef);
      imageRef.current = imageUrlRef;
    }
  }, [mainImage, name, imageUrlRef, imageRef]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          name,
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        handleDropS3(acceptedFiles, S3FileType.profile, {
          belongsTo: belongsToId || '',
          ...(mainImage?.id && { id: mainImage?.id }),
          updateDynamoImageLink: true,
          deleteOld: true,
          oldUrl: mainImage?.urlImage || '',
        });
      }
    },
    [setValue, mainImage, belongsToId, name]
  );

  return (
    <Card sx={{ p: 3, pb: 3 }}>
      <Stack spacing={3} sx={{ pb: 3 }}>
        <div>
          <LabelStyle>Cover Image</LabelStyle>
          <RHFUploadSingleFile
            name={name}
            dropTitle={'Drop or Select Image'}
            accept={AcceptedFiles.images}
            maxSize={3145728}
            onDrop={handleDrop}
            loading={!isComplete}
            uploadProgress={uploadProgress}
          />
        </div>
      </Stack>
    </Card>
  );
}
