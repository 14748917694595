import { createSelector } from 'reselect';
import { filterByRoleHelper } from 'utils/index';
import { OrganizationStaffRoleType } from 'constants/index';

export const userSelector = createSelector(
  (state) => state.user,
  (user) => user
);

export const isAuthSelector = createSelector(userSelector, (user) => user.isAuth);

export const isUserOrganizerSelector = createSelector(userSelector, (user) => user.organizedEvents?.length > 0);

export const userOrganizationsSelector = createSelector(userSelector, (user) => user.organizations);

export const userOrganizedEventsSelector = createSelector(userSelector, (user) => user.organizedEvents);

export const userOrganizedEventsByOrgIdSelector = (orgId) =>
  createSelector(userOrganizedEventsSelector, (events) => events?.filter((event) => event?.organizationId === orgId));

export const userHasOrganizationSelector = createSelector(userSelector, (user) => user.organizations?.length > 0);

export const isUserInitializedSelector = createSelector(userSelector, (user) => user.isUserInitialized);

export const userDataSelector = createSelector(userSelector, (user) => user.data);

export const errorSelector = createSelector(userSelector, (user) => user.errors);

export const userLoadingSelector = createSelector(userSelector, (user) => user.isLoading);

export const isUserQuerySuccessSelector = createSelector(userSelector, (user) => user.isSuccess);

export const userEmailSelector = createSelector(userDataSelector, (userData) => userData.email);

export const userProfileImageObjSelector = createSelector(
  userDataSelector,
  (userData) => userData?.profilePhoto || null
);

export const userIdSelector = createSelector(userDataSelector, (userData) => userData.id);

export const userSubIdSelector = createSelector(userDataSelector, (userData) => userData.userId);

export const userFirstNameSelector = createSelector(userDataSelector, (userData) => userData.firstName);

export const userLastNameSelector = createSelector(userDataSelector, (userData) => userData.lastName);

export const userDynamoIdSelector = createSelector(userDataSelector, (userData) => userData.id || userData.userId);

export const addressesSelector = createSelector(userDataSelector, (userData) => userData.address);

export const primaryAddressSelector = createSelector(addressesSelector, (address) => address.primaryAddress);

export const billingShippingAddressSelector = createSelector(
  addressesSelector,
  (address) => address.billingShippingAddress
);

export const isEmailConfirmedSelector = createSelector(userDataSelector, (userData) => userData.isEmailVerified);

export const isNotAuthenticatedOrVerifiedSelector = createSelector(
  [isAuthSelector, isEmailConfirmedSelector],
  (isAuthenticated, isVerified) => !isAuthenticated || (isAuthenticated && !isVerified)
);

export const isAuthAndConfirmedSelector = createSelector(
  [isAuthSelector, isEmailConfirmedSelector],
  (isAuth, isConfirmed) => isAuth && isConfirmed
);

export const userFullNameSelector = createSelector(
  userDataSelector,
  (userData) => `${userData.firstName} ${userData.lastName}`
);

export const userCreatedOrganizationsSelector = createSelector(
  [userOrganizationsSelector, userIdSelector],
  (organizations, userId) => {
    return organizations?.filter(
      (organization) =>
        organization?.user?.id === userId &&
        filterByRoleHelper(organization?.roles || [], OrganizationStaffRoleType.CREATOR)
    );
  }
);

export const userSubscriptionsSelector = createSelector(userSelector, (user) => {
  return user.subscriptions || [];
});

export const userSubscriptionSelector = createSelector(userSelector, (user) => {
  return user.subscription;
});

export const userSubscriptionAllowableOrganizationSelector = createSelector(userSelector, (user) => {
  return user.subscription?.organizations;
});

export const userExceedsOrganizationLimitsSelector = createSelector(
  [userSubscriptionAllowableOrganizationSelector, userCreatedOrganizationsSelector],
  (allowable, createdOrganizations) => {
    return (createdOrganizations?.length || 0) >= allowable;
  }
);
