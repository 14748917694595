import { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DynamicForm as DynamicFormCreator } from 'components/hook-form';
import { ProgressPage, defaultCategoryForm } from 'constants/index';
import { useDispatch, useSelector } from 'react-redux';
import {
  upsertRegistrantData,
  updateEventRegistrationOrder,
  getUserRegisteredParticipantsData,
} from 'modules/registration/actions';
import { Box, Grid, Card, Button, Typography, Stack, Collapse } from '@mui/material';
import { userDataSelector } from 'modules/user/selector';
import { LoadingButton } from '@mui/lab';
import { _addressBooks } from '_mock';
import Label from 'components/Label';
import Iconify from 'components/Iconify';
import { userRegisteredParticipantsDataSelector } from 'modules/registration/selector';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { formatCategoryValue, sortFormFields, sortForms, sortFormsByLatestUpdate } from 'utils';
import ModalResetProgress from './ModalResetProgress';
import RegistrationCartSummary from './RegistrationCartSummary';

export default function RegistrationForm({
  registrationDetailLineItems = [],
  eventCategories = [],
  metricUsedIsKm,
  isCartSummaryFetchedSuccess = false,
  handleBackClick: handleBackClickProps = null,
  shouldBackToEvent: handleShouldBackToEvent = false,
}) {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  const [openBackModalForReset, setOpenBackModalForReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enabledNext, setEnabledNext] = useState(false);

  const authUserData = useSelector(userDataSelector);
  const participants = useSelector(userRegisteredParticipantsDataSelector);

  const shouldBackToEvent = handleShouldBackToEvent && handleBackClickProps;

  const eventCategoryMapping = useCallback(
    (categoryId) => eventCategories.find((category) => category?.id === categoryId),
    [eventCategories]
  );

  useEffect(
    () => {
      if (isMountedRef.current) {
        (async () => {
          await dispatch(getUserRegisteredParticipantsData());
        })();
      }

      return () => {
        isMountedRef.current = false;
      };
    },
    // eslint-disable-next-line
    [dispatch, isMountedRef.current]
  );

  // enable disable next button
  useEffect(() => {
    if (registrationDetailLineItems?.length && eventCategories?.length) {
      const booleanArray = registrationDetailLineItems?.map((item) => {
        const registrantsList = item?.registrantData?.items || [];
        // TODO display only one Form and sort it to most updated as sometimes setting up for form in admins side has some issues in deleting
        // FIXME Redo this logic once multiple forms are implemented
        // const categoryFormLength = eventCategoryMapping(item?.categoryId)?.form?.items?.length || null;
        const categoryFormLength = 1;
        if (categoryFormLength) {
          return registrantsList?.length === categoryFormLength;
        }
        return false;
      });
      setEnabledNext(booleanArray.some((val) => !val)); // if there's a false in array
    }
  }, [registrationDetailLineItems, eventCategories, eventCategoryMapping]);

  const handleModalState = useCallback(() => {
    setOpenBackModalForReset((prev) => !prev);
  }, []);

  const handleNextStep = useCallback(async () => {
    setLoading(true);
    await dispatch(updateEventRegistrationOrder({ progress: ProgressPage.summary }));
    setLoading(false);
  }, []);

  return (
    <>
      {openBackModalForReset && <ModalResetProgress onClose={handleModalState} open={openBackModalForReset} />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              size="small"
              color="inherit"
              onClick={shouldBackToEvent ? handleBackClickProps : handleModalState}
              startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}
            >
              Back
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          {registrationDetailLineItems?.map((lineItem, index) => (
            <FormDetails
              key={index}
              index={index}
              lineItemCategory={lineItem}
              lineItemsLength={registrationDetailLineItems?.length || 0}
              category={eventCategoryMapping(lineItem?.categoryId) || {}}
              handleNextStep={handleNextStep}
              setEnabledNext={setEnabledNext}
              metricUsedIsKm={metricUsedIsKm}
              authUserData={authUserData}
              prefillOptions={participants || []}
            />
          ))}
        </Grid>

        <Grid item xs={12} md={4}>
          <RegistrationCartSummary sx={{ mb: 6 }} />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            onClick={handleNextStep}
            disabled={enabledNext || !isCartSummaryFetchedSuccess}
            sx={{ mb: 4 }}
          >
            Next Step
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}

const FormDetails = ({
  lineItemCategory,
  category,
  index,
  lineItemsLength,
  handleNextStep,
  setEnabledNext,
  metricUsedIsKm,
  authUserData,
  prefillOptions = [],
}) => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(index === 0); // Open first collapsable
  const [openForm, setOpenForm] = useState(1); // Open first collapsable

  const registrantsData = lineItemCategory?.registrantData?.items || [];

  const sortedCategoryFormByFormNumber = sortForms(
    category?.form?.items?.length ? category?.form?.items : [defaultCategoryForm]
  );

  // TODO display only one Form and sort it to most updated as sometimes setting up for form in admins side has some issues in deleting
  // FIXME Redo this logic once multiple forms are implemented

  const categoryForms = [sortFormsByLatestUpdate(sortedCategoryFormByFormNumber)?.[0]];

  const categoryFormFields = (form) => sortFormFields(form?.formFields || []);

  const handleEditableClick = useCallback(() => {
    setEditable((prev) => !prev);
  }, []);

  const onSubmitActionHandler = useCallback(
    async (values, valuesHasChanged, formNumber, registrantDataId, teamTitleHasChanged) => {
      // TODO check if there's really a change of data
      await dispatch(
        upsertRegistrantData(
          values,
          valuesHasChanged,
          formNumber,
          lineItemCategory?.id || '',
          category?.id,
          registrantDataId,
          teamTitleHasChanged,
          registrantsData?.map((item) => item?.id)
        )
      ).then(() => {
        setOpenForm((prevVal) => (categoryForms?.length <= prevVal + 1 ? prevVal + 1 : 1));
      });
    },
    [dispatch, lineItemCategory, category]
  );

  return (
    <Card sx={{ p: 3, mb: 3, position: 'relative', border: 1 }}>
      <Label color="info" sx={{ ml: 1 }}>
        {formatCategoryValue(metricUsedIsKm, category)}
      </Label>

      <Stack spacing={1} direction="column" sx={{ m: 1, position: 'relative' }}>
        {lineItemsLength > 1 &&
          !editable &&
          registrantsData?.map((data) => {
            const userData = data?.userData
              ? typeof data?.userData === 'string'
                ? JSON.parse(data?.userData)
                : data?.userData
              : {};
            return (
              <Box key={data.id} sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1">{`${userData?.firstName || ''} ${
                  userData?.lastName || ''
                }`}</Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  &nbsp;({userData?.gender || ''})
                </Typography>
              </Box>
            );
          })}
      </Stack>

      {categoryForms?.map((form, ind) => {
        return (
          <Collapse in={editable} sx={{ width: 1 }} key={ind}>
            <DynamicFormCreator
              formFields={categoryFormFields(form)}
              currentLineItemIndexPlusOne={index + 1}
              registrantData={
                registrantsData?.find((registrant) => registrant?.formOrderNumber === form?.formOrderNumber) ?? {}
              }
              teamName={registrantsData?.find((registrant) => registrant?.teamName)?.teamName ?? ''}
              numberOfForms={categoryForms?.length}
              formNumber={form?.formOrderNumber}
              openForm={openForm}
              setOpenForm={setOpenForm}
              lineItemsLength={lineItemsLength}
              onSubmitActionHandler={onSubmitActionHandler}
              nextPageCall={handleNextStep}
              setEnabledNext={setEnabledNext}
              authUserData={authUserData}
              prefillOptions={prefillOptions}
            />
          </Collapse>
        );
      })}

      {lineItemsLength > 1 && (
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            ...(!editable && { position: { sm: 'absolute' }, right: { sm: 24 }, bottom: { sm: 24 } }),
          }}
        >
          <Button variant="outlined" size="small" onClick={handleEditableClick}>
            {editable ? 'Minimize' : 'Edit'}
          </Button>
          <Box sx={{ mx: 0.5 }} />
        </Box>
      )}
    </Card>
  );
};

FormDetails.propTypes = {
  lineItemCategory: PropTypes.any,
  category: PropTypes.any,
  index: PropTypes.number,
};
