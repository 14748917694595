import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Stack,
  Dialog,
  Button,
  Divider,
  DialogTitle,
  DialogContent,
  ButtonGroup,
  Typography,
  Link,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoginForm } from 'sections/auth/login/LoginForm';
import { RegisterForm } from 'sections/auth/register';
import { ForgotPasswordForm } from 'sections/auth/forgotPassword';
import { ConfirmSignUpForm } from 'sections/auth/confirmSignup';
import { userEmailSelector } from 'modules/user/selector';
import { resendConfirmation } from 'modules/user/actions';
import { createAlert } from 'modules/alerts/actions';
import Logo from 'components/Logo';
import { CloseIcon } from 'theme/overrides/CustomIcons';
import { emptyFunction } from 'utils';

// ----------------------------------------------------------------------

SignupAuthForm.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onNextStep: PropTypes.func,
  onCreateBilling: PropTypes.func,
};

const active = {
  login: 'login',
  register: 'register',
  confirm: 'confirm',
  forgotPassword: 'forgotPassword',
};

export default function SignupAuthForm({
  open,
  authenticated,
  isEmailVerified,
  handleBackButton,
  signInRedirect = null,
  startIcon = null,
  closeButtonText = null,
}) {
  const dispatch = useDispatch();

  const [activeState, setActiveState] = useState(active.login);

  const [sendingCode, setSendingCode] = useState(false);
  const [numberOfTimesInSendingCode, setNumberOfTimesInSendingCode] = useState(0);
  const [resendIsClicked, setResendIsClicked] = useState(false);
  const [delayValueString, setDelayValueString] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [typedEmail, setTypedEmail] = useState('');

  const userEmail = useSelector(userEmailSelector);

  const disableForgotPasswordTextField = authenticated && (userEmail || (typedEmail && isEmailValid));

  useEffect(() => {
    if (!authenticated) {
      setActiveState(active.login);
    } else if (authenticated && !isEmailVerified) {
      setActiveState(active.confirm);
    }
  }, [authenticated, isEmailVerified]);

  //  ------------- Email Confirmation Component ------------------
  const handleClickResendCode = useCallback(async () => {
    setSendingCode(true);
    if ((userEmail || typedEmail) && isEmailValid) {
      await dispatch(resendConfirmation(userEmail || typedEmail));
      // eslint-disable-next-line
      setNumberOfTimesInSendingCode((curr) => (curr += 1));
      setResendIsClicked(true);
    } else createAlert('CUSTOM_ERROR', 'Email address is not valid');
    setSendingCode(false);
  }, [typedEmail, isEmailValid]);

  useEffect(() => {
    if (resendIsClicked && numberOfTimesInSendingCode > 0) {
      setResendIsClicked(false);
      startTimer(numberOfTimesInSendingCode * 30);
    }
  }, [numberOfTimesInSendingCode, resendIsClicked]);

  const startTimer = (duration) => {
    let timer = duration;
    let minutes;
    let seconds;
    const resendTimer = setInterval(() => {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? '0'.concat(minutes) : minutes;
      seconds = seconds < 10 ? '0'.concat(seconds) : seconds;

      setDelayValueString(` ${minutes}:${seconds}`);

      // eslint-disable-next-line
      if (--timer < 0) {
        setDelayValueString('');
        clearInterval(resendTimer);
      }
    }, 1000);
  };

  //  ------------- End of Email Confirmation Component ------------------

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <DialogTitle>
          <IconButton aria-label="close" onClick={handleBackButton} style={{ position: 'absolute', top: 1, right: 1 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {activeState === active.login && (
            <SignInComponent
              userEmail={userEmail || typedEmail || ''}
              registerRedirect={() => setActiveState(active.register)}
              forgotPasswordRedirect={() => setActiveState(active.forgotPassword)}
              shouldRedirectTo={signInRedirect}
            />
          )}
          {activeState === active.register && (
            <RegisterComponent
              confirmRedirect={() => setActiveState(active.confirm)}
              loginRedirect={() => setActiveState(active.login)}
            />
          )}
          {activeState === active.forgotPassword && (
            <ForgotPasswordComponent
              userEmail={userEmail || typedEmail || ''}
              shouldDisable={disableForgotPasswordTextField}
              redirectAfterReset={() => {
                setActiveState(active.login);
              }}
              loginRedirect={() => setActiveState(active.login)}
            />
          )}
          {activeState === active.confirm && (
            <Box>
              <ConfirmSignUpFormComponent
                userEmail={userEmail || typedEmail || ''}
                setTypedEmail={setTypedEmail}
                setIsEmailValid={setIsEmailValid}
                isEmailVerified={isEmailVerified}
                isAuth={authenticated}
                hasOwnRedirect
                shouldRedirectTo={() => {
                  setActiveState(active.login);
                }}
              />
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                Didn't Get the code ?{' '}
                <LoadingButton
                  loading={sendingCode}
                  variant="text"
                  onClick={handleClickResendCode}
                  disabled={Boolean(delayValueString)}
                >
                  {' '}
                  <b>{`Resend Code ${delayValueString}`}</b>
                </LoadingButton>
              </Typography>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}

const SignInComponent = ({ userEmail, registerRedirect, forgotPasswordRedirect, shouldRedirectTo = emptyFunction }) => {
  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', textAlign: 'center', pb: 8 }}>
      <Box sx={{ mb: 3, textAlign: '-webkit-center' }}>
        <Logo disabledLink sx={{ width: '100px' }} />
      </Box>

      <Typography variant="h5" gutterBottom>
        Sign in to your account
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ pb: 2 }}>
        Don't have an account?{' '}
        <Link onClick={registerRedirect} sx={{ cursor: 'pointer' }}>
          Get started
        </Link>
      </Typography>

      <LoginForm
        userEmail={userEmail || ''}
        hasOwnRedirect
        showForgotPassword
        showConfirmEmail={false}
        forgotPasswordRedirect={forgotPasswordRedirect}
        shouldRedirectTo={shouldRedirectTo}
        noAutoFocus
      />
    </Box>
  );
};

const RegisterComponent = ({ confirmRedirect, loginRedirect }) => {
  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', textAlign: 'center', pb: 8 }}>
      <Box sx={{ mb: 3, textAlign: '-webkit-center' }}>
        <Logo disabledLink sx={{ width: '100px' }} />
      </Box>

      <Typography variant="h5" gutterBottom>
        Get started absolutely free
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ pb: 2 }}>
        Already have an account?{' '}
        <Link onClick={loginRedirect} sx={{ cursor: 'pointer' }}>
          Sign in
        </Link>
      </Typography>

      <RegisterForm hasOwnRedirect shouldRedirectTo={confirmRedirect} noAutoFocus />
    </Box>
  );
};

const ForgotPasswordComponent = ({ userEmail, loginRedirect, shouldDisable, redirectAfterReset }) => {
  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', textAlign: 'center', pb: 8 }}>
      <Box sx={{ mb: 3, textAlign: '-webkit-center' }}>
        <Logo disabledLink sx={{ width: '100px' }} />
      </Box>

      <Typography variant="h5" gutterBottom>
        Forgot Password
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ pb: 2 }}>
        No worries, we'll send you an email to confirm it was you and you can set a new one.{' '}
        <Link onClick={loginRedirect} sx={{ cursor: 'pointer' }}>
          Sign in
        </Link>
      </Typography>

      <ForgotPasswordForm
        userEmail={userEmail || ''}
        shouldDisable={shouldDisable}
        hasOwnRedirect
        shouldRedirectTo={redirectAfterReset}
        noAutoFocus
      />
    </Box>
  );
};

const ConfirmSignUpFormComponent = (props = {}) => {
  return (
    <Box sx={{ maxWidth: 400, mx: 'auto', textAlign: 'center' }}>
      <Box sx={{ mb: 3, textAlign: '-webkit-center' }}>
        <Logo disabledLink sx={{ width: '100px' }} />
      </Box>

      <Typography variant="h5" gutterBottom>
        Confirm your email
      </Typography>
      <Typography variant="body2" gutterBottom sx={{ pb: 2 }}>
        Please PASTE the CODE we sent to your email.{' '}
      </Typography>

      <ConfirmSignUpForm {...props} />
    </Box>
  );
};
