import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  Card,
  Checkbox,
  Stack,
  Typography,
  CardHeader,
  CardContent,
  FormControlLabel,
  FormControl,
  FormGroup,
  FormHelperText,
} from '@mui/material';

SummaryAttestation.propTypes = {
  numbersOfRequiredFields: PropTypes.number.isRequired,
};

export default function SummaryAttestation({
  numbersOfRequiredFields,
  cardHeaderTitle = 'Attestations and Agreements',
  watcher,
  controller,
  valueSetter,
}) {
  const formContext = useFormContext();
  const control = controller ?? formContext?.control;
  const watch = watcher ?? formContext?.watch;
  const setValue = valueSetter ?? formContext?.setValue;
  const attestations = watch('attestations');

  const checkedAttestations = attestations?.filter((item) => item.isChecked);

  const isError = numbersOfRequiredFields !== checkedAttestations.length;

  const handleChange = useCallback(
    (isChecked, option) => {
      const newAttestationValue = attestations; // initial value
      const attestationIndex = newAttestationValue?.findIndex((attestation) => attestation?.value === option?.value);
      newAttestationValue.splice(attestationIndex, 1, {
        ...option,
        isChecked,
      });
      setValue('attestations', newAttestationValue);
    },
    [attestations]
  );

  return (
    <Card>
      {cardHeaderTitle && <CardHeader title={cardHeaderTitle} />}
      <CardContent>
        <Controller
          name="attestations"
          control={control}
          render={({ field }) => {
            return (
              <FormControl required error={isError} component="fieldset" sx={{ m: 3 }} variant="standard">
                <Stack spacing={2} alignItems="left" direction={{ xs: 'column' }}>
                  {/* <FormLabel component="legend">Pick two</FormLabel> */}
                  <FormGroup>
                    {attestations.map((option) => {
                      const selected = option?.isChecked || false;

                      return (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selected}
                                onChange={() => handleChange(!selected, option)}
                                name={option?.value}
                              />
                            }
                            label={
                              <Box sx={{ ml: 1 }}>
                                <Typography variant="subtitle2">{option?.title}</Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                  {option?.label}
                                </Typography>
                              </Box>
                            }
                          />
                        </>
                      );
                    })}
                    {isError && <FormHelperText>All Items are required to be checked</FormHelperText>}
                  </FormGroup>
                </Stack>
              </FormControl>
            );
          }}
        />
      </CardContent>
    </Card>
  );
}
