import { useCallback } from 'react';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { isBefore } from 'date-fns';
import { Box, Grid, Card, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton, MobileDateTimePicker, MobileDatePicker } from '@mui/lab';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { eventDataDataSelector } from 'modules/event/selector';
import { updateEventData, updateDynamoEventData } from 'modules/event/actions';
import AddressForm from 'components/AddressForm';
import useAddressHook from 'hooks/useAddressHook';
import CoverImage from 'sections/organization/event/setup/general/CoverImage';
import { filterWithObjectFieldInObject, getRegistrationStartDate, getRegistrationEndDate } from 'utils/index';
import moment from 'moment-timezone';

// ----------------------------------------------------------------------

export const EventPrimaryPageHashes = {
  PRIMARY_INFORMATION: 'primary-information',
};

export default function EventPrimary() {
  const history = useHistory();
  const dispatch = useDispatch();
  const UpdateUserSchema = Yup.object().shape({
    eventName: Yup.string()
      .min(5, 'Event name should at least be 5 Characters')
      .required('Organization Name is required'),
  });

  const eventData = useSelector(eventDataDataSelector);

  const mainEventImage = eventData?.mainEventImage?.items?.[0];

  const { loading: addressHookLoading, address } = useAddressHook({ address: eventData });

  const registrationStartDate = getRegistrationStartDate(eventData?.registrationStart, eventData?.createdAt);

  const registrationEndDate = getRegistrationEndDate(eventData?.registrationEnd, eventData?.dateOfEvent);

  const defaultValues = {
    eventName: eventData?.eventName || '',
    eventShortDescription: eventData?.eventShortDescription || '',
    dateOfEvent: eventData?.dateOfEvent || moment().toDate(),
    registrationStart: registrationStartDate?.toDate() || moment().toDate(),
    registrationEnd: registrationEndDate?.toDate() || moment().toDate(),
    cover: [],
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors, isDirty },
    control,
    watch,
    setValue,
    reset,
  } = methods;

  const values = watch();

  const eventIsNotLaterThanToday = isBefore(new Date(values.dateOfEvent), Date.now());

  const shouldDisableButton = errors?.length || !isDirty;

  const eventDate = moment(values.dateOfEvent);
  const time = moment('23:59:59', 'HH:mm');
  const dateOfEventValue = moment(eventDate)
    .set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second'),
    })
    .format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');

  const onSubmit = useCallback(
    async (values) => {
      values.dateOfEvent = moment(dateOfEventValue);
      const reconstructedValues = filterWithObjectFieldInObject(values, eventData);
      const response = await dispatch(updateEventData({ id: eventData?.id, ...reconstructedValues }));
      const successResponse = Boolean(response?.payload?.success);
      if (successResponse) {
        reset(values);
      }
      return { success: successResponse || false };
    },
    [history, dispatch, filterWithObjectFieldInObject, eventData, dateOfEventValue]
  );

  const onSubmitAddressUpdate = useCallback(
    async (values) => {
      const reconstructedValues = filterWithObjectFieldInObject(values, eventData);
      const response = await dispatch(updateDynamoEventData({ id: eventData?.id, ...reconstructedValues }));
      const successResponse = Boolean(response?.payload?.success);
      return { success: successResponse };
    },
    [history, dispatch, filterWithObjectFieldInObject, eventData]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Primary
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <CoverImage setValue={setValue} image={mainEventImage} belongsToId={eventData?.id || ''} name={'cover'} />
          <Card sx={{ p: 3 }} id={EventPrimaryPageHashes.PRIMARY_INFORMATION}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField
                name="eventName"
                label="Event Name"
                inputProps={{ style: { textTransform: 'capitalize' } }}
              />

              <Controller
                name="dateOfEvent"
                control={control}
                render={({ field }) => (
                  <MobileDatePicker
                    {...field}
                    label="Date Of Event"
                    inputFormat="dd/MM/yyyy"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        helperText={
                          eventIsNotLaterThanToday && <Box>Date of Event is not later than today. (Warning only)</Box>
                        }
                      />
                    )}
                  />
                )}
              />

              <Controller
                name="registrationStart"
                control={control}
                render={({ field }) => (
                  <MobileDateTimePicker
                    {...field}
                    label="Registration Start"
                    inputFormat="dd/MM/yyyy hh:mm a"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        helperText={<Box>Date should not be later than registration end</Box>}
                      />
                    )}
                    maxDateTime={moment(values?.registrationEnd || dateOfEventValue).toDate()}
                  />
                )}
              />

              <Controller
                name="registrationEnd"
                control={control}
                render={({ field }) => (
                  <MobileDateTimePicker
                    {...field}
                    label="Registration End"
                    inputFormat="dd/MM/yyyy hh:mm a"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        helperText={
                          <Box>Date should not be later than event date and should be more than start date</Box>
                        }
                      />
                    )}
                    maxDateTime={moment(dateOfEventValue).toDate()}
                    minDateTime={moment(values?.registrationStart).toDate()}
                  />
                )}
              />
            </Box>
            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting} disabled={shouldDisableButton}>
                  Save Changes
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        </FormProvider>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Event Location
        </Typography>
        {!addressHookLoading && <AddressForm address={address} disabledReset handleOnSubmit={onSubmitAddressUpdate} />}
      </Grid>
    </Grid>
  );
}
