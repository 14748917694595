import { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Stack, Button, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { isUserOrganizerSelector, isAuthAndConfirmedSelector } from 'modules/user/selector';
import GeneralModal from 'components/GeneralModal';
import { setProfileView } from 'modules/app/actions';
import { DocIllustration } from 'assets';
import useAuth from 'hooks/useAuth';
import { ProfileView, Routes } from 'constants/index';
import { getPath } from 'utils';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const history = useHistory();
  const dispatch = useDispatch();

  const userHasEventsOrHasAlreadySetUpAsOrganization = useSelector(isUserOrganizerSelector);
  const isAuthAndConfirmed = useSelector(isAuthAndConfirmedSelector);

  const [isClicked, setIsClicked] = useState(false);
  const [openConfirmSwitchModal, setOpenConfirmModal] = useState(false);
  const { user } = useAuth();

  const handleOrganizerItemClick = useCallback(() => {
    setIsClicked(true);
    if (isAuthAndConfirmed) {
      setOpenConfirmModal(true);
    }
  }, [isAuthAndConfirmed]);

  useEffect(() => {
    if (isAuthAndConfirmed) {
      if (isClicked) {
        setOpenConfirmModal(true);
      }
    }
  }, [isAuthAndConfirmed, isClicked]);

  const handleCloseSwitchModal = () => setOpenConfirmModal((prev) => !prev);

  const handleRedirectSwitchModal = () => {
    dispatch(setProfileView(ProfileView.organization));
    history.push(getPath(Routes.ORGANIZATIONS_DASHBOARD));
    handleCloseSwitchModal(false);
  };

  return (
    <>
      <GeneralModal
        open={openConfirmSwitchModal}
        id="switch-modal-display"
        title="Confirm Switch"
        message="Confirmation to switch profile view from user to organization"
        buttonOneText="Cancel"
        buttonTwoText="Switch"
        buttonOneClick={handleCloseSwitchModal}
        buttonTwoClick={handleRedirectSwitchModal}
      />
      <Stack spacing={3} sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
        <DocIllustration sx={{ width: 1 }} />

        <div>
          <Typography gutterBottom variant="subtitle1">
            Hi, {`${user?.firstName} ${user?.lastName}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Want to
            <br /> {!userHasEventsOrHasAlreadySetUpAsOrganization ? 'Create an Event ?' : 'Manage your Events ?'}
          </Typography>
        </div>

        <Button variant="contained" onClick={handleOrganizerItemClick} disableRipple>
          {userHasEventsOrHasAlreadySetUpAsOrganization ? 'Switch to Organizer' : 'Become an Organizer'}
        </Button>
      </Stack>
    </>
  );
}
