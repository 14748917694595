import { handleActions } from 'redux-actions';
import {
  FETCH_EVENTS,
  EVENTS_FETCHED_SUCCESS,
  EVENTS_FETCHING,
  EVENT_FETCHED_SUCCESS,
  EVENT_FETCHING,
  SEARCH_EVENTS,
  SHOULD_DISPLAY_SEARCH_LIST,
  GET_EVENT_BY_ID,
  RESET_EVENT_DATA,
} from './types';

const initialState = {
  eventsListData: [],
  event: null,
  eventSearchListData: [],
  shouldDisplaySearchList: false,
  eventsFetchedSuccess: false,
  eventsFetching: false,
  eventFetchedSuccess: false,
  eventFetching: false,
  errors: [],
};

// eslint-disable-next-line
const ACTION_HANDLERS = {
  [FETCH_EVENTS]: {
    next: (state, action) => ({
      ...state,
      eventsListData: action.payload,
      eventsFetchedSuccess: true,
      eventsFetching: false,
    }),
  },
  [SEARCH_EVENTS]: {
    next: (state, action) => ({
      ...state,
      eventSearchListData: action.payload,
      shouldDisplaySearchList: true,
      eventsFetchedSuccess: true,
      eventsFetching: false,
    }),
  },
  [EVENTS_FETCHED_SUCCESS]: {
    next: (state, action) => ({
      ...state,
      eventsFetchedSuccess: action.payload,
    }),
  },
  [EVENTS_FETCHING]: {
    next: (state, action) => ({
      ...state,
      eventsFetching: action.payload,
    }),
  },
  [EVENT_FETCHED_SUCCESS]: {
    next: (state, action) => ({
      ...state,
      eventFetchedSuccess: action.payload,
    }),
  },
  [EVENT_FETCHING]: {
    next: (state, action) => ({
      ...state,
      eventFetching: action.payload,
    }),
  },
  [SHOULD_DISPLAY_SEARCH_LIST]: {
    next: (state, action) => ({
      ...state,
      shouldDisplaySearchList: action.payload,
    }),
  },
  [GET_EVENT_BY_ID]: {
    next: (state, action) => ({
      ...state,
      event: action.payload.data,
      eventFetchedSuccess: true,
      eventFetching: false,
    }),
  },
  [RESET_EVENT_DATA]: {
    next: (state) => ({
      ...state,
      event: null,
      eventFetching: false,
    }),
  },
};

export default handleActions(ACTION_HANDLERS, initialState);
