import { connect } from 'react-redux';
import { App } from 'components/App/App';
import {
  isAuthSelector,
  isUserOrganizerSelector,
  isEmailConfirmedSelector,
  userOrganizationsSelector,
  userOrganizedEventsSelector,
} from 'modules/user/selector';
import { isInitializedSelector, profileSelector } from 'modules/app/selector';
import { selectedEventIdSelector, selectedOrganizationIdSelector } from 'modules/organization/selector';
import { initializeApp } from 'modules/app/actions';
import { fetchEvents } from 'modules/events/actions';
import { eventsDataSelector, eventsFetchingSelector } from 'modules/events/selector';
import { createAlert, removeAlert, removeAllAlerts } from 'modules/alerts/actions';
import { alertsSelector } from 'modules/alerts/selector';

const mapActionCreators = (dispatch) => ({
  async initializeApp(path) {
    await dispatch(initializeApp(path));
  },
  createAlert(alert) {
    dispatch(createAlert(alert));
  },
  fetchEvents(options) {
    dispatch(fetchEvents(options));
  },
  removeAlert(id) {
    dispatch(removeAlert(id));
  },
  removeAllAlerts(id) {
    dispatch(removeAllAlerts());
  },
});

const mapStateToProps = (state) => ({
  isAuth: isAuthSelector(state),
  organizerData: {
    isUserOrganizer: isUserOrganizerSelector(state),
    organizations: userOrganizationsSelector(state),
    organizedEvents: userOrganizedEventsSelector(state),
  },
  isEventAdmin: false,
  alerts: alertsSelector(state) || [],
  isEmailConfirmed: isEmailConfirmedSelector(state),
  evensListData: eventsDataSelector(state),
  eventFetching: eventsFetchingSelector(state),
  isAppFullyInitialized: isInitializedSelector(state),
  profile: profileSelector(state),
  selectedEventId: selectedEventIdSelector(state),
  selectedOrganizationId: selectedOrganizationIdSelector(state),
});

export const AppContainer = connect(mapStateToProps, mapActionCreators)(App);
