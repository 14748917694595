import { createAction } from 'redux-actions';
import { toastMapper } from 'utils/toastValues';
import { CREATE_ALERT, REMOVE_ALERT, REMOVE_ALL_ALERTS } from './types';

export const createAlert = createAction(CREATE_ALERT, (alertType, alertMessage = '') => (dispatch, getState) => {
  return alertMessage
    ? {
        alert: {
          type: alertType || '',
          message: alertMessage,
        },
      }
    : {
        alert: toastMapper(alertType) || {
          type: 'InternalServerError',
          message: 'Internal Server Error, please try again later',
        },
      };
});

export const removeAllAlerts = createAction(REMOVE_ALL_ALERTS);

export const removeAlert = createAction(REMOVE_ALERT, (alertId) => (dispatch, getState) => alertId);
