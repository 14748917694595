import { useCallback } from 'react';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Card, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { eventPayoutOptionDataSelector, eventDataDataSelector } from 'modules/event/selector';
import { updateDynamoEventData } from 'modules/event/actions';
import { filterWithObjectFieldInObject } from 'utils/index';

// ----------------------------------------------------------------------

export default function PayoutOptionForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const UpdateUserSchema = Yup.object().shape({
    bankName: Yup.string().min(3, 'Bank Name should be at least 3 characters long').required('Bank Name is required'),
    fullName: Yup.string().min(5, 'Full Name should be at least 5 characters long').required('Full Name is required'),
    accountNumber: Yup.string()
      .min(5, 'Account Number should be at least 5 characters long')
      .required('Account Number is required'),
  });

  const payoutOptionData = useSelector(eventPayoutOptionDataSelector);

  const eventData = useSelector(eventDataDataSelector);

  const defaultValues = {
    bankName: payoutOptionData?.bankName || '',
    fullName: payoutOptionData?.fullName || '',
    accountNumber: payoutOptionData?.accountNumber || '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = useCallback(
    async (values) => {
      const response = await dispatch(updateDynamoEventData({ id: eventData?.id, payoutOption: values }));
      return { success: response?.payload?.success || false };
    },
    [history, dispatch, filterWithObjectFieldInObject, payoutOptionData, eventData]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Payout Option
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <RHFTextField name="bankName" label="Bank Name" inputProps={{ style: { textTransform: 'capitalize' } }} />
              <RHFTextField name="fullName" label="Full Name" inputProps={{ style: { textTransform: 'capitalize' } }} />
              <RHFTextField name="accountNumber" label="Account Number" />
            </Box>
            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Stack>
            </Stack>
          </Card>
        </FormProvider>
      </Grid>
    </Grid>
  );
}
