import { createSelector } from 'reselect';
import {
  eventCategoriesSelector,
  isWaiverFormSameForAllSelector,
  metricUsedIsKmSelector,
} from 'modules/events/selector';
import { formatDistanceValue } from 'utils/common/helpers/eventHelper';
import { userFullNameSelector } from 'modules/user/selector';

export const registrationSelector = createSelector(
  (state) => state.registration,
  (registration) => registration
);

export const userChoiceSelector = createSelector(registrationSelector, (registration) => registration.userChoice);

export const userChoiceCategoriesSelector = createSelector(userChoiceSelector, (useChoice) => useChoice.categories);

export const userChoiceCategoriesTotalQuantitySelector = createSelector(userChoiceCategoriesSelector, (categories) =>
  categories.reduce((total, category) => total + (category.quantity || 0), 0)
);

export const userChoiceCategoryByIdSelector = (id) =>
  createSelector(
    userChoiceCategoriesSelector,
    (categories) => categories.find((category) => category?.id === id) || null
  );

export const userChoiceCategoryQuantityByIdSelector = (id) =>
  createSelector(
    userChoiceCategoriesSelector,
    (categories) => categories.find((category) => category?.id === id)?.quantity || 0
  );

export const subTotalPriceSelector = createSelector(
  [userChoiceCategoriesSelector, eventCategoriesSelector],
  (useChoiceCategories, eventCategories) =>
    useChoiceCategories.reduce((total, category, index) => {
      return total + category.quantity * (eventCategories.find((cat) => cat.id === category.id)?.regPrice?.amount || 0);
    }, 0)
);

export const registrationDetailsSelector = createSelector(
  registrationSelector,
  (registration) => registration.registrationDetails
);

export const registrationDetailLineItemsSelector = createSelector(
  registrationSelector,
  (registration) => registration.registrationDetails?.lineItems?.items || []
);

export const lineItemsRegistrantData = createSelector(registrationDetailLineItemsSelector, (lineItems) =>
  lineItems?.reduce((acc, lineItem) => {
    return [...acc, ...lineItem?.registrantData?.items?.map((data) => data)];
  }, [])
);

export const registrationProgressSelector = createSelector(
  registrationDetailsSelector,
  (registrationDetails) => registrationDetails.progress
);

export const registrationDetailsFetchedSelector = createSelector(
  registrationSelector,
  (registration) => registration.registrationDetailsFetched
);

export const registrationCartSummarySelector = createSelector(
  registrationSelector,
  (registration) => registration.registrationCartSummary
);

export const cartSummaryLineItemsSelector = createSelector(
  registrationCartSummarySelector,
  (cartSummary) => cartSummary.lineItems
);

export const fetchingRegistrationCartSummarySelector = createSelector(
  registrationSelector,
  (registration) => registration.fetchingRegistrationCartSummary
);

export const successGetRegistrationCartSummarySelector = createSelector(
  registrationSelector,
  (registration) => registration.successGetRegistrationCartSummary
);

export const userRegisteredParticipantsDataSelector = createSelector(
  registrationSelector,
  (registration) => registration.userRegisteredParticipantsData
);

export const userRegistrationAttestations = ({ attestationTypes = [] }) =>
  createSelector([lineItemsRegistrantData, userFullNameSelector], (registrantsData, userFullName) => {
    const authUserRegistration = registrantsData?.find(
      (data) => (data?.fullName || '')?.toLowerCase() === userFullName?.toLowerCase()
    );
    const attestations =
      authUserRegistration?.agreements?.items?.filter((agreement) => attestationTypes.includes(agreement?.type)) || [];

    return attestations;
  });

export const lineItemsCategoriesWaiverSelector = createSelector(
  [cartSummaryLineItemsSelector, eventCategoriesSelector, isWaiverFormSameForAllSelector, metricUsedIsKmSelector],
  (lineItems, categories, isWaiverFormSameForAll, metricUsedIsKm) => {
    let waiversOnLineItems = lineItems;
    if (isWaiverFormSameForAll) {
      waiversOnLineItems = [categories?.find((item) => item?.waiverForm?.items?.length)]?.filter((item) => item);
    }

    const waivers = waiversOnLineItems.reduce((acc, item) => {
      const foundCategory = categories?.find((cat) => cat?.id === item?.id);
      const catWaivers = foundCategory?.waiverForm?.items || [];

      const updatedWaiver = catWaivers?.reduce(
        (concatenated, cat) => [
          ...concatenated,
          { ...cat, distance: formatDistanceValue(metricUsedIsKm, item?.distance), isRequired: true },
        ],
        []
      );
      return [...acc, ...updatedWaiver];
    }, []);

    return waivers;
  }
);
