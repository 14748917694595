import pathToRegexp, { compile } from 'path-to-regexp';
import qs from 'qs';
import { RoutesValue, Routes, OrganizationPrimaryPath, OrganizationsPrimaryPath, EventPrimaryPath } from '../constants';

export const getPath = (route, { withoutParams = false } = {}) => {
  const path = RoutesValue[route]?.path || RoutesValue[Routes.EVENTS].path;
  return withoutParams ? path.slice(0, path.indexOf('/:')) : path;
};

export const getRouteValue = (route) => RoutesValue[route];

export const getRouteTitle = (route) => RoutesValue[route].title;

export const generateCategoryPath = (route) => RoutesValue[route].title;

export const isOneOfRoutes = (generatePath, adminRouts, location, params = {}) =>
  Object.entries(adminRouts)?.some(
    ([_, value]) =>
      generatePath(value.path, {
        ...params,
      }) === location.pathname
  );

export const constructPath = (path, params, generatePath) => generatePath(getPath(path), params);

const compiledCache = {};

export const generatePathWithQueryParams = (rawPath, params) => {
  let toPath;
  if (compiledCache[rawPath]) {
    toPath = compiledCache[rawPath];
  } else {
    toPath = compile(rawPath);
    compiledCache[rawPath] = toPath;
  }

  const queryParams = { ...params };
  const path = toPath(params, {
    encode: (value, token) => {
      delete queryParams[token.name];
      return encodeURI(value);
    },
  });

  const queryString = qs.stringify(queryParams);
  if (queryString) {
    return `${path}?${queryString}`;
  }
  return path;
};

export const isOrganizationsRoute = (path) =>
  path.includes(`/${OrganizationsPrimaryPath}`) || isOrganizationRoute(path);

export const isOrganizationRoute = (path) => path.includes(`/${OrganizationPrimaryPath}/`);

export const pathIncludesEvent = (path) => path.includes(`/${EventPrimaryPath}/`);

export const getOrganizationIdByPath = (path) => {
  // eg. "/organization/b8488238-a15c-4e06-9474-468374389943/events";
  const segments = path?.split('/'); // Split the path into an array of segments
  const orgId = segments?.[2]; // The orgId is at index 2

  return orgId;
};

export const getEventIdByPath = (path) => {
  // eg. "/organization/b8488238-a15c-4e06-9474-468374389943/event/b8488238-a15c-4e06-9474-468374389944";
  const segments = path?.split('/'); // Split the path into an array of segments
  const orgId = segments?.[4]; // The orgId is at index 2

  return orgId || '';
};
