import { useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
  Paper,
} from '@mui/material';
import Iconify from 'components/Iconify';
import { constructPrizeValue } from 'utils';
import useResponsive from 'hooks/useResponsive';
import EventImagesCarousel from './EventImagesCarousel';

const columnsValue = ['Type', 'Desc', 'Prizes'];

export default function EventPrizes({ event }) {
  const { eventPrizes } = event;

  const orderOfPrizesType = useMemo(() => {
    const arrayOfPrizeTypes =
      eventPrizes?.items
        ?.filter((val, ind, arr) => arr?.findIndex((item) => item?.prizeType === val?.prizeType) === ind)
        ?.map((item) => item?.prizeType) || [];

    return arrayOfPrizeTypes?.reduce((acc, item, index, arr) => {
      const allItemsWithTheSamePrizeType = eventPrizes?.items?.filter((val) => val.prizeType === item);
      const mergeAllValuesWithSamePrizeType = allItemsWithTheSamePrizeType?.reduce((acc, item) => {
        return {
          prizeType: item.prizeType,
          prizeImages: [...(acc?.prizeImages || []), ...(item?.imageURL?.items || [])],
          prizes: [
            ...(acc?.prizes || []),
            {
              prizeValue: item.prizeValue,
              prizeCategory: item.prizeCategory,
            },
          ],
        };
      }, {});

      return [...acc, mergeAllValuesWithSamePrizeType];
    }, []);
  }, [eventPrizes]);

  const rowsValue = (prizes) => {
    return prizes
      .map((prize, index) => ({
        type: prize?.prizeCategory?.prizeCategoryType,
        value: prize?.prizeCategory?.prizeCategoryValue,
        prize: constructPrizeValue(prize?.prizeValue),
      }))
      .sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
  };
  return (
    <div>
      {orderOfPrizesType.length > 0 &&
        orderOfPrizesType.map((item, index) => {
          return (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<Iconify icon={'bx:down-arrow'} />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
              >
                <Typography sx={{ width: '33%', flexShrink: 0, color: 'red' }}>
                  <b>{item?.prizeType}</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <PrizeTable rows={rowsValue(item?.prizes || [])} columnHeaders={columnsValue} />
                {item?.prizeImages > 0 && (
                  <EventImagesCarousel
                    key={index}
                    shouldShowTabSizeImages={false}
                    imagesInfo={item?.prizeImages || []}
                    title={''}
                    description={''}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
}

const PrizeTable = ({ rows, columnHeaders }) => {
  const isDesktop = useResponsive('up', 'sm');
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        {!isDesktop && <caption>Scroll to the left to see values</caption>}
        <TableHead>
          <TableRow>
            {(columnHeaders ?? [])?.map((column, index) => (
              <TableCell key={index}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length ? (
            (rows ?? []).map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.type}
                </TableCell>
                <TableCell align="left">{row.value}</TableCell>
                <TableCell align="left">{row.prize}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Nothing to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
