import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { resetRegistration, changeRegistrationProgress } from 'modules/registration/actions';
import { DialogTitle, DialogContent, DialogActions, DialogContentText, Button, Divider, Dialog } from '@mui/material';
import { ProgressPage } from 'constants';
import { LoadingButton } from '@mui/lab';

export default function ModalResetProgress({
  open,
  onClose,
  handleSubmit = null,
  handleButtonThreeClick = null,
  dialogTitle = '',
  dialogContentText = '',
  disableButton2 = false,
  disableButton1 = false,
  disableButton3 = false,
  button1 = '',
  button2 = '',
  button3 = '',
  button3Loading = false,
  button2Loading = false,
}) {
  const dispatch = useDispatch();

  const handleGoBack = useCallback(async () => {
    dispatch(changeRegistrationProgress(ProgressPage.numberOfRegistrants));
    onClose();
    await dispatch(resetRegistration());
  }, []);

  const shouldShowButtonThree = button3 && handleButtonThreeClick;

  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>{dialogTitle || 'Warning on going back'}</DialogTitle>

      <DialogContent>
        <DialogContentText id="registration-progress-alert-dialog-description">
          {dialogContentText ||
            `If you go back to last page you'll lost your data and start all over again. Do you still want to go back?`}
        </DialogContentText>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button variant="contained" onClick={onClose} disabled={disableButton1}>
          {button1 || `Cancel`}
        </Button>
        {shouldShowButtonThree && (
          <LoadingButton
            type="submit"
            variant="outlined"
            loading={button3Loading}
            onClick={handleButtonThreeClick}
            disabled={disableButton3}
          >
            {button3}
          </LoadingButton>
        )}
        <LoadingButton
          type="submit"
          variant="outlined"
          loading={button2Loading}
          onClick={handleSubmit || handleGoBack}
          disabled={disableButton2}
        >
          {button2 || `Go back`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
