import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DialogTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Alert,
  AlertTitle,
  Stack,
  Link,
} from '@mui/material';
import publishEventHook from 'hooks/usePublishEventHook';

export default function PublishEventModal({ setOpen, open }) {
  const history = useHistory();
  const handleClose = () => {
    setOpen(false);
  };

  const lackingItems = publishEventHook();

  const handleRedirect = useCallback(
    (path) => {
      handleClose();
      history.push(path);
    },
    [history]
  );

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-publish-event-title"
        aria-describedby="alert-dialog-publish-event-description"
      >
        <DialogTitle id="alert-dialog-title">Remaining Action Items</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-publish-event-description">
            You still have remaining action items to publish this event and make it available for registration.
            <Stack spacing={1} direction="column">
              {lackingItems.map((item) => (
                <Alert severity="error" key={item.name}>
                  <AlertTitle>
                    {item.title} {' ('}
                    <Link
                      noWrap
                      variant="body2"
                      onClick={() => handleRedirect(item.link)}
                      sx={{ color: 'green', cursor: 'pointer' }}
                    >
                      Click here
                    </Link>
                    {')'}
                  </AlertTitle>
                  {item.message}
                </Alert>
              ))}
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
