import * as Yup from 'yup';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, IconButton, InputAdornment, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import Iconify from 'components/Iconify';
import { signUpUser } from 'modules/user/actions';
import TermsAndPrivacyPolicy from 'components/TermsAndPrivacyPolicy';

const emptyFunc = () => {};

export const RegisterForm = ({ hasOwnRedirect = false, shouldRedirectTo = emptyFunc(), noAutoFocus = false }) => {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [afterSubmitErrors, setAfterSubmitErrors] = useState([]);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().min(8, 'Should be greater than 8 characters!').required('Password is required'),
    phoneNumber: Yup.string().required('Phone is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      phoneNumber: '',
      country: 'Philippines',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const response = await dispatch(signUpUser(values, hasOwnRedirect));
      if (response.payload?.success !== undefined && !response.payload?.success) {
        setErrors('afterSubmit');
        setAfterSubmitErrors(response.payload?.errors);
      } else {
        shouldRedirectTo();
      }
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, isValid, values, setFieldValue, handleBlur } =
    formik;

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {errors === 'afterSubmit' && afterSubmitErrors?.length && (
            <>
              {afterSubmitErrors?.map((error) => (
                <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                  {error?.message}
                </Alert>
              ))}
            </>
          )}
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoFocus={!noAutoFocus}
                label="First name"
                {...getFieldProps('firstName')}
                inputProps={{ style: { textTransform: 'capitalize' } }}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                fullWidth
                label="Last name"
                {...getFieldProps('lastName')}
                inputProps={{ style: { textTransform: 'capitalize' } }}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>

            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <PhoneInput
              country={'ph'}
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              value={values.phoneNumber}
              component={TextField}
              onChange={(phone) => setFieldValue('phoneNumber', phone)}
              onBlur={handleBlur}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              disabled={!isValid}
            >
              Register
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>

      <TermsAndPrivacyPolicy />
    </>
  );
};

RegisterForm.propTypes = {
  hasOwnRedirect: PropTypes.bool,
  shouldRedirectTo: PropTypes.func,
};
