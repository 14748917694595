// @mui
import { Box, Skeleton, Grid } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonCart() {
  return (
    <Grid item xs={12}>
      <Skeleton variant="rectangular" width="90%" sx={{ height: 20, borderRadius: 2, m: 2 }} />
      <Skeleton variant="rectangular" width="90%" sx={{ height: 20, borderRadius: 2, m: 2 }} />
      <Skeleton variant="rectangular" width="90%" sx={{ height: 20, borderRadius: 2, m: 2 }} />
      <Skeleton variant="rectangular" width="90%" sx={{ height: 40, borderRadius: 2, m: 2 }} />
    </Grid>
  );
}
