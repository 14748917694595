// Values should have ERROR | INFO | SUCCESS | WARNING
export const UserErrorTypes = {
  signUpError: 'SIGN_UP_ERROR',
  signInError: 'SIGN_IN_ERROR',
  gettingSessionTokenError: 'GET_SESSION_TOKEN_ERROR',
  confirmSignUpError: 'REJECT_SIGN_UP_ERROR',
  resendConfirmationFail: 'RESENDING_CONFIRMATION_ERROR',
  invalidEmail: 'INVALID_EMAIL_ERROR',
  defaultError: 'DEFAULT_ERROR',
};

export const UserSuccessTypes = {
  resendConfirmationSuccess: 'RESEND_CONFIRMATION_SUCCESS',
  emailSuccessfullyVerified: 'EMAIL_VERIFY_SUCCESS',
  forgotPasswordChangeSuccess: 'FORGOT_PASSWORD_CHANGE_SUBMIT_SUCCESS',
};

export const CognitoConstantErrors = {
  UsernameExistsException: 'UsernameExistsException',
  InvalidParameterException: 'InvalidParameterException',
  UserNotFoundException: 'UserNotFoundException',
  CodeMismatchException: 'CodeMismatchException',
  LimitExceededException: 'LimitExceededException',
  UserNotConfirmedException: 'UserNotConfirmedException',
};

export const UserErrorMessages = {
  signInError: "We're having trouble signing you in. Please try again in a while",
  signUpError: "We're having trouble signing you up. Please try again in a while",
  gettingSessionTokenError: "We're having trouble getting your session. We'll redirect you to login page",
  confirmSignUpError: "We're having trouble confirming your signup. Please try again in a while",
  resendConfirmationError: "We're having trouble resending your confirmation. Please try again in a while",
  changeUserPasswordError: "We're having trouble changing your password. Please try again in a while",
  UsernameExistsExceptionError: 'An account with the given email already exists. Please try another email!',
  InvalidParameterExceptionError: 'User is already confirmed.',
  CodeMismatchExceptionError: 'Invalid verification code provided, please try again.',
  UserNotFoundExceptionError: 'Email Address Not Found in our system.',
  invalidEmailError: 'Invalid Email Address!',
  LimitExceededExceptionError: 'Attempt limit exceeded, please try after some time.',
  defaultErrorMessage: 'Internal server error, please try again later.',
};

export const UserSuccessMessages = {
  resendConfirmationSuccess: 'We successfully sent you the code, please check your email',
  emailVerificationSuccess: 'We successfully verified your email.',
  forgotPasswordChangeSuccessMessage: "We successfully Changed your password, we'll redirect you to log in now!",
};

export const userSuccessArray = [
  {
    type: UserSuccessTypes.forgotPasswordChangeSuccess,
    message: UserSuccessMessages.forgotPasswordChangeSuccessMessage,
  },
  {
    type: UserSuccessTypes.resendConfirmationSuccess,
    message: UserSuccessMessages.resendConfirmationSuccess,
  },
  {
    type: UserSuccessTypes.emailSuccessfullyVerified,
    message: UserSuccessMessages.emailVerificationSuccess,
  },
];

export const userErrorsArray = [
  {
    type: CognitoConstantErrors.LimitExceededException,
    message: UserErrorMessages.LimitExceededExceptionError,
  },
  {
    type: UserErrorTypes.signUpError,
    message: UserErrorMessages.invalidEmailError,
  },
  {
    type: UserErrorTypes.defaultError,
    message: UserErrorMessages.defaultErrorMessage,
  },
  {
    type: UserErrorTypes.invalidEmail,
    message: UserErrorMessages.signInError,
  },
  {
    type: UserErrorTypes.resendConfirmationFail,
    message: UserErrorMessages.resendConfirmationError,
  },
  {
    type: UserErrorTypes.confirmSignUpError,
    message: UserErrorMessages.confirmSignUpError,
  },
  {
    type: CognitoConstantErrors.UsernameExistsException,
    message: UserErrorMessages.UsernameExistsExceptionError,
  },
  {
    type: CognitoConstantErrors.CodeMismatchException,
    message: UserErrorMessages.CodeMismatchExceptionError,
  },
  {
    type: CognitoConstantErrors.UsernameExistsException,
    message: UserErrorMessages.UsernameExistsExceptionError,
  },
  {
    type: CognitoConstantErrors.UserNotFoundException,
    message: UserErrorMessages.UserNotFoundExceptionError,
  },
];

export const userCustomAttributes = {
  firstName: 'custom:firstName',
  birthday: 'custom:birthDay',
  lastName: 'custom:lastName',
  addressLine1: 'custom:addressLine1',
  country: 'custom:country',
  city: 'custom:city',
  cityCode: 'custom:cityCode',
  state: 'custom:state',
  stateCode: 'custom:stateCode',
  barangay: 'custom:barangay',
  barangayCode: 'custom:barangayCode',
  region: 'custom:region',
  regionCode: 'custom:regionCode',
  zipCode: 'custom:zipCode',
  isPublic: 'custom:isPublic',
};

export const userSearchType = {
  email: 'email',
  name: 'name',
};
