export const GET_USER_EVENTS = 'user/GET_USER_EVENTS';
export const GET_USER_REGISTRATION_DETAILS = 'user/GET_USER_REGISTRATION_DETAILS';
export const GET_USER_DATA = 'user/GET_USER_DATA';
export const GET_USER_SESSION = 'user/GET_USER_SESSION';
export const LOGOUT_USER = 'user/LOGOUT_USER';
export const CREATE_USER_DYNAMO = 'user/CREATE_USER_DYNAMO';
export const HYDRATE_USER = 'user/HYDRATE_USER';
export const CHECK_USER = 'user/CHECK_USER';
export const USER_ERROR = 'user/USER_ERROR';
export const LOADING_USER = 'user/LOADING_USER';
export const FORGOT_PASSWORD = 'user/FORGOT_PASSWORD';
export const REGISTER_USER = 'user/REGISTER_USER';
export const SIGN_IN_USER = 'user/SIGN_IN_USER';
export const CONFIRM_SIGNUP = 'user/CONFIRM_SIGNUP';
export const RESEND_CONFIRMATION = 'user/RESEND_CONFIRMATION';
export const FORGOT_PASSWORD_SUBMIT = 'user/FORGOT_PASSWORD_SUBMIT';
export const UPDATE_USER_DATA = 'user/UPDATE_USER_DATA';
export const CHANGE_USER_PASSWORD = 'user/CHANGE_USER_PASSWORD';
export const NO_USER_IS_LOADED = 'user/NO_USER_IS_LOADED';
export const ADD_USER_TO_GROUP = 'user/ADD_USER_TO_GROUP';
export const FETCH_USER_EVENT_JUNCTION = 'user/FETCH_USER_EVENT_JUNCTION';

// Organizations Actions
export const USER_ORGANIZED_EVENTS = 'organization/USER_ORGANIZED_EVENTS';
export const USER_ORGANIZATIONS = 'organization/USER_ORGANIZATIONS';
export const SEARCH_USER = 'organization/SEARCH_USER';
export const ADD_NEW_ORGANIZATION_TO_LIST = 'organization/ADD_NEW_ORGANIZATION_TO_LIST';
export const ADD_TO_USER_ORGANIZATION_LIST = 'organization/ADD_TO_USER_ORGANIZATION_LIST';
export const REMOVE_JUNCTION_ORGANIZATION = 'organization/REMOVE_JUNCTION_ORGANIZATION';
