import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { NavigationType, Routes } from 'constants/index';
import { useDispatch, useSelector } from 'react-redux';
import GeneralModal from 'components/GeneralModal';
import { useParams, generatePath, useHistory } from 'react-router-dom';
import { resetSelectedOrganization } from 'modules/organization/actions';
import { resetSelectedEvent } from 'modules/event/actions';
import { RedirectModal } from 'sections/general/RedirectModal';
import { userEventRoles } from 'modules/event/selector';
import { getPath } from 'utils/routes';
import { isUserOrgAdminSelector } from 'modules/organization/selector';
import { NavItemRoot } from './NavItem';
import { NavItemWithSubItem } from './NavList';

// eslint-disable-next-line react-hooks/exhaustive-deps
export const NavListItemWrapper = ({ list, isCollapse, active, hasActiveChild, setOpen, open }) => {
  const hasChildren = list.children;
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const eventRoles = useSelector(userEventRoles);
  const isOrgAmin = useSelector(isUserOrgAdminSelector);
  const history = useHistory();

  const type = list.type;

  const roles = list?.roles || [];

  const [openConfirmSwitchModal, setOpenConfirmModal] = useState(false);
  const [openExitOrganizationModal, setOpenExitOrganizationModal] = useState(false);
  const [openExitEventModal, setOpenExitEventModal] = useState(false);

  const handleBackToOrganizationsModalConfirm = useCallback(() => {
    dispatch(resetSelectedOrganization());
    setOpenExitOrganizationModal(false);
    history.push(getPath(Routes.ORGANIZATIONS_DASHBOARD));
  }, [dispatch]);

  const handleBackToOrganizationModalConfirm = useCallback(() => {
    dispatch(resetSelectedEvent());
    setOpenExitEventModal(false);
    history.push(generatePath(getPath(Routes.ORGANIZATION_DASHBOARD), { organizationId }));
  }, [dispatch, history, organizationId]);

  const handleOnClickNavItem = useCallback(() => {
    switch (type) {
      case NavigationType.SWITCH:
        setOpenConfirmModal(true);
        break;
      case NavigationType.EXIT_ORG:
        setOpenExitOrganizationModal(true);
        break;
      case NavigationType.EXIT_EVENT:
        setOpenExitEventModal(true);
        break;
      default:
        break;
    }
  }, [type]);

  const allUserEventRoles = eventRoles?.reduce((acc, role) => [...acc, ...(role?.scope ?? [])], []);
  const isUserApplicableToNavRoles = roles?.some((role) => allUserEventRoles?.includes(role));

  if (roles?.length && !isUserApplicableToNavRoles && !isOrgAmin) {
    return null;
  }

  return (
    <>
      {openConfirmSwitchModal && <RedirectModal setOpen={setOpenConfirmModal} isOpen={openConfirmSwitchModal} />}
      {openExitOrganizationModal && (
        <GeneralModal
          open={Boolean(openExitOrganizationModal)}
          id="back-to-organizations-modal-display"
          title="Back to Organization"
          message={'Confirm back to Organizations List'}
          buttonOneText="Cancel"
          buttonTwoText="Confirm"
          buttonOneClick={() => setOpenExitOrganizationModal(false)}
          buttonTwoClick={handleBackToOrganizationsModalConfirm}
        />
      )}
      {openExitEventModal && (
        <GeneralModal
          open={Boolean(openExitEventModal)}
          id="back-to-orgnization-modal-display"
          title="Back to Organization"
          message={'Confirm back to Organization Portal'}
          buttonOneText="Cancel"
          buttonTwoText="Confirm"
          buttonOneClick={() => setOpenExitEventModal(false)}
          buttonTwoClick={handleBackToOrganizationModalConfirm}
        />
      )}
      {hasChildren ? (
        <NavItemWithSubItem
          list={list}
          isCollapse={isCollapse}
          active={active || Boolean(hasActiveChild)}
          hasActiveChild={hasActiveChild}
          handleOnClickNavItem={handleOnClickNavItem}
          setOpen={setOpen}
          open={open}
        />
      ) : (
        <NavItemRoot item={list} active={active} isCollapse={isCollapse} handleOnClickNavItem={handleOnClickNavItem} />
      )}
    </>
  );
};

NavListItemWrapper.propTypes = {
  hasChildAndChildActive: PropTypes.bool,
  active: PropTypes.bool,
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  setOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    caption: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
  }),
};
