import { matchPath } from 'react-router-dom';

// ----------------------------------------------------------------------

import NavSectionHorizontal from './horizontal';
import NavSectionVertical from './vertical';

export { NavSectionHorizontal, NavSectionVertical };

export function isExternalLink(path) {
  return path.includes('http');
}

export function getActive(path, pathname) {
  // return path ? !!matchPath({ path }, pathname) : false;
  return path && pathname && path === pathname;
}
