/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrganizationDashboardData = /* GraphQL */ `
  query GetOrganizationDashboardData($ctx: CTX!, $id: ID!) {
    getOrganizationDashboardData(ctx: $ctx, id: $id)
  }
`;
export const fetchEventPortalDashboardData = /* GraphQL */ `
  query FetchEventPortalDashboardData($ctx: CTX!, $id: ID!) {
    fetchEventPortalDashboardData(ctx: $ctx, id: $id)
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      eventName
      name
      eventShortDescription
      additionalNotes
      organizationId
      organization {
        id
        creatorId
        organizationName
        status
        createdAt
        updatedAt
        __typename
      }
      eventStaffs {
        nextToken
        __typename
      }
      registrationEnd
      registrationStart
      hasSameRouteForAllCat
      routeImageURL {
        nextToken
        __typename
      }
      mainEventImage {
        nextToken
        __typename
      }
      eventInstance
      eventType
      dateOfEvent
      slotsAvailabilityIsSameForAllCategories
      canHaveMultipleRegistrantsInOneOrder
      hasSameFormForAllCategories
      metricUsedIsKm
      eventPrizes {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      registrations {
        nextToken
        __typename
      }
      contactInfo {
        type
        value
        __typename
      }
      discounts {
        nextToken
        __typename
      }
      published
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      isWaiverFormSameForAll
      isRulesAndRegulationsSameForAll
      payoutOption {
        bankName
        accountNumber
        fullName
        __typename
      }
      addressLine1
      addressLine2
      barangay
      city
      state
      region
      country
      transactionFee
      transactionFeeType
      updatedBibNumberSequence
      deliveryOptions {
        title
        description
        value
        __typename
      }
      createdAt
      subscription {
        eventsStaff
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventCategory = /* GraphQL */ `
  query GetEventCategory($id: ID!) {
    getEventCategory(id: $id) {
      id
      eventId
      distance
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      categoryType
      categoryValue
      categoryImageURL {
        nextToken
        __typename
      }
      slots {
        categoryId
        eventId
        totalSlotsAvailable
        totalRegistrants
        createdAt
        updatedAt
        __typename
      }
      raceKits {
        nextToken
        __typename
      }
      cutOffTime
      allowableQuantityPerOrder
      assemblyTime
      gunTime
      regPrice {
        categoryId
        eventId
        createdAt
        updatedAt
        __typename
      }
      categoryPrizes {
        __typename
      }
      form {
        nextToken
        __typename
      }
      registrants {
        nextToken
        __typename
      }
      routeImageURL {
        nextToken
        __typename
      }
      waiverForm {
        nextToken
        __typename
      }
      rulesAndRegulations {
        nextToken
        __typename
      }
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      raceBibPreFix
      raceResults {
        id
        belongsTo
        description
        title
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventCategories = /* GraphQL */ `
  query ListEventCategories(
    $filter: ModelEventCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRaceKit = /* GraphQL */ `
  query GetRaceKit($id: ID!) {
    getRaceKit(id: $id) {
      id
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      images {
        nextToken
        __typename
      }
      kitType
      description
      displaySequence
      optionName
      optionValues
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRaceKits = /* GraphQL */ `
  query ListRaceKits(
    $filter: ModelRaceKitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRaceKits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryId
        eventId
        kitType
        description
        displaySequence
        optionName
        optionValues
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventPrize = /* GraphQL */ `
  query GetEventPrize($id: ID!) {
    getEventPrize(id: $id) {
      id
      eventId
      prizeType
      imageURL {
        nextToken
        __typename
      }
      prizeValue {
        prizeAmountType
        prizeAmountValue
        __typename
      }
      prizeCategory {
        prizeCategoryType
        prizeCategoryValue
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventPrizes = /* GraphQL */ `
  query ListEventPrizes(
    $filter: ModelEventPrizeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventPrizes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        prizeType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategorySlotsInventory = /* GraphQL */ `
  query GetCategorySlotsInventory($categoryId: ID!) {
    getCategorySlotsInventory(categoryId: $categoryId) {
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      totalSlotsAvailable
      totalRegistrants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCategorySlotsInventories = /* GraphQL */ `
  query ListCategorySlotsInventories(
    $categoryId: ID
    $filter: ModelCategorySlotsInventoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategorySlotsInventories(
      categoryId: $categoryId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        categoryId
        eventId
        totalSlotsAvailable
        totalRegistrants
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRegistrationPrice = /* GraphQL */ `
  query GetRegistrationPrice($categoryId: ID!) {
    getRegistrationPrice(categoryId: $categoryId) {
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      prices {
        type
        amountInCent
        currency
        enabled
        priceValidFrom
        priceValidTo
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRegistrationPrices = /* GraphQL */ `
  query ListRegistrationPrices(
    $categoryId: ID
    $filter: ModelRegistrationPriceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRegistrationPrices(
      categoryId: $categoryId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        categoryId
        eventId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRegistrationDiscount = /* GraphQL */ `
  query GetRegistrationDiscount($id: ID!) {
    getRegistrationDiscount(id: $id) {
      id
      eventId
      categoryId
      discountName
      discountDesc
      discountData {
        type
        amountInCent
        currency
        priceValidFrom
        priceValidTo
        __typename
      }
      enabled
      quantity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRegistrationDiscounts = /* GraphQL */ `
  query ListRegistrationDiscounts(
    $filter: ModelRegistrationDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegistrationDiscounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        categoryId
        discountName
        discountDesc
        enabled
        quantity
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getForm = /* GraphQL */ `
  query GetForm($id: ID!) {
    getForm(id: $id) {
      id
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      formOrderNumber
      formFields {
        fieldType
        fieldId
        validationType
        label
        orderNumber
        placeholder
        type
        defaultValue
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listForms = /* GraphQL */ `
  query ListForms(
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryId
        eventId
        formOrderNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      belongsTo
      description
      title
      urlImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        belongsTo
        description
        title
        urlImage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      belongsTo
      description
      title
      fileUrls {
        url
        type
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        belongsTo
        description
        title
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMockUpData = /* GraphQL */ `
  query GetMockUpData($id: ID!) {
    getMockUpData(id: $id) {
      id
      eventId
      belongsTo
      type
      title
      description
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMockUpData = /* GraphQL */ `
  query ListMockUpData(
    $filter: ModelMockUpDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMockUpData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        belongsTo
        type
        title
        description
        body
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userSub
      firstName
      lastName
      fullName
      email
      password
      isEmailConfirmed
      birthDate
      organizedEvents {
        nextToken
        __typename
      }
      organization {
        nextToken
        __typename
      }
      registrations {
        nextToken
        __typename
      }
      phoneNumber
      about
      isPublic
      profilePhoto {
        id
        belongsTo
        description
        title
        urlImage
        createdAt
        updatedAt
        __typename
      }
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      primaryAddress {
        type
        addressLine1
        country
        region
        regionCode
        state
        stateCode
        city
        cityCode
        barangay
        barangayCode
        zipCode
        __typename
      }
      billingShippingAddress {
        type
        addressLine1
        country
        region
        regionCode
        state
        stateCode
        city
        cityCode
        barangay
        barangayCode
        zipCode
        __typename
      }
      addresses {
        type
        addressLine1
        country
        region
        regionCode
        state
        stateCode
        city
        cityCode
        barangay
        barangayCode
        zipCode
        __typename
      }
      agreements {
        nextToken
        __typename
      }
      registrantsData {
        nextToken
        __typename
      }
      subscription {
        organizations
        events
        organizationStaff
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserOrganizationEventJunction = /* GraphQL */ `
  query GetUserOrganizationEventJunction($id: ID!) {
    getUserOrganizationEventJunction(id: $id) {
      id
      userId
      userEmail
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      eventId
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      organizationId
      status
      roles {
        role
        scope
        descriptions
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserOrganizationEventJunctions = /* GraphQL */ `
  query ListUserOrganizationEventJunctions(
    $filter: ModelUserOrganizationEventJunctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserOrganizationEventJunctions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userEmail
        eventId
        organizationId
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      creatorId
      organizationEmails {
        nextToken
        __typename
      }
      organizationProfilePhotoURLs {
        nextToken
        __typename
      }
      organizationName
      subscription {
        organizationStaff
        events
        __typename
      }
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      agreements {
        nextToken
        __typename
      }
      staffs {
        nextToken
        __typename
      }
      events {
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creatorId
        organizationName
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserOrganizationWithRoles = /* GraphQL */ `
  query GetUserOrganizationWithRoles($id: ID!) {
    getUserOrganizationWithRoles(id: $id) {
      id
      type
      userId
      userEmail
      userFirstName
      userLastName
      fullName
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      organizationId
      organization {
        id
        creatorId
        organizationName
        status
        createdAt
        updatedAt
        __typename
      }
      status
      roles {
        role
        scope
        descriptions
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserOrganizationWithRoles = /* GraphQL */ `
  query ListUserOrganizationWithRoles(
    $filter: ModelUserOrganizationWithRolesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserOrganizationWithRoles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        userId
        userEmail
        userFirstName
        userLastName
        fullName
        organizationId
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmailSchema = /* GraphQL */ `
  query GetEmailSchema($id: ID!) {
    getEmailSchema(id: $id) {
      id
      belongsTo
      type
      email
      verified
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmailSchemas = /* GraphQL */ `
  query ListEmailSchemas(
    $filter: ModelEmailSchemaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmailSchemas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        belongsTo
        type
        email
        verified
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventRegistrationOrder = /* GraphQL */ `
  query GetEventRegistrationOrder($id: ID!) {
    getEventRegistrationOrder(id: $id) {
      id
      userEmail
      eventId
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      lineItems {
        nextToken
        __typename
      }
      registrationState
      paymentStatus
      progress
      deliveryOption
      paymentInformation {
        orderId
        description
        paidAt
        createdAt
        updatedAt
        __typename
      }
      appliedDiscounts {
        discountId
        __typename
      }
      paymentRetries {
        sourceHookId
        timeStamp
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventRegistrationOrders = /* GraphQL */ `
  query ListEventRegistrationOrders(
    $filter: ModelEventRegistrationOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventRegistrationOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userEmail
        eventId
        userId
        registrationState
        paymentStatus
        progress
        deliveryOption
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEventRegistrationLineItem = /* GraphQL */ `
  query GetEventRegistrationLineItem($id: ID!) {
    getEventRegistrationLineItem(id: $id) {
      id
      orderId
      eventId
      categoryId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      paymentPortion {
        type
        amountInCent
        currency
        __typename
      }
      registrationOrder {
        id
        userEmail
        eventId
        userId
        registrationState
        paymentStatus
        progress
        deliveryOption
        createdAt
        updatedAt
        __typename
      }
      teamName
      bibNumber
      registrantData {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEventRegistrationLineItems = /* GraphQL */ `
  query ListEventRegistrationLineItems(
    $filter: ModelEventRegistrationLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventRegistrationLineItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        eventId
        categoryId
        teamName
        bibNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRegistrantData = /* GraphQL */ `
  query GetRegistrantData($id: ID!) {
    getRegistrantData(id: $id) {
      id
      lineItemId
      orderId
      eventId
      formOrderNumber
      userId
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      categoryId
      lineItem {
        id
        orderId
        eventId
        categoryId
        teamName
        bibNumber
        createdAt
        updatedAt
        __typename
      }
      agreements {
        nextToken
        __typename
      }
      firstName
      lastName
      fullName
      age
      bibNumber
      userEmail
      gender
      userData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRegistrantData = /* GraphQL */ `
  query ListRegistrantData(
    $filter: ModelRegistrantDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegistrantData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lineItemId
        orderId
        eventId
        formOrderNumber
        userId
        categoryId
        firstName
        lastName
        fullName
        age
        bibNumber
        userEmail
        gender
        userData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAgreement = /* GraphQL */ `
  query GetAgreement($id: ID!) {
    getAgreement(id: $id) {
      id
      belongsTo
      type
      agreement
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAgreements = /* GraphQL */ `
  query ListAgreements(
    $filter: ModelAgreementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgreements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        belongsTo
        type
        agreement
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRegistrationOrderPayment = /* GraphQL */ `
  query GetRegistrationOrderPayment($orderId: ID!) {
    getRegistrationOrderPayment(orderId: $orderId) {
      orderId
      source {
        id
        type
        __typename
      }
      billing {
        email
        name
        phone
        __typename
      }
      description
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      paidAt
      totalPaidAmount {
        type
        amountInCent
        currency
        __typename
      }
      breakDown {
        id
        distance
        quantity
        __typename
      }
      netAmount {
        type
        amountInCent
        currency
        __typename
      }
      taxes {
        type
        amountInCent
        currency
        __typename
      }
      fees {
        type
        amountInCent
        currency
        __typename
      }
      donations {
        type
        amountInCent
        currency
        __typename
      }
      discounts {
        type
        amountInCent
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRegistrationOrderPayments = /* GraphQL */ `
  query ListRegistrationOrderPayments(
    $orderId: ID
    $filter: ModelRegistrationOrderPaymentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRegistrationOrderPayments(
      orderId: $orderId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        orderId
        description
        paidAt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApp = /* GraphQL */ `
  query GetApp($id: ID!) {
    getApp(id: $id) {
      featureToggles
      constants
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApps = /* GraphQL */ `
  query ListApps(
    $filter: ModelAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        featureToggles
        constants
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsByOrganizationId = /* GraphQL */ `
  query EventsByOrganizationId(
    $organizationId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByOrganizationId(
      organizationId: $organizationId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const categoriesByEvent = /* GraphQL */ `
  query CategoriesByEvent(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoriesByEvent(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const distancesByEvent = /* GraphQL */ `
  query DistancesByEvent(
    $distance: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    distancesByEvent(
      distance: $distance
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const raceKitsByCategory = /* GraphQL */ `
  query RaceKitsByCategory(
    $categoryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRaceKitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    raceKitsByCategory(
      categoryId: $categoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        eventId
        kitType
        description
        displaySequence
        optionName
        optionValues
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const raceKitByEvent = /* GraphQL */ `
  query RaceKitByEvent(
    $eventId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRaceKitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    raceKitByEvent(
      eventId: $eventId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        eventId
        kitType
        description
        displaySequence
        optionName
        optionValues
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventPrizesByCategoryId = /* GraphQL */ `
  query EventPrizesByCategoryId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventPrizeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventPrizesByCategoryId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        prizeType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const inventoriesByEventId = /* GraphQL */ `
  query InventoriesByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCategorySlotsInventoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inventoriesByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        categoryId
        eventId
        totalSlotsAvailable
        totalRegistrants
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const registrationPricesByEventId = /* GraphQL */ `
  query RegistrationPricesByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationPriceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationPricesByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        categoryId
        eventId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const registrationDiscountsByEventId = /* GraphQL */ `
  query RegistrationDiscountsByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrationDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationDiscountsByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        categoryId
        discountName
        discountDesc
        enabled
        quantity
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const formByCategory = /* GraphQL */ `
  query FormByCategory(
    $categoryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formByCategory(
      categoryId: $categoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        eventId
        formOrderNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const formByEvent = /* GraphQL */ `
  query FormByEvent(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    formByEvent(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        eventId
        formOrderNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const mockUpDataByEventId = /* GraphQL */ `
  query MockUpDataByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMockUpDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mockUpDataByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventId
        belongsTo
        type
        title
        description
        body
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const organizationEventJunctionByUser = /* GraphQL */ `
  query OrganizationEventJunctionByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrganizationEventJunctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationEventJunctionByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userEmail
        eventId
        organizationId
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userOrganizationEventJunctionByEventId = /* GraphQL */ `
  query UserOrganizationEventJunctionByEventId(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrganizationEventJunctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userOrganizationEventJunctionByEventId(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userEmail
        eventId
        organizationId
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userOrganizationEventJunctionByOrganizationId = /* GraphQL */ `
  query UserOrganizationEventJunctionByOrganizationId(
    $organizationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrganizationEventJunctionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userOrganizationEventJunctionByOrganizationId(
      organizationId: $organizationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        userEmail
        eventId
        organizationId
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const emailByOwnerId = /* GraphQL */ `
  query EmailByOwnerId(
    $belongsTo: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmailSchemaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    emailByOwnerId(
      belongsTo: $belongsTo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        belongsTo
        type
        email
        verified
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const orderByEvent = /* GraphQL */ `
  query OrderByEvent(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventRegistrationOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderByEvent(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userEmail
        eventId
        userId
        registrationState
        paymentStatus
        progress
        deliveryOption
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const registrationByUserId = /* GraphQL */ `
  query RegistrationByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventRegistrationOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userEmail
        eventId
        userId
        registrationState
        paymentStatus
        progress
        deliveryOption
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lineItemByOrder = /* GraphQL */ `
  query LineItemByOrder(
    $orderId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventRegistrationLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lineItemByOrder(
      orderId: $orderId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        eventId
        categoryId
        teamName
        bibNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lineItemByEvent = /* GraphQL */ `
  query LineItemByEvent(
    $eventId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventRegistrationLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lineItemByEvent(
      eventId: $eventId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        eventId
        categoryId
        teamName
        bibNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const lineItemByCategory = /* GraphQL */ `
  query LineItemByCategory(
    $categoryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventRegistrationLineItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lineItemByCategory(
      categoryId: $categoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        eventId
        categoryId
        teamName
        bibNumber
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const registrantByLineItem = /* GraphQL */ `
  query RegistrantByLineItem(
    $lineItemId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrantDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrantByLineItem(
      lineItemId: $lineItemId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lineItemId
        orderId
        eventId
        formOrderNumber
        userId
        categoryId
        firstName
        lastName
        fullName
        age
        bibNumber
        userEmail
        gender
        userData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const registrantDataByOrder = /* GraphQL */ `
  query RegistrantDataByOrder(
    $orderId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrantDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrantDataByOrder(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lineItemId
        orderId
        eventId
        formOrderNumber
        userId
        categoryId
        firstName
        lastName
        fullName
        age
        bibNumber
        userEmail
        gender
        userData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const registrantsByEvent = /* GraphQL */ `
  query RegistrantsByEvent(
    $eventId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrantDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrantsByEvent(
      eventId: $eventId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lineItemId
        orderId
        eventId
        formOrderNumber
        userId
        categoryId
        firstName
        lastName
        fullName
        age
        bibNumber
        userEmail
        gender
        userData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const registrantsByUser = /* GraphQL */ `
  query RegistrantsByUser(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrantDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrantsByUser(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lineItemId
        orderId
        eventId
        formOrderNumber
        userId
        categoryId
        firstName
        lastName
        fullName
        age
        bibNumber
        userEmail
        gender
        userData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const registrantDataByCategory = /* GraphQL */ `
  query RegistrantDataByCategory(
    $categoryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRegistrantDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrantDataByCategory(
      categoryId: $categoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lineItemId
        orderId
        eventId
        formOrderNumber
        userId
        categoryId
        firstName
        lastName
        fullName
        age
        bibNumber
        userEmail
        gender
        userData
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
