import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField, InputAdornment, IconButton, Link, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from 'components/Iconify';
import 'react-phone-input-2/lib/style.css';
import { forgotUserPassword, forgotUserPasswordSubmit } from 'modules/user/actions';

const emptyFunc = () => {};

export const ForgotPasswordForm = ({
  userEmail,
  shouldDisable = false,
  hasOwnRedirect = false,
  shouldRedirectTo = emptyFunc(),
}) => {
  const dispatch = useDispatch();

  const [delayValueString, setDelayValueString] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [hasCode, setHasCode] = useState(false);
  const [emailAdd, setEmailAdd] = useState(userEmail || '');
  const [numberOfTimesInSendingCode, setNumberOfTimesInSendingCode] = useState(0);
  const [resendIsClicked, setResendIsClicked] = useState(false);

  /* ------------------- FORM FOR SEND EMAIL ------------------- */

  const SendEmailSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: userEmail || '',
    },
    validationSchema: SendEmailSchema,
    onSubmit: async (values, setSubmitting) => {
      setResendIsClicked(true);
      // eslint-disable-next-line
      setNumberOfTimesInSendingCode((curr) => (curr += 1));
      await dispatch(forgotUserPassword(values.email));
      setHasCode(true);
      setSubmitting(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, isValid, values, setFieldValue } = formik;

  useEffect(() => {
    if (userEmail) {
      setFieldValue('email', userEmail);
      setEmailAdd(userEmail);
    }
  }, [userEmail]);

  useEffect(() => {
    if (values.email) {
      setEmailAdd(values.email);
    }
  }, [values]);

  useEffect(() => {
    if (resendIsClicked && numberOfTimesInSendingCode > 0) {
      setResendIsClicked(false);
      startTimer(numberOfTimesInSendingCode * 10);
    }
  }, [numberOfTimesInSendingCode, resendIsClicked]);

  const startTimer = (duration) => {
    let timer = duration;
    let minutes;
    let seconds;
    const resendTimer = setInterval(() => {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? '0'.concat(minutes) : minutes;
      seconds = seconds < 10 ? '0'.concat(seconds) : seconds;

      setDelayValueString(` ${minutes}:${seconds}`);

      // eslint-disable-next-line
      if (--timer < 0) {
        setDelayValueString('');
        clearInterval(resendTimer);
      }
    }, 1000);
  };

  const ForgotPasswordSendEmail = () => (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            disabled={shouldDisable}
            {...getFieldProps('email')}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link
            component={Button}
            variant="subtitle2"
            to=""
            underline="hover"
            onClick={() => setHasCode((prev) => !prev)}
          >
            Already have a Code
          </Link>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!isValid || isSubmitting || Boolean(delayValueString)}
        >
          {`Send me an email ${delayValueString}`}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );

  /* ------------------- END OF FORM FOR SEND EMAIL ------------------- */

  /* ------------------- FORM FOR RESET PASSWORD WITH CODE ------------------- */

  const SubmitNewPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    code: Yup.string().min(2, 'Too Short!').max(10, 'Too Long!').required('Code is required'),
    newPassword: Yup.string().min(8, 'Should be greater than 8 characters!').required('Password is required'),
  });

  const formikForSubmitNewPassword = useFormik({
    initialValues: {
      email: emailAdd,
      newPassword: '',
      code: '',
    },
    validationSchema: SubmitNewPasswordSchema,
    onSubmit: async (values, setSubmitting) => {
      const { email, code, newPassword } = values;
      // eslint-disable-next-line
      console.log(values);
      await dispatch(forgotUserPasswordSubmit(email, code, newPassword, hasOwnRedirect));
      shouldRedirectTo();
      setSubmitting(false);
    },
  });

  const {
    errors: errorsOnSubmitNewPass,
    touched: touchedOnSubmitNewPass,
    handleSubmit: handleSubmitOnSubmitNewPass,
    isSubmitting: isSubmittingOnSubmitNewPass,
    getFieldProps: getFieldPropsOnSubmitNewPass,
    values: valuesOnSubmitNewPass,
    setFieldValue: setFieldValueOnSubmitNewPass,
    isValid: isValidOnSubmitNew,
  } = formikForSubmitNewPassword;

  useEffect(() => {
    setFieldValueOnSubmitNewPass('email', emailAdd, true);
  }, [emailAdd]);

  const ForgotPasswordSubmitForm = () => (
    <FormikProvider value={formikForSubmitNewPassword}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmitOnSubmitNewPass}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            disabled={shouldDisable}
            value={emailAdd || valuesOnSubmitNewPass.email}
            {...getFieldPropsOnSubmitNewPass('email')}
            error={touchedOnSubmitNewPass.email && Boolean(errorsOnSubmitNewPass.email)}
            helperText={touchedOnSubmitNewPass.email && errorsOnSubmitNewPass.email}
          />

          <TextField
            fullWidth
            autoComplete="code"
            type="number"
            label="Code"
            {...getFieldPropsOnSubmitNewPass('code')}
            autoFocus={!errorsOnSubmitNewPass.email}
            error={touchedOnSubmitNewPass.code && Boolean(errorsOnSubmitNewPass.code)}
            helperText={touchedOnSubmitNewPass.code && errorsOnSubmitNewPass.code}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            {...getFieldPropsOnSubmitNewPass('newPassword')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touchedOnSubmitNewPass.newPassword && errorsOnSubmitNewPass.newPassword)}
            helperText={touchedOnSubmitNewPass.newPassword && errorsOnSubmitNewPass.newPassword}
          />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link
            component={Button}
            variant="subtitle2"
            to=""
            underline="hover"
            onClick={() => setHasCode((prev) => !prev)}
          >
            Wan't a New Code ?
          </Link>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmittingOnSubmitNewPass}
          disabled={!isValidOnSubmitNew}
        >
          Reset Password
        </LoadingButton>
      </Form>
    </FormikProvider>
  );

  /* ------------------- END OF FORM FOR RESET PASSWORD WITH CODE ------------------- */

  return <>{!hasCode ? ForgotPasswordSendEmail() : ForgotPasswordSubmitForm()} </>;
};

ForgotPasswordForm.propTypes = {
  userEmail: PropTypes.string,
  shouldDisable: PropTypes.bool,
};
