import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment-timezone';
import { TimeReturnType } from 'constants';

// ----------------------------------------------------------------------

export function fDate(date = '') {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date = '') {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date = '') {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date = '') {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date = '') {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fTimeOnly(date = '') {
  return format(new Date(date), 'HH:mm');
}

export function getTimeDifference(timeA, timeB, TimeReturnTypeVal) {
  const diffTime = moment(timeA).diff(moment(timeB), 'seconds');

  const diffInSec = moment.utc(moment.duration(diffTime, 'seconds').asMilliseconds());

  if (TimeReturnTypeVal === TimeReturnType.MinuteSecAndInWord) {
    return diffInSec.format('HH [hrs] mm [mins]');
  }

  return diffInSec.format('hh:mm:ss');
}

export const validateTimeFormat = (timeString = '') => {
  // Regular expression to match "00:00:00" or "0:00:00" format with hours greater than 24
  const regex = /^(\d+):[0-5]\d:[0-5]\d$/;
  return regex.test(timeString);
};

export const removeUnwantedCharactersForTime = (timeString) => {
  // Regular expression to match unwanted characters
  const regex = /['am|pm|AM|PM]/g;
  return timeString.replace(regex, '');
};

// eslint-disable-next-line
export const invalidSplitItems = (items = []) => {
  const invalidItems = [];

  // eslint-disable-next-line
  items.forEach((item) => {
    // Extract the numeric part (e.g., "1" from "1km Split")
    const numericPart = item.match(/^\d+/)?.[0];
    if (!numericPart || parseInt(numericPart, 10) > 1000) {
      invalidItems.push(item);
      return;
    }

    // Check if "km" or "mile" is present (case-insensitive)
    const hasKmOrMile = /km|mile/i.test(item);
    if (!hasKmOrMile) {
      invalidItems.push(item);
      return;
    }

    // Check if "Split" (exact word) is present
    const hasExactSplit = /\bSplit\b/i.test(item);
    if (!hasExactSplit) {
      invalidItems.push(item);
    }
  });

  return invalidItems;
};

export const paceCalculator = ({
  previousTime = '00:00:00',
  currentTime = '00:00:00',
  previousDistance = 0,
  currentDistance = 1,
  distanceType = 'km',
  returnPaceOnly = false,
}) => {
  // Convert time from "hh:mm:ss" to seconds
  function timeToSeconds(time) {
    const parts = time.split(':').map(Number);
    return parts[0] * 3600 + parts[1] * 60 + parts[2];
  }

  // Convert seconds to "mm:ss" format with rounding
  function secondsToMinutes(seconds) {
    const roundedSeconds = Math.round(seconds);
    const minutes = Math.floor(roundedSeconds / 60);
    const remainingSeconds = roundedSeconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  const previousTimeInSeconds = timeToSeconds(previousTime);
  const currentTimeInSeconds = timeToSeconds(currentTime);
  const elapsedTimeInSeconds = currentTimeInSeconds - previousTimeInSeconds;

  // Calculate the distance covered
  const distanceCovered = currentDistance - previousDistance;

  // Calculate pace in seconds per km/mile
  const paceInSeconds = elapsedTimeInSeconds / distanceCovered;

  // Convert pace to "mm:ss" format
  const paceInMinutes = secondsToMinutes(paceInSeconds);

  if (returnPaceOnly) {
    return `${paceInMinutes}`;
  }

  return `${paceInMinutes}/${distanceType}`;
};

export const getDistanceUnit = (str = '') => {
  // Match the pattern where a number is followed by letters
  const match = str?.match(/\d+([a-zA-Z]+)/);
  if (match) {
    let unit = match?.[1];
    // Remove the trailing 's' if it exists
    if (unit && unit?.endsWith('s')) {
      unit = unit?.slice(0, -1);
    }

    return unit || 'km';
  }
  return 'km';
};

export const getFastestPace = (splits) => {
  // Convert pace from "mm:ss/km" to seconds per km
  const paceToSeconds = (pace) => {
    const [minutes, seconds] = pace?.split('/')[0].split(':').map(Number);
    return minutes * 60 + seconds;
  };

  // Use reduce to find the split with the fastest pace
  return splits?.reduce((fastestSplit, currentSplit) => {
    const fastestPaceInSeconds = paceToSeconds(fastestSplit?.pace);
    const currentPaceInSeconds = paceToSeconds(currentSplit?.pace);

    return currentPaceInSeconds < fastestPaceInSeconds ? currentSplit : fastestSplit;
  }, splits?.[0]);
};

export const calculatePacePercentages = (splits, fastestPace) => {
  // Convert pace to seconds per km for comparison
  function paceToSeconds(pace) {
    const [minutes, seconds] = pace?.split(':').map(Number);
    return minutes * 60 + seconds;
  }

  const fastestPaceSeconds = paceToSeconds(fastestPace?.pace?.split('/')?.[0]);

  return splits.map((split) => {
    const splitPaceSeconds = paceToSeconds(split?.pace.split('/')?.[0]);
    const percentage = (fastestPaceSeconds / splitPaceSeconds) * 100;
    return {
      ...split,
      percentage: `${percentage.toFixed(2)}`,
    };
  });
};

export const extractNumber = (distance) => {
  return parseFloat(distance);
};

// example: ["1km Split", "2km Split", "3km Split"] is valid but["1km Split", "3km Split", "2km Split"] is invalid
export const isSplitsInAscendingOrder = (arr = []) => {
  return arr.every((value, index, array) => index === 0 || extractNumber(array?.[index - 1]) <= extractNumber(value));
};

export const areAllDistancesInSameUnit = (arr, distanceUnit) => {
  return arr.every((str) => str.includes(distanceUnit));
};

export const sortSplits = (splits) => {
  return splits.sort((a, b) => extractNumber(a) - extractNumber(b));
};

export const parseNumberDistance = (dist) => Number.parseFloat(dist?.split(' ')?.[0].match(/[\d.]+/)?.[0] || 0) || 0;
