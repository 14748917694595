import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isAuthSelector, isEmailConfirmedSelector } from 'modules/user/selector';
import { SignupAuthForm } from 'sections/@dashboard/event-signup';
import { emptyFunction } from 'utils';

export default function useAuthModalHook({
  handleBackButton,
  openProp = false,
  shouldAutoRun = true,
  signInRedirect = emptyFunction,
}) {
  const [open, setOpen] = useState(openProp);

  const isEmailVerified = useSelector(isEmailConfirmedSelector);
  const authenticated = useSelector(isAuthSelector);

  useEffect(() => {
    if (shouldAutoRun) {
      if (!authenticated || (authenticated && !isEmailVerified)) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }
  }, [authenticated, isEmailVerified]);

  return (
    <>
      <SignupAuthForm
        open={openProp || open}
        handleBackButton={handleBackButton}
        authenticated={authenticated}
        isEmailVerified={isEmailVerified}
        signInRedirect={signInRedirect}
      />
    </>
  );
}
