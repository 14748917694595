import { useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
import MenuPopover from 'components/MenuPopover';
import { getPath, getRouteTitle } from 'utils/routes';
import { ProfileView, Routes, S3ImageSizes, StaticImages } from 'constants/index';

import { logoutUser } from 'modules/user/actions';
import { profileSelector } from 'modules/app/selector';
import { useRenderImageHook } from 'hooks/useS3hook';
import {
  userFullNameSelector,
  userEmailSelector,
  isUserOrganizerSelector,
  userHasOrganizationSelector,
  isAuthAndConfirmedSelector,
  userProfileImageObjSelector,
} from 'modules/user/selector';
import { RedirectModal } from 'sections/general/RedirectModal';

// ----------------------------------------------------------------------

const organizerItemType = {
  SWITCH_BUTTON: 'SWITCH_BUTTON',
};

const MENU_OPTIONS = (userHasEventsOrHasAlreadySetUpAsOrganization, isInOrganizationProfile) => [
  {
    label: getRouteTitle(Routes.MY_RACES).split(' | ')[0],
    icon: 'eva:home-fill',
    linkTo: getPath(Routes.MY_RACES),
  },
  {
    label: getRouteTitle(Routes.PROFILE).split(' | ')[0],
    icon: 'eva:person-fill',
    linkTo: getPath(Routes.PROFILE),
  },
  {
    label: !isInOrganizationProfile
      ? userHasEventsOrHasAlreadySetUpAsOrganization
        ? 'Switch to Organizer'
        : 'Become an Organizer'
      : 'Switch to User',
    icon: 'eva:person-fill',
    type: organizerItemType.SWITCH_BUTTON,
    linkTo: getPath(Routes.ORGANIZER),
    organizerItem: true,
  },
  // {
  //   label: getRouteTitle(Routes.SETTINGS).split(' | ')[0],
  //   icon: 'eva:settings-2-fill',
  //   linkTo: getPath(Routes.SETTINGS),
  // },
];

export const AccountPopover = () => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const isAdminChooseUserRoute = matchPath(getPath(Routes.ADMIN_CHOOSE_USER), pathname);

  const email = useSelector(userEmailSelector);
  const fullName = useSelector(userFullNameSelector);
  const isUserOrganizer = useSelector(isUserOrganizerSelector);
  const isAuthAndConfirmed = useSelector(isAuthAndConfirmedSelector);
  const userHasOrganization = useSelector(userHasOrganizationSelector);
  const profile = useSelector(profileSelector);
  const userProfileImageObj = useSelector(userProfileImageObjSelector);
  const userHasEventsOrHasAlreadySetUpAsOrganization = isUserOrganizer || userHasOrganization;
  const isInOrganizationProfile = profile === ProfileView.organization;
  const anchorRef = useRef(null);

  const [open, setOpen] = useState(null);
  const [openConfirmSwitchModal, setOpenConfirmModal] = useState(false);

  const profileImageURL = useRenderImageHook({ imageUrl: userProfileImageObj?.urlImage, size: S3ImageSizes._300x300 });

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setOpen(null);
  }, []);

  const handleOrganizerItemClick = useCallback(
    (type) => () => {
      setOpen(null);
      if (type === organizerItemType.SWITCH_BUTTON) {
        setOpenConfirmModal(true);
      }
    },
    [userHasEventsOrHasAlreadySetUpAsOrganization]
  );

  const handleLogout = useCallback(() => {
    setOpen(null);
    dispatch(logoutUser(Boolean(isAdminChooseUserRoute)));
  }, [isAdminChooseUserRoute]);

  return (
    <>
      {openConfirmSwitchModal && <RedirectModal setOpen={setOpenConfirmModal} isOpen={openConfirmSwitchModal} />}
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={profileImageURL || StaticImages.profileAvatar2} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {fullName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {!isAdminChooseUserRoute && (
          <Stack sx={{ p: 1 }}>
            {MENU_OPTIONS(userHasEventsOrHasAlreadySetUpAsOrganization, isInOrganizationProfile).map((option) => {
              const shouldDisplayOrganizationOption =
                isAuthAndConfirmed && option?.organizerItem && userHasEventsOrHasAlreadySetUpAsOrganization;

              return (
                <div key={option.label}>
                  {(shouldDisplayOrganizationOption || !option?.organizerItem) && (
                    <MenuItem
                      {...(option?.organizerItem ? {} : { to: option.linkTo, component: RouterLink })}
                      onClick={option?.organizerItem ? handleOrganizerItemClick(option.type) : handleClose}
                      disabled={profile === ProfileView.organization && !option?.organizerItem}
                    >
                      {option.label}
                    </MenuItem>
                  )}
                </div>
              );
            })}
          </Stack>
        )}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
};

AccountPopover.propTypes = {};
