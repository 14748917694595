import { createSelector } from 'reselect';
import { formatCategoryTypeAndValue, formatDistanceValue } from 'utils';

export const eventsSelector = createSelector(
  (state) => state.events,
  (events) => events
);

export const eventsDataSelector = createSelector(eventsSelector, (events) => events.eventsListData);

export const eventDataSelector = createSelector(eventsSelector, (events) => events.event);

export const metricUsedIsKmSelector = createSelector(eventDataSelector, (event) => event?.metricUsedIsKm);

export const eventOrganizationProfileSelector = createSelector(
  eventDataSelector,
  (event) => event?.organization?.organizationProfilePhotoURLs?.items?.[0] || {}
);

export const eventCategoriesSelector = createSelector(eventDataSelector, (event) => event?.categories?.items || []);

export const eventCategoriesResultsFilesSelector = createSelector(eventCategoriesSelector, (categories) =>
  categories?.reduce(
    (acc, category) => [
      ...acc,
      ...(category?.raceResults?.id
        ? [
            {
              categoryId: category?.raceResults?.belongsTo,
              fileUrls: category?.raceResults?.fileUrls,
            },
          ]
        : []),
    ],
    []
  )
);

export const eventSlotsAvailabilityIsSameForAllCategoriesSelector = createSelector(
  eventDataSelector,
  (event) => event?.slotsAvailabilityIsSameForAllCategories || false
);

export const eventFetchedSuccessSelector = createSelector(eventsSelector, (events) => events.eventFetchedSuccess);

export const getEventCategorySelector = (id) =>
  createSelector(eventCategoriesSelector, (categories) => categories.find((category) => category?.id === id) || null);

export const eventsFetchingSelector = createSelector(eventsSelector, (events) => events.eventsFetching);

export const eventFetchingSelector = createSelector(eventsSelector, (events) => events.eventFetching);

export const shouldDisplaySearchListSelector = createSelector(
  eventsSelector,
  (events) => events.shouldDisplaySearchList
);

export const eventSearchListDataSelector = createSelector(eventsSelector, (events) => events.eventSearchListData);

export const isWaiverFormSameForAllSelector = createSelector(
  eventDataSelector,
  (event) => event?.isWaiverFormSameForAll
);

export const eventImagesSelector = createSelector(eventCategoriesSelector, (categories) =>
  categories
    ?.reduce((acc, category) => {
      const items = category?.raceKits?.items?.length
        ? category?.raceKits?.items?.map((item) => item?.images?.items)?.filter((item) => item)
        : [];
      return [...acc, ...items];
    }, [])
    // Reduce into one array
    ?.reduce((acc, item) => [...acc, ...item], [])
);

export const contactInfoSelector = createSelector(eventDataSelector, (eventData) => eventData?.contactInfo || []);

// NOTE: Should be the same implementation and return value with "distancesSelector" in src/modules/event/selector.js
export const eventDistancesSelector = createSelector(
  [eventCategoriesSelector, metricUsedIsKmSelector],
  (categories, metricUsedIsKm) => {
    const arrayOfDistance =
      categories?.map((item) => ({ distance: item?.distance, id: item?.id, category: item })) || [];

    const distances = arrayOfDistance
      ?.sort((a, b) => a?.distance - b?.distance)
      ?.map((dist) => ({
        distance: `${formatDistanceValue(metricUsedIsKm, dist?.distance)}`,
        id: dist?.id,
        categoryTypeAndValue: formatCategoryTypeAndValue(metricUsedIsKm, dist?.category),
        category: dist?.category,
      }));

    return distances;
  }
);
