import PropTypes from 'prop-types';
import { Dialog, Button, Divider, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

ModalSaveChanges.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default function ModalSaveChanges({ open, onClose, onConfirm }) {
  return (
    <Dialog fullWidth maxWidth="sm" open={open}>
      <DialogTitle>Changes detected</DialogTitle>

      <DialogContent>
        <DialogContentText id="registration-changes-alert-dialog-description">
          You have changes from this form would you like to save it before going to the next form ?
        </DialogContentText>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton type="submit" variant="outlined" loading={false} onClick={onConfirm}>
          Next without saving
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
