export const FETCH_EVENTS = 'events/FETCH_EVENTS';
export const EVENTS_FETCHED_SUCCESS = 'events/EVENTS_FETCHED_SUCCESS';
export const EVENTS_FETCHING = 'events/EVENTS_FETCHING';
export const EVENT_FETCHED_SUCCESS = 'event/EVENT_FETCHED_SUCCESS';
export const EVENT_FETCHING = 'event/EVENT_FETCHING';
export const SHOULD_DISPLAY_SEARCH_LIST = 'events/SHOULD_DISPLAY_SEARCH_LIST';
export const SEARCH_EVENTS = 'events/SEARCH_EVENTS';
export const CREATE_EVENT = 'events/CREATE_EVENT';
export const GET_EVENT_BY_ID = 'event/GET_EVENT_BY_ID';
export const RESET_EVENT_DATA = 'event/RESET_EVENT_DATA';
