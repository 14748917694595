import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, Stack, Divider, CardHeader, Typography, CardContent } from '@mui/material';
import { SkeletonCart } from 'components/skeleton';
import { formatDistanceValue, fCurrency, calculatedTotalCharges } from 'utils';
import { FeeType } from 'constants/common';
import {
  registrationCartSummarySelector,
  successGetRegistrationCartSummarySelector,
  fetchingRegistrationCartSummarySelector,
} from 'modules/registration/selector';
import { eventDataSelector } from 'modules/events/selector';

// RegistrationCartSummary.propTypes = {
// total: PropTypes.number,
// discount: PropTypes.number,
// subtotal: PropTypes.number,
// shipping: PropTypes.number,
// onEdit: PropTypes.func,
// enableEdit: PropTypes.bool,
// onApplyDiscount: PropTypes.func,
// enableDiscount: PropTypes.bool,
// };

export default function RegistrationCartSummary({ isPayNowStep = false, isCheckoutPage = false }) {
  const registrationCartSummary = useSelector(registrationCartSummarySelector);
  const successGetRegistrationCartSummary = useSelector(successGetRegistrationCartSummarySelector);
  const fetchingRegistrationCartSummary = useSelector(fetchingRegistrationCartSummarySelector);
  const event = useSelector(eventDataSelector);

  const displayedCartData = (() => {
    if (!isPayNowStep) {
      return {
        ...registrationCartSummary,
        total: registrationCartSummary?.subTotal,
      };
    }
    return registrationCartSummary;
  })();

  const fees = [...(displayedCartData?.fees || []), ...(displayedCartData?.taxes || [])];

  const renderDelivery = () => {
    const deliveryCharge = registrationCartSummary?.fees?.find((fee) => fee.type === FeeType.DELIVERY_FEE);
    if (!deliveryCharge) {
      return null;
    }
    return (
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Delivery Fee
        </Typography>
        <Typography variant="subtitle2">
          {fCurrency((deliveryCharge?.amountInCent ?? 0) / 100, deliveryCharge?.currency)}
        </Typography>
      </Stack>
    );
  };

  return (
    <Card sx={{ mb: 3 }}>
      <CardHeader title="Registration Summary" />

      {successGetRegistrationCartSummary && !fetchingRegistrationCartSummary ? (
        <CardContent>
          <Stack spacing={2}>
            {displayedCartData?.lineItems?.length > 0 && (
              <RegistrationCartSummaryItems
                lineItems={displayedCartData?.lineItems}
                metricUsedIsKm={event?.metricUsedIsKm || true}
              />
            )}

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Sub Total
              </Typography>
              <Typography variant="subtitle2">
                {fCurrency((displayedCartData?.subTotal?.amountInCent ?? 0) / 100, displayedCartData?.total?.currency)}
              </Typography>
            </Stack>
            {displayedCartData?.discounts?.length > 0 && (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Discount
                </Typography>
                <Typography variant="subtitle2">{0}</Typography>
              </Stack>
            )}
            {fees?.length > 0 && isPayNowStep && (
              <>
                {renderDelivery()}
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Transaction Fee
                  </Typography>
                  <Typography variant="subtitle2">
                    {calculatedTotalCharges(fees?.filter((fee) => fee.type))?.name}
                  </Typography>
                </Stack>
              </>
            )}
            <Divider />
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle1">Total</Typography>
              <Box sx={{ textAlign: 'right' }}>
                <Typography variant="subtitle1" sx={{ color: 'error.main' }}>
                  {fCurrency((displayedCartData?.total?.amountInCent ?? 0) / 100, displayedCartData?.total?.currency)}
                </Typography>
                {!isPayNowStep && isCheckoutPage && (
                  <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                    (Fees are calculated on the next step)
                  </Typography>
                )}
                {displayedCartData?.fees?.length > 0 && isPayNowStep && (
                  <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                    (VAT included if applicable)
                  </Typography>
                )}
              </Box>
            </Stack>

            {/* <TextField
            fullWidth
            placeholder="Discount codes / Gifts"
            value="DISCOUNT5"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button onClick={() => () => {}} sx={{ mr: -0.5 }}>
                    Apply
                  </Button>
                </InputAdornment>
              ),
            }}
          /> */}
          </Stack>
        </CardContent>
      ) : (
        <SkeletonCart />
      )}
    </Card>
  );
}

const RegistrationCartSummaryItems = memo(({ lineItems, metricUsedIsKm }) => {
  const lineItemsToRender = lineItems.filter((item) => item.quantity);
  return (
    <>
      {lineItemsToRender?.map(
        (item, index) =>
          item?.quantity && (
            <Stack direction="row" justifyContent="space-between" key={index}>
              <Typography variant="body2" sx={{ color: 'text.primary' }}>
                {formatDistanceValue(metricUsedIsKm || true, item.distance)} (
                {fCurrency((item?.price?.amountInCent ?? 0) / 100)})
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.primary' }}>
                {` x ${item.quantity}`}
              </Typography>
              <Typography variant="subtitle2">
                {fCurrency(((item?.price?.amountInCent ?? 0) / 100) * item.quantity, item?.price?.currency)}
              </Typography>
            </Stack>
          )
      )}
    </>
  );
});
