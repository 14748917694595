import { handleActions } from 'redux-actions';
import {
  GET_ORGANIZATION,
  GET_USER_LIST_OF_ORGANIZATIONS,
  SET_SELECTED_ORGANIZATION_ID,
  SET_SELECTED_EVENT_ID,
  GET_ORGANIZATION_STAFF_LIST,
  REMOVE_SELECTED_ORGANIZATION_ID,
  REMOVE_SELECTED_ORGANIZATION_EVENT_ID,
  SET_ORGANIZATION_STAFF,
  ORGANIZATION_STAFF_IS_FETCHED,
  UPDATE_STAFF_DETAILS,
  GET_STAFF_EVENTS_LIST,
  FETCHING_ORGANIZATION_STAFF,
  SET_IS_FETCHED_ORGANIZATION_EVENTS,
  // events
  FETCH_ORGANIZATION_EVENTS,
  ADD_EVENT_TO_EVENT_LISTS,
  // organizations
  FETCH_ORGANIZATION_DASHBOARD_DATA,
  IS_FETCHED_ORGANIZATION_DASHBOARD_DATA,
  IS_FETCHED_ORGANIZATION_DATA,
  UPDATE_ORGANIZATION_DATA,
  SET_UPDATING_ORGANIZATION,
  RESET_SELECTED_ORGANIZATION,
  UPDATE_STAFF_EVENT_ROLE,
} from './types';

export const initialState = {
  selectedOrganizationId: '',
  selectedEventId: '',
  organization: {
    data: null,
    odooData: null,
    isUserAdmin: false,
  },
  dashboard: {
    eventsCount: 0,
    registrationsCounts: 0,
    totalAmountAccumulated: 0,
  },
  organizationStaffs: [],
  organizationStaff: {
    id: '',
    staffEventList: [],
  },
  staffEventListIsFetched: false,
  organizationDataFetched: false,
  organizationStaffIsFetched: false,
  updatingOrganizationData: false,
  isDashboardFetched: false,
  event: null,
  events: {
    list: [],
    isFetched: false,
  },
  organizationsList: [],
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [SET_SELECTED_ORGANIZATION_ID]: {
    next: (state, action) => ({
      ...state,
      ...initialState,
      selectedOrganizationId: action.payload || '',
    }),
  },
  [SET_SELECTED_EVENT_ID]: {
    next: (state, action) => ({
      ...state,
      selectedEventId: action.payload || '',
    }),
  },
  [GET_ORGANIZATION]: {
    next: (state, action) => ({
      ...state,
      organization: {
        ...state.organization,
        data: action.payload.organization || null,
        roles: action.payload?.roles || [],
        isUserAdmin: action.payload.isUserAdmin,
      },
      organizationDataFetched: true,
    }),
  },
  [GET_USER_LIST_OF_ORGANIZATIONS]: {
    next: (state, action) => ({
      ...state,
      organizationsList: action.payload || [],
    }),
  },
  [GET_ORGANIZATION_STAFF_LIST]: {
    next: (state, action) => ({
      ...state,
      organizationStaffs: action.payload || [],
    }),
  },
  [SET_ORGANIZATION_STAFF]: {
    next: (state, action) => ({
      ...state,
      organizationStaff: action.payload || null,
    }),
  },
  [FETCHING_ORGANIZATION_STAFF]: {
    next: (state, action) => ({
      ...state,
      organizationStaff: null,
    }),
  },
  [UPDATE_STAFF_DETAILS]: {
    next: (state, action) => ({
      ...state,
      organizationStaff: {
        ...state.organizationStaff,
        ...action.payload,
      },
    }),
  },
  [GET_STAFF_EVENTS_LIST]: {
    next: (state, action) => ({
      ...state,
      organizationStaff: {
        ...state.organizationStaff,
        staffEventList: action.payload,
      },
      staffEventListIsFetched: true,
    }),
  },
  [UPDATE_STAFF_EVENT_ROLE]: {
    next: (state, action) => ({
      ...state,
      organizationStaff: {
        ...state.organizationStaff,
        ...(action.payload?.roles && !action.payload?.isStaffEventRolUpdate && { roles: action.payload?.roles }),
      },
      staffEventListIsFetched: true,
    }),
  },
  [ORGANIZATION_STAFF_IS_FETCHED]: {
    next: (state, action) => ({
      ...state,
      organizationStaffIsFetched: action.payload || false,
    }),
  },
  [REMOVE_SELECTED_ORGANIZATION_ID]: {
    next: (state, action) => ({
      ...state,
      selectedOrganizationId: '',
    }),
  },
  [REMOVE_SELECTED_ORGANIZATION_EVENT_ID]: {
    next: (state, action) => ({
      ...state,
      selectedEventId: '',
    }),
  },
  [FETCH_ORGANIZATION_EVENTS]: {
    next: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        list: action.payload || [],
        isFetched: true,
      },
    }),
  },
  [SET_IS_FETCHED_ORGANIZATION_EVENTS]: {
    next: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        isFetched: action.payload || false,
      },
    }),
  },
  [FETCH_ORGANIZATION_DASHBOARD_DATA]: {
    next: (state, action) => ({
      ...state,
      dashboard: {
        eventsCount: action.payload?.eventsCount || 0,
        registrationsCounts: action.payload?.registrationsCounts || 0,
        totalAmountAccumulated: action.payload?.totalAmountAccumulated || 0,
      },
      organization: {
        ...state.organization,
        odooData: action.payload?.organization || null,
      },
      isOrganizationDashboardDataFetched: true,
    }),
  },
  [IS_FETCHED_ORGANIZATION_DASHBOARD_DATA]: {
    next: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        isDashboardFetched: action.payload || false,
      },
    }),
  },
  [IS_FETCHED_ORGANIZATION_DATA]: {
    next: (state, action) => ({
      ...state,
      organizationDataFetched: action.payload || false,
    }),
  },
  [UPDATE_ORGANIZATION_DATA]: {
    next: (state, action) => ({
      ...state,
      organization: {
        ...state.organization,
        data: {
          ...(state.organization?.data || {}),
          ...action.payload?.data,
        },
        odooData: {
          ...(state.organization?.odooData || {}),
          ...action.payload?.odooData,
        },
      },
      updatingOrganizationData: true,
    }),
  },
  [SET_UPDATING_ORGANIZATION]: {
    next: (state, action) => ({
      ...state,
      updatingOrganizationData: action.payload || false,
    }),
  },
  [RESET_SELECTED_ORGANIZATION]: {
    next: (state, action) => ({
      ...initialState,
    }),
  },
  [ADD_EVENT_TO_EVENT_LISTS]: {
    next: (state, action) => ({
      ...state,
      events: {
        ...state.events,
        list: [action.payload, ...state.events.list],
      },
    }),
  },
};

export default handleActions(ACTION_HANDLERS, initialState);
