import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
};

export function RHFCheckbox({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Checkbox {...field} checked={field.value} />}
        />
      }
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
};

export function RHFMultiCheckbox({ name, options, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const onSelected = (option) =>
          field?.value && field?.value?.includes(option)
            ? field?.value?.filter((value) => value !== option)
            : [...(field?.value ? field?.value : []), option];

        const shouldSelect = other?.required && field?.value?.length === 0;

        return (
          <>
            <FormGroup>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  control={
                    <Checkbox
                      checked={!field?.value ? false : field?.value.includes(option?.value)}
                      onChange={() => field.onChange(onSelected(option.value))}
                      {...(shouldSelect && { required: true })}
                    />
                  }
                  label={option.label}
                  {...other}
                />
              ))}
            </FormGroup>

            {!!shouldSelect && <FormHelperText sx={{ px: 2 }}>Should select at least 1</FormHelperText>}
          </>
        );
      }}
    />
  );
}
