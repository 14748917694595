export const mergeObjectsByField = (arr, fieldsToCompare, caseSensitive = true) => {
  const mergedMap = new Map();

  arr.forEach((obj = {}) => {
    const key = fieldsToCompare
      .map((field) => {
        const value = obj?.[field]?.trim();
        return caseSensitive ? value : value.toLowerCase();
      })
      .join('|');

    if (!mergedMap?.has(key)) {
      mergedMap?.set(key, { ...obj });
    } else {
      const existingObj = mergedMap?.get(key);
      Object.keys(obj).forEach((field) => {
        if (typeof obj[field] === 'string' && obj[field].startsWith('{') && obj[field].endsWith('}')) {
          try {
            const jsonObj = JSON.parse(obj[field]);
            if (
              typeof existingObj[field] === 'string' &&
              existingObj[field].startsWith('{') &&
              existingObj[field].endsWith('}')
            ) {
              const existingJsonObj = JSON.parse(existingObj[field]);
              existingObj[field] = JSON.stringify({ ...existingJsonObj, ...jsonObj });
            } else {
              existingObj[field] = JSON.stringify(jsonObj);
            }
          } catch (e) {
            existingObj[field] = obj[field];
          }
        } else {
          existingObj[field] = obj[field];
        }
      });
    }
  });

  return Array.from(mergedMap?.values());
};
