import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { SettingsProvider } from 'contexts/SettingsContext';
import { CollapseDrawerProvider } from 'contexts/CollapseDrawerContext';
import MotionLazyContainer from 'components/animate/MotionLazyContainer';
import ThemeSettings from 'components/settings';
import NotistackProvider from 'components/NotistackProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// theme
import ThemeProvider from 'theme';
// scroll bar
import 'simplebar/src/simplebar.css';
import 'react-image-lightbox/style.css';

import { HelmetProvider } from 'react-helmet-async';
import Amplify from 'aws-amplify';
import { store, history } from './store';
import awsConfig from './aws-exports';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import { AppContainer as App } from './containers/AppContainer';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

// highlight
import 'utils/highlight';

// editor
import 'react-quill/dist/quill.snow.css';

// --------------- START OF AWS AMPLIFY CONFIGURATION ----------

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
// const [localRedirectSignIn, productionRedirectSignIn] = awsConfig.oauth.redirectSignIn.split(',');

// const [localRedirectSignOut, productionRedirectSignOut] = awsConfig.oauth.redirectSignOut.split(',');

// const updatedAwsConfig = {
//   ...awsConfig,
//   oauth: {
//     ...awsConfig.oauth,
//     redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
//     redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
//   },
// };

Amplify.configure(awsConfig);

// ___________________ END OF AWS AMPLIFY CONFIGURATION ___________________

// If you want to enable client cache, register instead.
serviceWorker.unregister();

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ConnectedRouter history={history}>
        <HelmetProvider>
          <SettingsProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CollapseDrawerProvider>
                <MotionLazyContainer>
                  <ThemeProvider>
                    <ThemeSettings>
                      <NotistackProvider>
                        <App />
                      </NotistackProvider>
                    </ThemeSettings>
                  </ThemeProvider>
                </MotionLazyContainer>
              </CollapseDrawerProvider>
            </LocalizationProvider>
          </SettingsProvider>
        </HelmetProvider>
      </ConnectedRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
