// import { useContext } from 'react';
import { userDataSelector, isAuthSelector, isEmailConfirmedSelector } from 'modules/user/selector';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { getPath } from 'utils';
import { Routes } from 'constants';

const useAuth = () => {
  // const context = useContext(true)
  const userData = useSelector(userDataSelector);
  const value = {
    isAuth: useSelector(isAuthSelector),
    isEmailVerified: useSelector(isEmailConfirmedSelector),
    user: userData,
  };

  if (!userData?.email) return <Redirect to={getPath(Routes.LOGIN)} />;

  return value ?? { isAuth: false, user: null };
};

export default useAuth;
