import { memo } from 'react';
import { Typography, Box, Stack } from '@mui/material';

export const RegistrationLineItemSummary = memo(({ userData, formFields }) => {
  const dataToRenderArray = Object.keys(userData)
    ?.reduce((acc, key) => {
      const value = userData[key] ? (Array.isArray(userData[key]) ? userData[key]?.join(', ') : userData[key]) : '';
      const formField = formFields?.find((field) => field?.fieldId === key);
      const label = formField?.label ?? 'Team Name';
      const orderNum = formField?.orderNumber;
      return [
        ...acc,
        {
          label,
          value,
          orderNum,
        },
      ];
    }, [])
    .sort((a, b) => a.orderNum - b.orderNum);

  return (
    <>
      <Box sx={{ pt: 2 }}>
        {dataToRenderArray.map((field, index) => (
          <Stack spacing={1} direction="row" key={index}>
            <Typography variant="subtitle1">{field?.label}: &nbsp;</Typography>
            <Typography variant="subtitle2" color="red">
              {field?.value}
            </Typography>
          </Stack>
        ))}
      </Box>
    </>
  );
});
