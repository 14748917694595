// import { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import useAuth from 'hooks/useAuth';
import { Stack, Card, Typography } from '@mui/material';
import CopyClipboard from 'components/CopyClipboard';

// ----------------------------------------------------------------------

export default function OrganizersContactInfo({ event }) {
  const { contactInfo } = event;
  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={3} alignItems="stretch">
        {contactInfo?.length > 0 ? (
          contactInfo?.map((contact, index) => (
            <div key={index}>
              <Typography sx={{ color: 'text.primary' }}>
                <b>{contact?.type}</b>
              </Typography>
              <CopyClipboard value={contact?.value} disabledTextBox />
            </div>
          ))
        ) : (
          <Typography sx={{ color: 'text.primary' }}>
            <b>Nothing to show</b>
          </Typography>
        )}
      </Stack>
    </Card>
  );
}
