export const NeedsCTXQueries = [
  'createToken',
  'verifyToken',
  'upsertUser',
  'addUserToGroup',
  'createDraftRegistration',
  'resetDraftRegistration',
  'getCalculatedRegistrationCart',
  'updateCategoriesInventory',
  'updateAmountAccumulated',
  'preCheckoutChecker',
  'createAnOrganization',
  'deleteAnOrganization',
  'updateAnOrganization',
  'removeStaffsFromOrganization',
  'batchDeleteUserOrganizationEventJunction',
  'createNewEvent',
  'updateAnEvent',
  'deleteAnEvent',
  'createNewCategory',
  'updateCategory',
  'deleteCategory',

  // Queries
  'getOrganizationDashboardData',
  'fetchEventPortalDashboardData',
];

export const PriceType = {
  REGULAR_PRICE: 'Regular Price',
};

export const AMPLIFY_ENVIRONMENT = {
  DEVELOP: 'develop',
  PRODUCTION: 'production',
  TESTING: 'testing',
};

export const ReturnType = {
  SortedNameValue: 'SortedNameValue',
  ValueOnlyWithCurrencyFormat: 'ValueOnlyWithCurrencyFormat',
  ValueWithValueValidity: 'ValueWithValueValidity',
};

export const FeeType = {
  SERVICE_FEE: 'service-fee',
  TRANSACTION_FEE: 'transaction-fee',
  TAX_FEE: 'tax-fee',
  DELIVERY_FEE: 'delivery-fee',
};

export const FeatureToggle = {
  FEATURE_ADMIN: 'FEATURE_ADMIN',
};

export const AppConstants = {
  ADMIN_HOSTNAME: 'ADMIN_HOSTNAME',
  REGISTRATION_SIGNUP_PORTAL_AGREEMENT: 'REGISTRATION_SIGNUP_PORTAL_AGREEMENT',
  GENERAL_EVENT_REGISTRATION_WAIVER: 'GENERAL_EVENT_REGISTRATION_WAIVER',
  DATA_SHARING_AGREEMENT: 'DATA_SHARING_AGREEMENT',
  PLATFORM_AGREEMENT: 'PLATFORM_AGREEMENT',
  EVENT_CREATION_AGREEMENT: 'EVENT_CREATION_AGREEMENT',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  DEFAULT_WAIVER: 'DEFAULT_WAIVER',
  PAYMENT_OPTIONS: 'PAYMENT_OPTIONS',

  // Company Info
  COMPANY_NAME: 'COMPANY_NAME',
  COMPANY_ADDRESS: 'COMPANY_ADDRESS',
  COMPANY_CONTACT_PERSON: 'COMPANY_CONTACT_PERSON',
  COMPANY_SUPPORT_EMAIL: 'COMPANY_SUPPORT_EMAIL',
  COMPANY_COMPANY_MOBILE_NUMBER: 'COMPANY_COMPANY_MOBILE_NUMBER',
};

export const EVENT_STATUS = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  DONE: 'DONE',
  NO_SLOTS_AVAILABLE: 'NO_SLOTS_AVAILABLE',
};
