import Iconify from 'components/Iconify';
import { getPath, getRouteTitle, formatCategoryForNavigation, removeDuplicateObject, sortDistances } from 'utils/index';
import { Routes, NavigationType, OrganizationRole } from 'constants/index';
import { generatePath } from 'react-router-dom';

// ----------------------------------------------------------------------

const getIcon = (name, color = '#637381') => <Iconify icon={name} width={22} height={22} color={color} />;

// NOTE: If Nav Item is Having a Property of 'type' assign an '#' to property path

const ICONS = {
  user: getIcon('arcticons:nofbeventscraper'),
  ecommerce: getIcon('arcticons:nofbeventscraper'),
  analytics: getIcon('arcticons:nofbeventscraper'),
  calendar: getIcon('arcticons:nofbeventscraper'),
  dashboard: getIcon('mdi:monitor-dashboard'),
  organization: getIcon('mdi:people-group'),
  settings: getIcon('uil:setting'),
  events: getIcon('icon-park-solid:activity-source'),
  back: getIcon('material-symbols:arrow-back-rounded'),
  setup: getIcon('gala:settings'),
  staff: getIcon('fa6-solid:people-line'),
  category: getIcon('carbon:category'),
};

export const userRacesSubNavigation = [
  {
    title: 'All Races',
    path: getPath(Routes.MY_RACES),
    icon: getIcon('bx:run'),
  },
  {
    title: getRouteTitle(Routes.MY_UPCOMING_EVENTS).split(' | ')[0],
    path: getPath(Routes.MY_UPCOMING_EVENTS),
    icon: getIcon('arcticons:nofbeventscraper'),
  },
  {
    title: getRouteTitle(Routes.MY_PREVIOUS_EVENTS).split(' | ')[0],
    path: getPath(Routes.MY_PREVIOUS_EVENTS),
    icon: getIcon('bx:run'),
  },
];

// NON AUTH ROUTES
// ----------------------------------------------------------------------
export const useNonAuthNavigation = [
  {
    subheader: 'navigation',
    items: [
      {
        title: getRouteTitle(Routes.EVENTS).split(' | ')[0],
        path: getPath(Routes.EVENTS),
        icon: getIcon('arcticons:nofbeventscraper'),
      },
      {
        title: getRouteTitle(Routes.CALCULATOR).split(' | ')[0],
        path: getPath(Routes.CALCULATOR),
        icon: getIcon('ph:calculator-light'),
        organizerRoute: false,
      },
      {
        title: getRouteTitle(Routes.LOGIN).split(' | ')[0],
        path: getPath(Routes.LOGIN),
        icon: getIcon('entypo:login'),
      },
      {
        title: getRouteTitle(Routes.REGISTER).split(' | ')[0],
        path: getPath(Routes.REGISTER),
        icon: getIcon('ant-design:form-outlined'),
      },
      {
        title: getRouteTitle(Routes.VERIFICATION).split(' | ')[0],
        path: getPath(Routes.VERIFICATION),
        icon: getIcon('uil:comment-alt-verify'),
      },
      {
        title: getRouteTitle(Routes.FORGOTPASSWORD).split(' | ')[0],
        path: getPath(Routes.FORGOTPASSWORD),
        icon: getIcon('bi:shield-lock'),
      },
    ],
  },
];

export const userAuthNavigation = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'navigation',
    items: [
      { title: getRouteTitle(Routes.EVENTS).split(' | ')[0], path: getPath(Routes.EVENTS), icon: ICONS.calendar },
      {
        title: getRouteTitle(Routes.MY_RACES).split(' | ')[0],
        path: getPath(Routes.MY_RACES),
        icon: getIcon('bx:run'),
        // children: userRacesSubNavigation,
      },
      {
        title: getRouteTitle(Routes.PROFILE).split(' | ')[0],
        path: getPath(Routes.PROFILE),
        icon: getIcon('bxs:user'),
        organizerRoute: false,
      },
      {
        title: getRouteTitle(Routes.CALCULATOR).split(' | ')[0],
        path: getPath(Routes.CALCULATOR),
        icon: getIcon('ph:calculator-light'),
        organizerRoute: false,
      },
      {
        title: getRouteTitle(Routes.VERIFICATION).split(' | ')[0],
        path: getPath(Routes.VERIFICATION),
        icon: getIcon('uil:comment-alt-verify'),
      },
      // {
      //   title: getRouteTitle(Routes.SETTINGS).split(' | ')[0],
      //   path: getPath(Routes.SETTINGS),
      //   icon: getIcon('bytesize:settings'),
      //   organizerRoute: false,
      // },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     {
  //       title: 'user',
  //       path: '/dashboard/user',
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'Four', path: '/dashboard/user/four' },
  //         { title: 'Five', path: '/dashboard/user/five' },
  //         { title: 'Six', path: '/dashboard/user/six' },
  //       ],
  //     },
  //   ],
  // },
];

export const organizationsNavigation = [
  {
    subheader: 'Your Organizations',
    items: [
      {
        title: 'Organizations',
        path: getPath(Routes.ORGANIZATIONS_DASHBOARD),
        icon: ICONS.organization,
      },
      {
        title: 'Switch to User',
        path: '#',
        icon: getIcon('ic:round-switch-account'),
        type: NavigationType.SWITCH,
      },
    ],
  },
];

export const eventPortalNavigation = ({ categories: eventCategories, metricUsedIsKm, organizationId }) => {
  const sortedCategories = sortDistances(eventCategories, false);
  const formattedCategories = sortedCategories?.map((category) =>
    formatCategoryForNavigation(metricUsedIsKm, category)
  );
  const categories = removeDuplicateObject(formattedCategories, 'name')?.sort(
    (a, b) => Number.parseFloat(a?.name) - Number.parseFloat(b?.name)
  );

  const categoriesNavigations = {
    title: 'Categories',
    path: getPath(Routes.EVENT_SETUP_CATEGORY_SETUP),
    icon: ICONS.category,
    roles: [OrganizationRole.ADMIN, OrganizationRole.CREATOR],
    children: categories?.map((category) => ({
      title: category?.name,
      path: generatePath(getPath(Routes.EVENT_SETUP_CATEGORY_SETUP), {
        organizationId,
        selectedEventId: category?.eventId,
        categoryId: category?.categoryId,
      }),
    })),
  };

  return [
    {
      subheader: "Event's Portal",
      items: [
        {
          title: 'Dashboard',
          path: getPath(Routes.EVENT_DASHBOARD),
          icon: ICONS.dashboard,
        },
        {
          title: 'Registrants',
          path: getPath(Routes.EVENT_REGISTRANTS),
          icon: ICONS.organization,
        },
        {
          title: 'Setup',
          path: getPath(Routes.EVENT_SETUP_GENERAL),
          roles: [OrganizationRole.ADMIN, OrganizationRole.CREATOR],
          icon: ICONS.setup,
          children: [
            {
              title: 'Event Setup',
              path: getPath(Routes.EVENT_SETUP_GENERAL),
            },
            {
              title: 'Categories Setup',
              path: getPath(Routes.EVENT_CATEGORIES_GENERAL_SETUP),
            },
          ],
        },
        ...(categories?.length > 0 ? [categoriesNavigations] : []),
        {
          title: 'Staffs',
          path: getPath(Routes.EVENT_STAFFS),
          icon: ICONS.staff,
          roles: [OrganizationRole.ADMIN, OrganizationRole.CREATOR],
        },
        {
          title: 'Go to Organization',
          path: '#',
          icon: ICONS.back,
          type: NavigationType.EXIT_EVENT,
        },
        {
          title: 'Switch to User',
          path: '#',
          icon: getIcon('ic:round-switch-account'),
          type: NavigationType.SWITCH,
        },
      ],
    },
  ];
};

export const organizationPortalNavigation = [
  {
    subheader: "Organization's Portal",
    items: [
      {
        title: 'Dashboard',
        path: getPath(Routes.ORGANIZATION_DASHBOARD),
        icon: ICONS.dashboard,
      },
      {
        title: 'Events',
        path: getPath(Routes.ORGANIZATION_EVENTS),
        icon: getIcon('arcticons:nofbeventscraper'),
      },
      {
        title: 'Staffs',
        path: getPath(Routes.ORGANIZATION_STAFFS),
        icon: ICONS.staff,
        roles: [OrganizationRole.ADMIN, OrganizationRole.CREATOR],
      },
      {
        title: 'Settings',
        path: getPath(Routes.ORGANIZATION_SETTINGS),
        icon: ICONS.settings,
        roles: [OrganizationRole.ADMIN, OrganizationRole.CREATOR],
      },
      {
        title: 'Go to Organization List',
        path: '#',
        icon: ICONS.back,
        type: NavigationType.EXIT_ORG,
      },
      {
        title: 'Switch to User',
        path: '#',
        icon: getIcon('ic:round-switch-account'),
        type: NavigationType.SWITCH,
      },
    ],
  },
];
