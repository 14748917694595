import Slider from 'react-slick';
import { useState, useRef, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Typography } from '@mui/material';
//
import Image from 'components/Image';
import LightboxModal from 'components/LightboxModal';
import { CarouselArrowIndex } from 'components/carousel';
import useResponsive from 'hooks/useResponsive';
import { useS3Hook } from 'hooks/useS3hook';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { S3ImageSizes } from 'constants/index';

export default function EventImagesCarousel({
  shouldShowTabSizeImages = true,
  imagesInfo: imagesItems = null,
  title = '',
}) {
  const [openLightbox, setOpenLightbox] = useState(false);

  const isMountedRef = useIsMountedRef();

  const [selectedImage, setSelectedImage] = useState(0);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [nav1, setNav1] = useState();

  const [nav2, setNav2] = useState();

  const slider1 = useRef(null);

  const slider2 = useRef(null);

  const isDesktop = useResponsive('up', 'sm');

  const { getImage } = useS3Hook();

  const [imagesInfo, setImagesInfo] = useState([]);

  // Pull all images
  useEffect(async () => {
    if (isMountedRef?.current) {
      const images = imagesItems;

      if (images?.length) {
        const imagesResponse = await Promise.all(
          images?.map(async ({ urlImage, id, size = S3ImageSizes.original }) => ({
            key: urlImage,
            id,
            image: await getImage(urlImage, undefined, undefined, size),
          }))
        );

        const imagesReduced = imagesResponse?.reduce(
          (acc, item, index) => [
            ...acc,
            {
              urlImage: item?.image,
              id: item?.id,
              key: item?.key || index,
            },
          ],
          []
        );

        setImagesInfo(imagesReduced);
      }
    }

    return () => {
      isMountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesItems, isMountedRef.current]);

  const imagesLightbox = imagesInfo.map((item) => item.urlImage);

  const handleOpenLightbox = (url) => {
    const selectedImage = imagesLightbox.findIndex((index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  const settings1 = {
    dots: false,
    arrows: false,
    slidesToShow: 1,
    draggable: false,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const settings2 = {
    dots: false,
    arrows: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    infinite: true,
    variableWidth: true,
    centerPadding: '0px',
    slidesToShow: imagesInfo.length > 3 ? 3 : imagesInfo.length,
  };

  useEffect(() => {
    if (imagesInfo?.length) {
      if (slider1.current) {
        setNav1(slider1.current);
      }
      if (slider2.current) {
        setNav2(slider2.current);
      }
    }
  }, [imagesInfo]);

  const handlePrevious = () => {
    slider2.current?.slickPrev();
    slider1.current?.slickPrev();
  };

  const handleNext = () => {
    slider2.current?.slickNext();
    slider1.current?.slickNext();
  };

  return (
    <RootStyle>
      <Card>
        <Box sx={{ p: isDesktop ? 1 : 0 }}>
          <Box sx={{ zIndex: 0, borderRadius: 2, overflow: 'hidden', position: 'relative' }}>
            {title && (
              <Typography variant={isDesktop ? 'h3' : 'h5'} gutterBottom noWrap sx={{ p: 3 }}>
                <b>{title || ''}</b>
              </Typography>
            )}
            {imagesInfo?.length > 0 && (
              <Slider {...settings1} asNavFor={nav2} ref={slider1}>
                {imagesInfo?.map((img, index) => (
                  <Image
                    key={index}
                    alt={img?.title || img?.description || ''}
                    src={img?.urlImage || ''}
                    ratio={'6/4'}
                    onClick={() => handleOpenLightbox(img?.urlImage)}
                    sx={{ cursor: 'zoom-in' }}
                  />
                ))}
              </Slider>
            )}
            <CarouselArrowIndex
              index={currentIndex}
              total={imagesInfo?.length ?? 0}
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          </Box>
        </Box>

        {imagesInfo?.length > 0 && shouldShowTabSizeImages && (
          <Box
            sx={{
              my: 3,
              mx: 'auto',
              overflow: 'hidden',
              '& .slick-current .isActive': { opacity: 0.1 },
              ...(imagesInfo.length === 1 && { maxWidth: THUMB_SIZE * 1 + 16 }),
              ...(imagesInfo.length === 2 && { maxWidth: THUMB_SIZE * 2 + 32 }),
              ...(imagesInfo.length === 3 && { maxWidth: THUMB_SIZE * 3 + 48 }),
              ...(imagesInfo.length === 4 && { maxWidth: THUMB_SIZE * 3 + 48 }),
              ...(imagesInfo.length >= 5 && { maxWidth: THUMB_SIZE * 6 }),
              ...(imagesInfo.length > 2 && {
                position: 'relative',
                '&:before, &:after': {
                  top: 0,
                  zIndex: 9,
                  content: "''",
                  height: '100%',
                  position: 'relative',
                  maxWidth: '100%',
                  width: (THUMB_SIZE * 2) / 3,
                  backgroundImage: (theme) =>
                    `linear-gradient(to left, ${alpha(theme.palette.background.paper, 0)} 0%, ${
                      theme.palette.background.paper
                    } 100%)`,
                },
                '&:after': { right: 0, transform: 'scaleX(-1)' },
              }),
            }}
          >
            <Slider {...settings2} asNavFor={nav1} ref={slider2}>
              {imagesInfo?.map((img, index) => (
                <Box key={index} sx={{ px: 0.75 }}>
                  <Image
                    disabledEffect
                    alt="thumb image"
                    src={img?.urlImage}
                    sx={{
                      width: THUMB_SIZE,
                      height: THUMB_SIZE,
                      borderRadius: 1.5,
                      cursor: 'pointer',
                      ...(currentIndex === index && {
                        border: (theme) => `solid 3px ${theme.palette.primary.main}`,
                      }),
                    }}
                  />
                </Box>
              ))}
            </Slider>
          </Box>
        )}
      </Card>

      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

const THUMB_SIZE = 64;

const RootStyle = styled('div')(({ theme }) => ({
  marginBottom: 3,
  '& .slick-slide': {
    float: theme.direction === 'rtl' ? 'right' : 'left',
    '&:focus': { outline: 'none' },
  },
}));
