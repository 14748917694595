import { contactInfoTypes, EVENT_STATUS } from 'constants/index';
import { CommonHelpers } from 'utils/common/index';
import { checkEventAvailability } from './common/helpers/categoryHelper';

export const formatDistanceValue = CommonHelpers.EventHelpers.formatDistanceValue;

export const getCategories = (categories, metric) => {
  return categories?.map((category) => formatDistanceValue(metric, category?.distance));
};

// export const formatDistanceValue = (metric = true, distance) => {
//   const isNumber = !Number.isNaN(parseFloat(distance));

//   return isNumber ? `${distance}${metric || metric === null ? 'km' : 'miles'}` : distance;
// };

export const formatCategoryValue = (metric, category) => {
  return `${formatDistanceValue(metric, category?.distance) || ''} - ${category?.categoryValue || 'Open'}`;
};

export const formatCategoryTypeAndValue = (metric, category) => {
  return `${formatDistanceValue(metric, category?.distance) || ''} - ${
    category?.categoryType?.toLowerCase() === 'open'
      ? category?.categoryType
      : `${category?.categoryType} - ${category?.categoryValue}`
  }`;
};

export const formatCategoryForNavigation = (metric, category) => {
  return {
    name: formatCategoryValue(metric, category),
    eventId: category?.eventId,
    categoryId: category?.id,
  };
};

export const availabilityChecker = (event, categories, categoryId, otherParams) => {
  const options = { categoryId: categoryId || '' };

  const hasResults = otherParams?.hasResults || false;

  const eventAvailability = () => {
    const checker = checkEventAvailability(
      event?.dateOfEvent,
      event?.registrationEnd,
      categories || event?.categories?.items || [],
      event?.slotsAvailabilityIsSameForAllCategories,
      options
    );

    const registrationButtonText =
      checker.status === EVENT_STATUS.OPEN
        ? 'Register'
        : checker.status === EVENT_STATUS.NO_SLOTS_AVAILABLE
        ? 'No slots available'
        : checker.status === EVENT_STATUS.CLOSE
        ? 'Registration closed'
        : checker.status === EVENT_STATUS.DONE
        ? `View Results ${hasResults ? '' : '(not available)'}`
        : '';
    return {
      ...checker,
      registrationButtonText,
    };
  };

  return eventAvailability();
};

export const getContactInfoByType = (values, type = contactInfoTypes.email) => {
  // Regular expression for email validation
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Regular expression for mobile number validation (assuming a 10-digit number)
  const mobilePattern = /\d{10,14}/;

  if (!Array.isArray(values)) {
    throw new Error('Input must be an array of values.');
  }

  const filteredValues = values.filter((value) => {
    if (type === contactInfoTypes.email) {
      return emailPattern.test(value);
    }
    if (type === contactInfoTypes.mobileNumber) {
      return mobilePattern.test(value);
    }
    return false;
  });

  return filteredValues?.[0] || '';
};
