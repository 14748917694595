import { useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Iconify from 'components/Iconify';

import { formatDistanceValue } from 'utils';
import EventImagesCarousel from './EventImagesCarousel';

export default function CategoryKits({ event }) {
  const { categories, metricUsedIsKm } = event;

  const orderOfDistance = useMemo(
    () => categories?.items?.sort((a, b) => b?.distance - a?.distance) || [],
    [categories]
  );

  //   const raceKitsForEachCategory = useMemo

  return (
    <div>
      {orderOfDistance?.length > 0 &&
        orderOfDistance?.map((category, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<Iconify icon={'bx:down-arrow'} />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <Typography sx={{ width: '33%', flexShrink: 0, color: 'red' }}>
                <b>{formatDistanceValue(metricUsedIsKm, category?.distance)}</b>
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                <b>{category?.categoryValue || 'Open'}</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {category?.raceKits?.items?.length
                ? category?.raceKits?.items?.map((item, index) => {
                    return (
                      <EventImagesCarousel
                        key={index}
                        shouldShowTabSizeImages={false}
                        imagesInfo={item?.images?.items || []}
                        title={item?.kitType || ''}
                        description={`${item?.optionName || ''} - ${item?.optionValues.join(',') || ''}`}
                      />
                    );
                  })
                : 'No kits information to show'}
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
}
