import { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Box, Grid, Card, Button, Stack, Typography, ButtonGroup, Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { LoadingButton } from '@mui/lab';
import Iconify from 'components/Iconify';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { eventContactInfoSelector } from 'modules/event/selector';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { updateDynamoEventData } from 'modules/event/actions';
import { selectedEventIdSelector } from 'modules/organization/selector';
import CancelIcon from '@mui/icons-material/Cancel';

// ----------------------------------------------------------------------

const contactInfoValuesConstructor = (eventContactInfo) =>
  eventContactInfo?.reduce((acc, item, index) => {
    return [...acc, { [`contact_${index}`]: item?.value }];
  }, []);

export default function EventContactInformation() {
  const dispatch = useDispatch();
  const [hasChanges, setHasChanges] = useState(true);
  const isMountedRef = useIsMountedRef();

  const eventContactInfo = useSelector(eventContactInfoSelector);
  const eventId = useSelector(selectedEventIdSelector);

  // eslint-disable-next-line
  const defaultValues = contactInfoValuesConstructor(eventContactInfo);

  const [contactForm, setContactForm] = useState(defaultValues);

  const methods = useForm({
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // Initializer
  useEffect(() => {
    if (!defaultValues?.length) {
      setContactForm([{ contact_0: '' }]);
    }
  }, [defaultValues, setContactForm]);

  useEffect(() => {
    if (isMountedRef.current) {
      setHasChanges(false);
    }
  }, [isMountedRef]);

  const onSubmit = useCallback(async () => {
    const reconstructedValues = contactForm?.reduce(
      (acc, contact, index) => [
        ...acc,
        {
          type: `Contact Info ${index + 1}`,
          value: Object.values(contact)?.[0],
        },
      ],
      []
    );
    const response = await dispatch(updateDynamoEventData({ id: eventId, contactInfo: reconstructedValues }));
    setHasChanges(false);
    return { success: response?.payload?.success || false };
    // eslint-disable-next-line
  }, [dispatch, setHasChanges, eventId, contactForm]);

  const onChangeHandler = (event, key) => {
    setHasChanges(true);
    setContactForm((prev) => {
      const filterValues = prev?.filter((contact) => Object.keys(contact)?.[0] !== key);

      return [...filterValues, { [key]: event.target.value }];
    });
  };

  const handleRemoveContact = (event, key) => {
    setHasChanges(true);
    setContactForm((prev) => {
      const filterValues = prev?.filter((contact) => Object.keys(contact)?.[0] !== key);

      return filterValues?.map((value, index) => ({ [`contact_${index}`]: Object.values(value)?.[0] }));
    });
  };

  // eslint-disable-next-line
  const handleAddNewContact = () => {
    // eslint-disable-next-line
    setContactForm((prev) => {
      return [...prev, { [`contact_${prev?.length}`]: '' }];
    });
  };

  const hasErrors = contactForm?.some((contact) => Object.entries(contact)?.[0]?.[1]?.length < 6);

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Contact Information
      </Typography>
      {hasChanges && <Alert severity="warning"> You have changes need to save</Alert>}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={1} alignItems="flex-end">
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                  <Button
                    size="small"
                    onClick={handleAddNewContact}
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                    disabled={!!hasErrors}
                  >
                    Add new Contact
                  </Button>
                </Stack>
              </Stack>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: 3,
                  columnGap: 2,
                  // gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                }}
              >
                {contactForm?.map((contactInfo, indexVal) => {
                  const keyVal = `contact_${indexVal}`;
                  const value = contactInfo?.[keyVal] || '';
                  const isError = value?.length < 6;
                  return (
                    <ButtonGroup variant="contained" key={indexVal} aria-label="outlined primary button group">
                      <RHFTextField
                        name={keyVal}
                        value={value}
                        label={`Contact ${indexVal + 1}`}
                        uncontrolled="true"
                        onChange={(values) => onChangeHandler(values, keyVal)}
                        error={isError}
                        helperText={isError ? 'Contact should be more than 5 characters' : ''}
                      />
                      {contactForm?.length !== 1 && (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={(event) => handleRemoveContact(event, keyVal)}
                          edge="end"
                          sx={{ paddingRight: 3 }}
                        >
                          <CancelIcon />
                        </IconButton>
                      )}
                    </ButtonGroup>
                  );
                })}
              </Box>
              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting} disabled={!!hasErrors}>
                    Save Changes
                  </LoadingButton>
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
