import { handleActions } from 'redux-actions';
import { GET_RESULTS_BY_CATEGORY, IS_RESULTS_FETCHING } from './types';

export const initialState = {
  raceResults: [],
  isFetching: false,
};

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [GET_RESULTS_BY_CATEGORY]: {
    next: (state, action) => ({
      ...state,
      raceResults: action.payload || [],
      isFetching: false,
    }),
  },
  [IS_RESULTS_FETCHING]: {
    next: (state, action) => ({
      ...state,
      isFetching: action.payload || false,
    }),
  },
};

export default handleActions(ACTION_HANDLERS, initialState);
