import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Radio,
  Stack,
  Typography,
  RadioGroup,
  CardHeader,
  CardContent,
  FormControlLabel,
} from '@mui/material';
import Iconify from 'components/Iconify';

CheckoutDelivery.propTypes = {
  deliveryOptions: PropTypes.array,
  onApplyShipping: PropTypes.func,
};

export default function CheckoutDelivery({
  deliveryOptions,
  onApplyShipping,
  setEnableNextCard,
  cardNumber,
  enableNextCard,
}) {
  const { control, setValue } = useFormContext();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (deliveryOptions?.length === 1 && isMountedRef.current) {
      setValue('delivery', deliveryOptions?.[0]?.value);
      setEnableNextCard((prev) => (cardNumber === prev ? Number(prev) + 1 : prev));
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [deliveryOptions, isMountedRef]);

  return (
    <Card>
      <CardHeader title="Delivery options" />
      <CardContent>
        <Controller
          name="delivery"
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              onChange={(event) => {
                const { value } = event.target;
                setEnableNextCard((prev) => (cardNumber === prev ? Number(prev) + 1 : prev));
                field.onChange(value);
                onApplyShipping(value);
              }}
            >
              <Stack spacing={2} alignItems="center" direction={{ xs: 'column', md: 'row' }}>
                {deliveryOptions.map((delivery) => {
                  const selected = field.value === delivery.value;

                  return (
                    <OptionStyle
                      key={delivery.value}
                      sx={{
                        ...(selected && {
                          boxShadow: (theme) => theme.customShadows.z20,
                        }),
                      }}
                    >
                      <FormControlLabel
                        value={delivery.value}
                        control={<Radio checkedIcon={<Iconify icon={'eva:checkmark-circle-2-fill'} />} />}
                        disabled={!(cardNumber <= enableNextCard)}
                        label={
                          <Box sx={{ ml: 1 }}>
                            <Typography variant="subtitle2">{delivery.title}</Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                              {delivery.description}
                            </Typography>
                          </Box>
                        }
                        sx={{ py: 3, flexGrow: 1, mr: 0 }}
                      />
                    </OptionStyle>
                  );
                })}
              </Stack>
            </RadioGroup>
          )}
        />
      </CardContent>
    </Card>
  );
}

const OptionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2.5),
  justifyContent: 'space-between',
  transition: theme.transitions.create('all'),
  border: `solid 1px ${theme.palette.divider}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));
