import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ProgressPage } from 'constants';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { upsertAgreements, fetchUserEventOrder, updateEventRegistrationOrder } from 'modules/registration/actions';
import {
  Box,
  Grid,
  Card,
  Button,
  IconButton,
  Divider,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';
import {
  formatDistanceValue,
  getConstantValue,
  getContactInfoByType,
  htmlToPlainText,
  removeDuplicateObject,
  sortByField,
} from 'utils';
import { LoadingButton } from '@mui/lab';
import Label from 'components/Label';
import Iconify from 'components/Iconify';
import { useRegistrationFormHook } from 'hooks/useRegistrationFormHook';
import { DynamicForm, FormProvider } from 'components/hook-form';
import { camelCase, capitalCase } from 'change-case';
import HighlightOff from '@mui/icons-material/HighlightOff';
import { contactInfoSelector, isWaiverFormSameForAllSelector } from 'modules/events/selector';
import GeneralWaiverFormModal from 'components/GeneralWaiverFormModal';
import { lineItemsCategoriesWaiverSelector, userRegistrationAttestations } from 'modules/registration/selector';
import { constantsSelector } from 'modules/app/selector';
import { AppConstants, Attestations, contactInfoTypes } from 'constants/index';
import RegistrationCartSummary from './RegistrationCartSummary';
import { RegistrationLineItemSummary } from './RegistrationLineItemSummary';
import SummaryAttestation from './SummaryAttestation';

export const ATTESTATIONS = (appConstants, data) => [
  {
    label: (
      <GeneralWaiverFormModal
        markDownValue={getConstantValue(appConstants, AppConstants.DATA_SHARING_AGREEMENT, data)}
        description={'Data Sharing Consent'}
        buttonOneText={'Agree'}
        otherSx={{ mt: 0 }}
      />
    ),
    value: 'DataSharingConsent',
    type: Attestations.DATA_SHARING_AGREEMENT,
    textVal: htmlToPlainText(getConstantValue(appConstants, AppConstants.DATA_SHARING_AGREEMENT, data) || ''),
    markDownValue: getConstantValue(appConstants, AppConstants.DATA_SHARING_AGREEMENT, data),
    isChecked: false,
    isRequired: true,
  },
  {
    label: (
      <GeneralWaiverFormModal
        markDownValue={appConstants?.[AppConstants.PLATFORM_AGREEMENT]}
        description={'Platform Agreement'}
        buttonOneText={'Agree'}
        otherSx={{ mt: 0 }}
      />
    ),
    markDownValue: appConstants?.[AppConstants.PLATFORM_AGREEMENT],
    value: 'PlatformAgreement',
    textVal: htmlToPlainText(appConstants?.[AppConstants.PLATFORM_AGREEMENT] || ''),
    type: Attestations.PLATFORM_AGREEMENT,
    isChecked: false,
    isRequired: true,
  },
];

export default function RegistrationSummary({
  registrationDetailLineItems,
  eventCategories,
  metricUsedIsKm,
  handleBackClick: handleBackClickProps = null,
  shouldBackToEvent: handleShouldBackToEvent = false,
  shouldShowAgreements = false,
  event,
}) {
  const dispatch = useDispatch();

  const { t: translate } = useTranslation();

  const [loading, setLoading] = useState(false);

  const waivers = useSelector(lineItemsCategoriesWaiverSelector);
  const isWaiverForSameForAll = useSelector(isWaiverFormSameForAllSelector);
  const appConstants = useSelector(constantsSelector);
  const contactInfo = useSelector(contactInfoSelector);

  const shouldBackToEvent = handleShouldBackToEvent && handleBackClickProps;

  const formattedWaivers = waivers?.reduce((acc, item, index) => {
    const value = camelCase(`waiver_${item?.distance}_${item?.title}`);
    const description = isWaiverForSameForAll
      ? `Event's ${capitalCase(item?.title || '')}`
      : capitalCase(`${item?.distance} ${item?.title}`);
    const reducedItem = {
      label: (
        <GeneralWaiverFormModal
          markDownValue={item?.body}
          description={description}
          index={index}
          buttonOneText={'Close'}
          otherSx={{ mt: 0 }}
        />
      ),
      value,
      isChecked: false,
      textVal: htmlToPlainText(item?.body),
      markDownValue: item?.body || '',
      type: value,
      isRequired: item?.isRequired,
    };
    return [...acc, reducedItem];
  }, []);

  const infoValues = contactInfo?.map((info) => info?.value || '');

  const appData = {
    provider: {
      providerCompanyName: appConstants?.[AppConstants.COMPANY_NAME],
      providerCompanyAddress: appConstants?.[AppConstants.COMPANY_ADDRESS],
      providerCompanySupportEmail: appConstants?.[AppConstants.COMPANY_SUPPORT_EMAIL],
      providerCompanyMobileNumber: appConstants?.[AppConstants.COMPANY_COMPANY_MOBILE_NUMBER],
    },
    event: {
      eventOrganizerName: capitalCase(event?.organization?.organizationName),
      eventName: capitalCase(event?.eventName),
      eventOrganizationEmail: getContactInfoByType(infoValues, contactInfoTypes.email),
      eventOrganizerPhoneNumber: getContactInfoByType(infoValues, contactInfoTypes.mobileNumber),
    },
  };

  const restructuredAttestations = [...ATTESTATIONS(appConstants, appData), ...formattedWaivers];

  const requiredAttestationTypes = restructuredAttestations?.map((attestation) => attestation?.type);

  const userAttestation = useSelector(userRegistrationAttestations({ attestationTypes: requiredAttestationTypes }));

  const numbersOfRequiredFields = restructuredAttestations.filter((item) => item.isRequired).length;

  const eventCategoryMapping = useCallback(
    (categoryId) => eventCategories.find((category) => category?.id === categoryId),
    [eventCategories]
  );

  const handleBackClick = useCallback(async () => {
    await dispatch(updateEventRegistrationOrder({ progress: ProgressPage.formFilling }));
  }, [dispatch]);

  const FormSchema = Yup.object().shape({
    attestations: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          label: Yup.string(),
          value: Yup.string(),
          isChecked: Yup.boolean(),
        })
      )
      .compact((v) => !v.isChecked)
      .min(numbersOfRequiredFields, translate('required-field'))
      .required(translate('required-field')),
  });

  // TODO this not function as expected to defaulted
  const formattedAttestations = restructuredAttestations?.reduce((acc, attestation) => {
    const toAddValue = userAttestation?.find(
      (attestationRestructured) => attestation?.type === attestationRestructured?.type
    );

    const toAdd = Boolean(toAddValue?.id && attestation);

    if (toAdd) {
      acc.push({ id: toAddValue?.id || '', ...attestation, isChecked: true });
    } else {
      acc.push(attestation);
    }

    return acc;
  }, []);

  const defaultValues = {
    attestations: formattedAttestations,
  };

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });
  const { watch, setValue, control } = methods;

  const checkedAttestations = watch('attestations')?.filter((item) => item.isChecked);

  const handleNextStep = useCallback(async () => {
    setLoading(true);
    const promises = [];
    promises.push(await dispatch(upsertAgreements(checkedAttestations)));
    promises.push(await dispatch(updateEventRegistrationOrder({ progress: ProgressPage.checkout })));

    await Promise.all(promises);
    setLoading(false);
  }, [setLoading, checkedAttestations]);

  // useEffect(() => {
  //   if (userAttestation?.length) {
  //     console.log('Reset');
  //     reset();
  //   }
  // }, [userAttestation]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              color="inherit"
              onClick={shouldBackToEvent ? handleBackClickProps : handleBackClick}
              startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}
            >
              Back
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          {registrationDetailLineItems?.map((lineItem, index) => (
            <RegistrationCategorySummary
              key={index}
              index={index}
              lineItemCategory={lineItem}
              lineItemsLength={registrationDetailLineItems?.length || 0}
              category={eventCategoryMapping(lineItem?.categoryId) || {}}
              metricUsedIsKm={metricUsedIsKm}
              shouldShowAgreements={shouldShowAgreements}
            />
          ))}

          <FormProvider methods={methods} onSubmit={() => {}}>
            <SummaryAttestation
              numbersOfRequiredFields={numbersOfRequiredFields}
              watch={watch}
              valueSetter={setValue}
              controller={control}
            />
          </FormProvider>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <RegistrationCartSummary sx={{ mb: 6 }} />

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              disabled={numbersOfRequiredFields !== checkedAttestations?.length}
              onClick={handleNextStep}
              sx={{ mb: 4 }}
            >
              Next Step
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export const RegistrationCategorySummary = ({
  lineItemCategory,
  category,
  index,
  lineItemsLength,
  metricUsedIsKm,
  ignoreActionButtons = false,
  shouldShowAgreements = true,
}) => {
  const [editEnabled, setEditEnabled] = useState(false); // Open first collapsable

  const handleEditableClick = useCallback(() => {
    setEditEnabled((prev) => !prev);
  }, []);

  const { onSubmitActionHandler, openForm, setOpenForm, registrantsData, categoryFormFields, categoryForms } =
    useRegistrationFormHook({
      lineItemCategory,
      category,
    });

  const categoryLabel = `${formatDistanceValue(metricUsedIsKm, category?.distance) || ''} - ${
    category?.categoryValue || 'Open'
  }`;

  return (
    <>
      {editEnabled && (
        <RegistrationFormModal
          handleEditableClick={handleEditableClick}
          editEnabled={editEnabled}
          lineItemsLength={lineItemsLength}
          index={index}
          openForm={openForm}
          onSubmitActionHandler={onSubmitActionHandler}
          registrantsData={registrantsData}
          categoryFormFields={categoryFormFields}
          categoryForms={categoryForms}
          setOpenForm={setOpenForm}
        />
      )}
      <Card sx={{ p: 3, mb: 3, position: 'relative', border: 1 }}>
        <RegistrantDetailsForm
          categoryLabel={categoryLabel}
          registrantsData={registrantsData}
          categoryForms={categoryForms}
          shouldShowAgreements={shouldShowAgreements}
          categoryFormFields={categoryFormFields}
        />

        {!ignoreActionButtons && (
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              position: { sm: 'absolute' },
              right: { sm: 24 },
              bottom: { sm: 24 },
            }}
          >
            <Button variant="outlined" size="small" onClick={handleEditableClick}>
              Edit
            </Button>
            <Box sx={{ mx: 0.5 }} />
          </Box>
        )}
      </Card>
    </>
  );
};

RegistrationCategorySummary.propTypes = {
  lineItemCategory: PropTypes.any,
  category: PropTypes.any,
  index: PropTypes.number,
};

export const RegistrationFormModal = ({
  handleEditableClick,
  editEnabled,
  lineItemsLength,
  index,
  onSubmitActionHandler,
  registrantsData,
  openForm,
  categoryFormFields,
  categoryForms,
  setOpenForm,
  title,
}) => {
  return (
    <Dialog fullWidth maxWidth="sm" open={editEnabled}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleEditableClick}>
        {title || 'Edit Registration'}
      </BootstrapDialogTitle>

      <Divider />

      <DialogContent>
        {categoryForms.map((form, ind) => (
          <Collapse in sx={{ width: 1 }} key={ind}>
            <DynamicForm
              formFields={categoryFormFields(form)}
              editEnable={editEnabled}
              isModal
              onCloseModal={handleEditableClick}
              currentLineItemIndexPlusOne={index + 1}
              registrantData={
                registrantsData?.find((registrant) => registrant?.formOrderNumber === form?.formOrderNumber) ?? {}
              }
              teamName={registrantsData?.find((registrant) => registrant?.teamName)?.teamName ?? ''}
              numberOfForms={categoryForms?.length}
              formNumber={form?.formOrderNumber}
              openForm={openForm}
              setOpenForm={setOpenForm}
              lineItemsLength={lineItemsLength}
              onSubmitActionHandler={onSubmitActionHandler}
              isPreview
            />
          </Collapse>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export const RegistrantDetailsForm = ({
  categoryLabel,
  registrantsData,
  categoryForms,
  categoryFormFields,
  shouldShowAgreements,
}) => {
  return (
    <>
      <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between">
        <Label color="info">{categoryLabel}</Label>
        {registrantsData?.[0]?.bibNumber && (
          <>
            <Label color="success">Bib: {registrantsData?.[0]?.bibNumber}</Label>
          </>
        )}
      </Stack>
      <Box sx={{ ml: 1, mt: 2 }}>
        {registrantsData?.map((registrant, index) => {
          const parsedUserData = registrant?.userData
            ? typeof registrant?.userData === 'string'
              ? JSON.parse(registrant?.userData)
              : registrant?.userData
            : {};
          const formItem = categoryForms?.find((form) => form?.formOrderNumber === registrant?.formOrderNumber);

          return (
            <>
              <RegistrationLineItemSummary
                key={index}
                userData={parsedUserData}
                formFields={categoryFormFields(formItem) || {}}
              />
              {shouldShowAgreements && registrant?.agreements?.items?.length && (
                <AgreementsLinkRender
                  agreements={removeDuplicateObject(sortByField(registrant?.agreements?.items), 'type')}
                />
              )}
            </>
          );
        })}
      </Box>
    </>
  );
};

export const AgreementsLinkRender = ({ agreements = [] }) => {
  return (
    <>
      <Divider variant="fullWidth" sx={{ m: 2 }} />
      {agreements?.map((agreement, index) => (
        <Stack spacing={1} direction="row" key={index}>
          <Typography variant="subtitle1">Agreement for</Typography>
          <GeneralWaiverFormModal
            markDownValue={JSON.parse(agreement?.agreement || '')?.htmlText}
            description={capitalCase(agreement?.type || '')}
            buttonOneText={'Close'}
            preTextValueProp={' '}
            otherSx={{ mt: 0, color: 'red' }}
          />
        </Stack>
      ))}
    </>
  );
};

export const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <HighlightOff />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
