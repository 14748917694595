import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useFormik, Form, FormikProvider } from 'formik';
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import { createAlert } from 'modules/alerts/actions';
import { verifySignUp } from 'modules/user/actions';

const emptyFunc = () => {};

export const ConfirmSignUpForm = ({
  userEmail,
  setTypedEmail,
  setIsEmailValid,
  isEmailVerified,
  isAuth,
  hasOwnRedirect = false,
  shouldRedirectTo = emptyFunc(),
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [reduxEmail] = useState(userEmail);
  const ConfirmSignUpSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    code: Yup.string().min(2, 'Too Short!').max(10, 'Too Long!').required('Code is required'),
  });

  if (isEmailVerified && userEmail && isAuth && !hasOwnRedirect) {
    dispatch(createAlert('CUSTOM_ERROR', 'Your email is already verified you cannot proceed to this page'));
    history.goBack();
  }

  const formik = useFormik({
    initialValues: {
      email: reduxEmail || '',
      code: '',
    },
    validationSchema: ConfirmSignUpSchema,
    onSubmit: async (values, setSubmitted) => {
      await dispatch(verifySignUp(values.email, values.code, hasOwnRedirect));
      shouldRedirectTo();
      setSubmitted(false);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, isValid, values, handleBlur, setFieldValue } =
    formik;

  useEffect(() => {
    if (values.email && !errors.email) {
      setIsEmailValid(true);
      setTypedEmail(values.email || '');
    } else {
      setIsEmailValid(false);
      setTypedEmail('');
    }
  }, [values, errors]);

  useEffect(() => {
    if (userEmail) {
      setFieldValue('email', userEmail);
    }
  }, [userEmail]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            autoFocus={!reduxEmail || !userEmail}
            disabled={isAuth}
            value={values.email}
            {...getFieldProps('email')}
            // onBlur={handleEmailTextBoxOnBlur()}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="code"
            type="number"
            label="Enter Code"
            autoFocus={Boolean(reduxEmail || userEmail)}
            {...getFieldProps('code')}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid}
          >
            Confirm Sign Up
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

ConfirmSignUpForm.propTypes = {
  userEmail: PropTypes.string,
  setTypedEmail: PropTypes.func,
  setIsEmailValid: PropTypes.func,
  isEmailVerified: PropTypes.bool,
  isAuth: PropTypes.bool,
};
