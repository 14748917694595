import { API } from 'aws-amplify';
import { store } from 'store';
import { AppConstants, NeedsCTXQueries } from 'constants/common';

export default {
  graphql: async (request) =>
    new Promise((resolve, reject) =>
      (async () => {
        const requestQueryNeedsCTX = NeedsCTXQueries.some((item) => request?.query?.includes(item));
        return await API.graphql({
          ...request,
          variables: { ...request?.variables, ...(requestQueryNeedsCTX ? ctxConstructor() : {}) },
        })
          .then((response) => resolve(response))
          .catch((err) => reject(err));
      })()
    ),
};

const ctxConstructor = () => {
  const state = store.getState();
  const userId = state?.user?.data?.userId;
  const userEmail = state?.user?.data?.email;
  const firstName = state?.user?.data?.firstName;
  const lastName = state?.user?.data?.lastName;
  const adminEmail = state?.app?.admin?.email;
  const isAdminPortal = state?.app?.constants?.[AppConstants.ADMIN_HOSTNAME] === window.location.hostname;
  const fullName = `${firstName || 'none'} ${lastName || 'none'}`;

  const isAdminNavigation = adminEmail && isAdminPortal;
  return {
    ctx: {
      user: {
        userId: userId || 'none',
        userEmail: userEmail || 'none',
        fullName,
        ...(isAdminNavigation && { adminEmail }),
      },
    },
  };
};
