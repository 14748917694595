import { useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
  Paper,
} from '@mui/material';
import Iconify from 'components/Iconify';
import useResponsive from 'hooks/useResponsive';
import { formatDistanceValue } from 'utils';

const columnsValue = ['Type', 'Desc', 'Prizes'];

export default function CategoriesPrizes({ event }) {
  const { categories, metricUsedIsKm } = event;

  const orderOfDistance = useMemo(
    () => categories?.items?.sort((a, b) => b?.distance - a?.distance) || [],
    [categories]
  );

  const rowsValue = (prizes) => {
    // TODO: sort by price type
    // const prizeType = prizes.map((prize, index) => {
    //   return prize
    // });

    return (
      prizes.map((prize, index) => ({
        type: prize?.prizeCategory?.prizeCategoryType,
        value: prize?.prizeCategory?.prizeCategoryValue,
        prize: prize?.prizeValue
          ?.map(
            (item) => ` ${item?.prizeAmountType || ''} ${item?.prizeAmountValue ? ` - ${item?.prizeAmountValue}` : ''}`
          )
          .join(`, `),
      })) ?? []
    ).sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
  };
  return (
    <div>
      {orderOfDistance.length > 0 &&
        orderOfDistance.map((category, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<Iconify icon={'bx:down-arrow'} />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <Typography sx={{ width: '33%', flexShrink: 0, color: 'red' }}>
                <b>{formatDistanceValue(metricUsedIsKm, category?.distance)}</b>
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>
                <b>{category?.categoryValue || 'Open'}</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PrizeTable rows={rowsValue(category?.categoryPrizes || [])} columnHeaders={columnsValue} />
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
}

const PrizeTable = ({ rows, columnHeaders }) => {
  const isDesktop = useResponsive('up', 'sm');
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        {!isDesktop && <caption>Scroll to the left to see values</caption>}
        <TableHead>
          <TableRow>
            {(columnHeaders ?? [])?.map((column, index) => (
              <TableCell key={index}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.length ? (
            (rows ?? []).map((row, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.type}
                </TableCell>
                <TableCell align="left">{row.value}</TableCell>
                <TableCell align="left">{row.prize}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                Nothing to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
