import { legacy_createStore as createStore, applyMiddleware } from 'redux';
// TODO remove create-react-router soon
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import reduxThunkFsa from 'redux-thunk-fsa';
import promiseMiddleware from 'redux-promise';
import createRootReducer from './rootReducers';

const preloadedState = {};

const history = createBrowserHistory();
const middlewares = [routerMiddleware(history), reduxThunkFsa, promiseMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);
// const enhancers = [middlewareEnhancer, monitorReducersEnhancer] // search for this on web this should appear in redux.js.org
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(createRootReducer(history), preloadedState, composedEnhancers);

export { store, history };
