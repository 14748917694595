import { useMemo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import { formatDistanceValue } from 'utils';
import EventImagesCarousel from './EventImagesCarousel';

export default function EventRoutes({ event }) {
  const { categories, metricUsedIsKm, hasSameRouteForAllCat, routeImageURL } = event;

  const orderOfDistance = useMemo(
    () => categories?.items?.sort((a, b) => b?.distance - a?.distance) || [],
    [categories]
  );

  const imagesInfo = useMemo(() => {
    return hasSameRouteForAllCat ? routeImageURL?.items?.map((item) => item) : [];
  }, [routeImageURL]);

  const imagesList = categories?.items?.reduce((acc, category) => {
    const items = category?.routeImageURL?.items?.length ? category?.routeImageURL?.items?.map((item) => item) : [];
    return [...acc, ...items];
  }, []);

  return (
    <>
      {!hasSameRouteForAllCat ? (
        <div>
          {orderOfDistance.length > 0 &&
            orderOfDistance.map((category, index) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<Iconify icon={'bx:down-arrow'} />}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                >
                  <Typography sx={{ width: '33%', flexShrink: 0, color: 'red' }}>
                    <b>{formatDistanceValue(metricUsedIsKm, category?.distance)}</b>
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    <b>Route</b>
                  </Typography>
                </AccordionSummary>
                {category?.routeImageURL?.items.length ? (
                  <AccordionDetails>
                    <EventImagesCarousel
                      key={index}
                      shouldShowTabSizeImages={false}
                      imagesInfo={category?.routeImageURL?.items || []}
                      title={''}
                      description={''}
                    />
                  </AccordionDetails>
                ) : (
                  <AccordionDetails>No Route Image to show for this category</AccordionDetails>
                )}
              </Accordion>
            ))}
        </div>
      ) : (
        <EventImagesCarousel
          shouldShowTabSizeImages={false}
          imagesInfo={imagesList ?? []}
          title={''}
          description={''}
        />
      )}
    </>
  );
}
