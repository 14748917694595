import PropTypes from 'prop-types';
import { useState } from 'react';
import { removeEmptyFieldsInObj } from 'utils/index';
import { useLocation, useParams, generatePath } from 'react-router-dom';
import { List, Collapse } from '@mui/material';
import { NavListItemWrapper } from './NavListItemWrapper';
import { NavItemRoot, NavItemSub } from './NavItem';
import { getActive } from '..';

// ----------------------------------------------------------------------
// TODO transfer to utils

const someRequiredFieldsMissing = (path, params) => {
  const fieldsRequired = path
    .split('/')
    ?.filter((field) => field.includes(':') && !field?.includes('?'))
    ?.map((field) => field.replace(':', ''));
  const paramsKeys = Object.keys(params);
  return fieldsRequired?.length ? fieldsRequired?.map((field) => paramsKeys?.includes(field))?.includes(false) : false;
};
export const generatePathHelper = (path, params, generatePath) => {
  const reconstructedParams = removeEmptyFieldsInObj(params) || {};
  const hasMissingRequiredFieldsInParams = path?.includes(':')
    ? someRequiredFieldsMissing(path, reconstructedParams)
    : false;
  const generatedPath = hasMissingRequiredFieldsInParams
    ? '#'
    : path?.includes(':') && Object.keys(reconstructedParams)?.length
    ? generatePath(path, { ...reconstructedParams })
    : path;
  return generatedPath;
};

NavListRoot.propTypes = {
  list: PropTypes.object,
  isCollapse: PropTypes.bool,
};

export function NavListRoot({ list, isCollapse }) {
  const { pathname } = useLocation();
  const params = useParams();
  const generatedPath = generatePathHelper(list.path, params, generatePath);
  const active = getActive(generatedPath, pathname);

  const hasActiveChild =
    list?.children?.map((child) => generatePathHelper(child?.path || '', params, generatePath))?.includes(pathname) ||
    false;

  const [open, setOpen] = useState(active || hasActiveChild);

  return (
    <NavListItemWrapper
      list={list}
      isCollapse={isCollapse}
      active={active}
      hasActiveChild={hasActiveChild}
      setOpen={setOpen}
      open={open}
    />
  );
}

// ----------------------------------------------------------------------

// eslint-disable-next-line
export function NavItemWithSubItem({ list, isCollapse, active, open, setOpen, hasActiveChild, handleOnClickNavItem }) {
  return (
    <>
      <NavItemRoot
        item={list}
        isCollapse={isCollapse}
        active={active || hasActiveChild}
        open={open}
        onOpen={() => setOpen(!open)}
      />

      {!isCollapse && (
        // eslint-disable-next-line
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {(list.children || []).map((item) => (
              <NavListSub
                key={item.title + item.path}
                list={item}
                handleOnClickNavItem={handleOnClickNavItem}
                hasActiveChild={hasActiveChild}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
}

// eslint-disable-next-line
NavListSub.propTypes = {
  list: PropTypes.object,
};

function NavListSub({ list, handleOnClickNavItem, hasActiveChild }) {
  const { pathname } = useLocation();

  const params = useParams();
  // TODO donot be too dependent on params
  const generatedPath = generatePathHelper(list.path, params, generatePath);
  const active = getActive(generatedPath, pathname);

  const [open, setOpen] = useState(active || hasActiveChild);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub item={list} onOpen={() => setOpen(!open)} open={open} active={active} />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => (
              <NavItemSub key={item.title + item.path} item={list} handleOnClickNavItem={handleOnClickNavItem} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={list} handleOnClickNavItem={handleOnClickNavItem} />;
}
