import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Typography, Divider } from '@mui/material';
import useResponsive from 'hooks/useResponsive';
import { formatDistanceValue, fDate, sortCategoriesByDistance } from 'utils';
import Markdown from 'components/Markdown';
import EventImagesCarousel from './EventImagesCarousel';

EventOverview.propTypes = {
  event: PropTypes.object,
};

export default function EventOverview({ event }) {
  const {
    eventName: title,
    categories,
    metricUsedIsKm,
    addressLine1,
    barangay,
    city,
    state,
    region,
    country,
    dateOfEvent,
    eventInstance,
    eventType,
    additionalNotes = '',
  } = event;

  const isDesktop = useResponsive('up', 'sm');

  const sortedCategoriesByDistance = sortCategoriesByDistance(categories);

  const imagesList = categories?.items
    ?.reduce((acc, category) => {
      const items = category?.raceKits?.items?.length
        ? category?.raceKits?.items?.map((item) => item?.images?.items)?.filter((item) => item)
        : [];
      return [...acc, ...items];
    }, [])
    // Reduce into one array
    ?.reduce((acc, item) => [...acc, ...item], []);

  return (
    <>
      <StyledTypography variant={isDesktop ? 'h2' : 'h3'} component="div" gutterBottom>
        {title}
      </StyledTypography>
      <StyledTypography variant="p" component="div" sx={{ ml: 3 }}>
        <b>Date of Event - </b>
        {fDate(dateOfEvent)}
      </StyledTypography>
      <StyledTypography variant="p" component="div" sx={{ ml: 3 }}>
        <b>Location - </b>
        {`${addressLine1 || ''} ${barangay || ''} ${city || ''}, ${state || ''}, ${region || ''} ${country || ''}`}
      </StyledTypography>
      {eventType && eventInstance && (
        <>
          <StyledTypography variant="p" component="div" sx={{ ml: 3 }}>
            <b>Type - </b>
            {`${eventInstance} - ${eventType}`}
          </StyledTypography>{' '}
        </>
      )}

      {categories?.items?.length > 0 && (
        <>
          <StyledTypography variant={isDesktop ? 'h4' : 'h5'} component="div">
            {categories?.items?.length === 1 ? 'Category' : 'Categories'}
          </StyledTypography>
          <StyledOrderList>
            {sortedCategoriesByDistance?.map((category, index) => (
              <StyledListItem key={index}>
                <b>{`${formatDistanceValue(metricUsedIsKm, category?.distance)}`}</b>
                {category.subCategory.length > 0 ? (
                  <ol>
                    {category.subCategory.map((item, index) => (
                      <StyledListItemSubCat key={index * 2}>{isDesktop ? <b>{item}</b> : item}</StyledListItemSubCat>
                    ))}
                  </ol>
                ) : (
                  ''
                )}
              </StyledListItem>
            ))}
          </StyledOrderList>
        </>
      )}

      <Divider sx={{ mx: 2 }} />
      <Box sx={{ p: { xs: 1, md: 3 } }}>
        <Markdown children={additionalNotes} />
      </Box>
      {/* {rulesAndRegulations?.items?.length > 0 &&
        rulesAndRegulations?.items?.map((mockData, index) => (
          <Box key={index} sx={{ p: { xs: 1, md: 3 } }}>
            <Markdown children={eventShortDescription} />
          </Box>
        ))} */}

      <StyledSpacer />
    </>
  );
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(3),
  },
}));

const StyledSpacer = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(30),
  },
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(20),
  },
}));

const StyledOrderList = styled('ol')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    marginLeft: theme.spacing(5),
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(8),
  },
}));

const StyledListItem = styled('li')(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const StyledListItemSubCat = styled('li')(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    marginLeft: theme.spacing(3),
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(6),
  },
}));
