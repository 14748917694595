import React from 'react';
import { Route, matchPath } from 'react-router-dom';
import { Redirect } from 'react-router';
import { getPath, whiteListedRoutes } from 'utils/index';
import { Routes } from '../constants';

export const NonAuthRoute = ({
  component: Component,
  isEmailConfirmed,
  userAuth,
  publicRoute,
  path,
  ...routeProps
}) => {
  // 1. Render requested route when user is unauthenticated
  if (!userAuth?.isAuth || publicRoute || (userAuth?.isAuth && !isEmailConfirmed && whiteListedRoutes.includes(path))) {
    return <Route {...routeProps} render={() => Component} />;
  }

  const { location } = routeProps;
  const redirectUri = location.state && location.state.prevRoute ? location.state.prevRoute : getPath(Routes.EVENTS);
  const match = matchPath(redirectUri, {
    path: getPath(Routes.HOME),
    exact: true,
  });

  // if home route slug exist we should keep deep link as it is
  const isRedirectToHome = Boolean(match && !match.params.slug);

  // 2. Redirect to feed if the user is logged in
  return <Redirect to={{ pathname: isRedirectToHome ? getPath(Routes.EVENTS) : redirectUri }} />;
};
