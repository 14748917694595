import PropTypes from 'prop-types';
import { generatePath, useHistory } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import { Stack, Button, Divider, Typography } from '@mui/material';
import Label from 'components/Label';
import Iconify from 'components/Iconify';
// import SocialsButton from 'components/SocialsButton';
import {
  availabilityChecker,
  emptyFunction,
  fDate,
  getPath,
  statusChecker,
  titleUrlFormatter,
  totalSlotsAvailableCalculator,
} from 'utils';
import { EVENT_STATUS, ProgressPage, Routes } from 'constants/index';
import { useCallback } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.up(1368)]: {
    padding: theme.spacing(5, 8),
  },
}));

// ----------------------------------------------------------------------

EventDetailsSummary.propTypes = {
  event: PropTypes.object.isRequired,
};

export default function EventDetailsSummary({ event, isPreview, hasResults, ...other }) {
  const { push: historyPush } = useHistory();
  const theme = useTheme();

  const {
    // list
    registrationEnd,
    registrationStart,
    dateOfEvent,
    categories,
    slotsAvailabilityIsSameForAllCategories,
    eventName,
    addressLine1,
    barangay,
    city,
    state,
    region,
    country,
    id: eventId,
  } = event;

  const handleRedirectToRegistrationFlow = useCallback(() => {
    historyPush({
      pathname: generatePath(getPath(Routes.EVENTREGISTRATION), {
        title: titleUrlFormatter(eventName || ''),
        id: eventId,
        progressPage: ProgressPage.numberOfRegistrants,
      }),
    });
  }, [eventName, eventId]);

  const handleRedirectToResults = useCallback(() => {
    historyPush({
      pathname: generatePath(getPath(Routes.EVENT_RACE_RESULTS), {
        eventId,
      }),
    });
  }, [eventName, eventId]);

  const eventStatus = statusChecker(registrationEnd, dateOfEvent);

  const totalSlotsAvailable = totalSlotsAvailableCalculator(categories?.items, slotsAvailabilityIsSameForAllCategories);

  const {
    isAvailable: isEventAvailable,
    registrationButtonText,
    status,
  } = availabilityChecker(event, undefined, undefined, { hasResults });

  const isDoneAndHasResults = status === EVENT_STATUS.DONE && hasResults;

  const disableSubmitButton = !isEventAvailable && !isDoneAndHasResults;

  const location = [addressLine1, barangay, city, state, region, country].filter((item) => item);

  const handleButtonClick = (() => {
    if (isPreview) {
      return emptyFunction;
    }

    if (isDoneAndHasResults) {
      return handleRedirectToResults;
    }

    return handleRedirectToRegistrationFlow;
  })();

  return (
    <RootStyle {...other}>
      <Label
        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
        color={eventStatus.color}
        sx={{ textTransform: 'uppercase' }}
      >
        {eventStatus.value}
      </Label>

      {isEventAvailable && (
        <Typography
          variant="overline"
          sx={{
            mt: 2,
            mb: 1,
            display: 'block',
            color: 'info.main',
          }}
        >
          {`Slots Available: ${totalSlotsAvailable}`}
        </Typography>
      )}

      <Typography variant="h5" paragraph sx={{ mt: isEventAvailable ? 2 : 1 }}>
        {eventName}
      </Typography>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Stack direction="column" alignItems="left" justifyContent="space-between" sx={{ my: 3 }}>
        <Typography variant="subtitle1" sx={{ mt: 0.5, fontWei: 400 }}>
          <Iconify icon={'solar:calendar-outline'} /> Date of Event:
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 0.5 }}>
          {fDate(dateOfEvent || '')}
        </Typography>
      </Stack>

      <Stack direction="column" alignItems="left" justifyContent="space-between" sx={{ my: 3 }}>
        <Typography variant="subtitle1" sx={{ mt: 0.5, fontWei: 400 }}>
          <Iconify icon={'basil:location-outline'} /> Location:
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 0.5 }}>
          {location?.length ? location?.join(', ') : 'Not indicated'}
        </Typography>
      </Stack>

      <Stack direction="column" alignItems="left" justifyContent="space-between" sx={{ my: 3 }}>
        <Typography variant="subtitle1" sx={{ mt: 0.5, fontWei: 400 }}>
          <Iconify icon={'solar:calendar-outline'} /> Registration Date:
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 0.5 }}>
          {`${fDate(registrationStart)} to ${fDate(registrationEnd)}`}
        </Typography>
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />

      <Stack direction="row" spacing={2} sx={{ mt: 5 }}>
        <Button
          fullWidth
          disabled={disableSubmitButton}
          size="large"
          color="info"
          variant="contained"
          startIcon={isEventAvailable ? <Iconify icon={'ic:round-add-shopping-cart'} /> : null}
          onClick={handleButtonClick}
          sx={{ whiteSpace: 'nowrap' }}
        >
          {registrationButtonText}
        </Button>
      </Stack>

      {/* <Stack alignItems="center" sx={{ mt: 3 }}>
          <SocialsButton initialColor />
        </Stack> */}
    </RootStyle>
  );
}
