import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, generatePath, useHistory } from 'react-router-dom';
import { Box, Card, Container, Typography, Tab, Grid, CardHeader, CardContent, Divider } from '@mui/material';
import Iconify from 'components/Iconify';
import useSettings from 'hooks/useSettings';
import useIsMountedRef from 'hooks/useIsMountedRef';
import Page from 'components/Page';
import { Routes, S3ImageSizes, ReturnType, PriceType, QueryParams, EVENT_STATUS } from 'constants/index';
import {
  availabilityChecker,
  emptyFunction,
  getActiveLatestPrice,
  getPath,
  sortDistances,
  titleUrlFormatter,
  totalSlotsAndRegistrants,
} from 'utils';
import qs from 'qs';
import { SkeletonEvent } from 'components/skeleton';
import {
  EventOverview,
  CategoriesInfo,
  CategoryKits,
  CategoriesPrizes,
  EventRoutes,
  OrganizersContactInfo,
} from 'sections/@dashboard/event';
import GeneralModal from 'components/GeneralModal';
import EventImagesCarousel from 'sections/@dashboard/event/EventImagesCarousel';
import { useSelector, useDispatch } from 'react-redux';
import {
  eventCategoriesResultsFilesSelector,
  eventCategoriesSelector,
  eventDistancesSelector,
  eventImagesSelector,
  eventSlotsAvailabilityIsSameForAllCategoriesSelector,
} from 'modules/events/selector';
import EventDetailsSummary from 'sections/@dashboard/event/EventDetailsSummary';
import moment from 'moment-timezone';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { capitalCase, snakeCase } from 'change-case';
import useResponsive from 'hooks/useResponsive';
import {
  createDraftRegistration,
  increaseUserChoiceCategoryById,
  resetRegistration,
} from 'modules/registration/actions';
import { registrationDetailsSelector, registrationProgressSelector } from 'modules/registration/selector';
import ModalResetProgress from 'sections/@dashboard/event-signup/ModalResetProgress';
import { isNotAuthenticatedOrVerifiedSelector } from 'modules/user/selector';
import useAuthModalHook from 'hooks/useAuthModalHook';

// ----------------------------------------------------------------------

const ACCOUNT_TABS = (event) => [
  {
    value: 'Overview',
    icon: <Iconify icon={'openmoji:overview'} width={20} height={20} />,
    component: <EventOverview event={event} />,
  },
  {
    value: 'Categories_Information',
    icon: <Iconify icon={'carbon:category-new-each'} width={20} height={20} />,
    component: <CategoriesInfo event={event} />,
  },
  {
    value: 'Kits',
    icon: <Iconify icon={'lucide:package-plus'} width={20} height={20} />,
    component: <CategoryKits event={event} />,
  },
  {
    value: 'Prizes',
    icon: <Iconify icon={'mdi:podium'} width={20} height={20} />,
    component: <CategoriesPrizes event={event} />,
  },
  {
    value: 'Routes_Information', // Neglect this one if event have same for all categories
    icon: <Iconify icon={'gis:route'} width={20} height={20} />,
    component: <EventRoutes event={event} />,
  },
  {
    value: 'Contact_Information',
    icon: <Iconify icon={'fluent:contact-card-20-regular'} width={20} height={20} />,
    component: <OrganizersContactInfo event={event} />,
  },
];

// ----------------------------------------------------------------------

export const EventPage = ({
  event,
  getEventById,
  registrationIsFetched,
  fetchUserEventOrder,
  isPreview = false,
  orgEventId = '',
}) => {
  const { push: historyPush } = useHistory();

  const { id: userEventId, title: eventName } = useParams();

  const eventId = orgEventId || userEventId;

  const isComponentMounted = useRef(true);

  const { themeStretch } = useSettings();

  const isMountedRef = useIsMountedRef();

  const [error] = useState(null);

  const [openModalNotPublished, setOpenModalNotPublished] = useState(false);

  const imagesList = useSelector(eventImagesSelector);

  const eventResultsFiles = useSelector(eventCategoriesResultsFilesSelector);

  const eventImages = event?.mainEventImage || [];
  const mainEventImage = eventImages?.items?.[0] || {}; // profile image should be one

  const eventImage = { urlImage: mainEventImage?.urlImage };

  const imagesToDisplay = imagesList?.reduce(
    (acc, image = {}) => [
      ...acc,
      {
        ...image,
        size: S3ImageSizes._500x500,
      },
    ],
    []
  );

  imagesToDisplay.unshift(eventImage);

  useEffect(() => {
    (async () => {
      if ((!event || event?.id !== eventId) && isMountedRef.current) {
        const response = await getEventById(eventId);
        if (response?.payload?.error) {
          setOpenModalNotPublished((prev) => !prev);
        }
      }
      if (isMountedRef.current && isComponentMounted.current && !isPreview) {
        await fetchUserEventOrder(eventId);
      }
    })();
    return () => {
      isComponentMounted.current = false;
    };
  }, [event, isMountedRef, isComponentMounted, isPreview]);

  const handleGotoEvents = () => {
    setOpenModalNotPublished((prev) => !prev);
    historyPush({
      pathname: getPath(Routes.EVENTS),
    });
  };

  const displayEvent = event && (registrationIsFetched || isPreview);

  return (
    <Page title="Event Details">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        {openModalNotPublished && (
          <GeneralModal
            open={openModalNotPublished}
            id={`unpublish-modal-display-event-id`}
            title="Event is not yet published"
            message="Event is not yet available as of the moment please contact the organizer"
            buttonOneText="Goto Events"
            buttonOneClick={handleGotoEvents}
          />
        )}
        {displayEvent ? (
          <>
            <Grid container>
              <Grid item xs={12} md={6} lg={7}>
                <EventImagesCarousel
                  shouldShowTabSizeImages
                  imagesInfo={imagesToDisplay || []}
                  title={''}
                  description={''}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={5}>
                <EventDetailsSummary event={event} isPreview={isPreview} hasResults={eventResultsFiles?.length} />
              </Grid>
            </Grid>

            <CategoryCards event={event} isPreview={isPreview} results={eventResultsFiles} />

            <EventInformation event={event} isPreview={isPreview} />
          </>
        ) : (
          <SkeletonEvent />
        )}

        {error && <Typography variant="h6">404 {error}!</Typography>}

        {/* <BlogPostRecent posts={recentPosts} /> */}
      </Container>
    </Page>
  );
};

const CategoryCards = ({ event, isPreview, results }) => {
  const distances = useSelector(eventDistancesSelector);

  const sortedDistancesAsc = sortDistances(distances);

  const isDesktop = useResponsive('up', 'sm');

  const desktopSpacing = sortedDistancesAsc?.length > 3 && isDesktop ? 2 : 3;

  return (
    <Grid container spacing={desktopSpacing} justifyContent="center" sx={{ my: 8 }}>
      {sortedDistancesAsc?.map(({ categoryTypeAndValue, category }, ind) => (
        <CategoryCard
          category={category}
          categoryTypeAndValue={categoryTypeAndValue}
          event={event}
          key={ind}
          isPreview={isPreview}
          results={results?.find((result) => result?.categoryId === category?.id)}
        />
      ))}
    </Grid>
  );
};

const CategoryCard = ({ event, category, categoryTypeAndValue, isPreview = false, results }) => {
  const { push: historyPush } = useHistory();
  const [openBackModalForReset, setOpenBackModalForReset] = useState(false);

  const dispatch = useDispatch();
  const categories = useSelector(eventCategoriesSelector);
  const registrationDetails = useSelector(registrationDetailsSelector);
  const isSlotSameForAllCategories = useSelector(eventSlotsAvailabilityIsSameForAllCategoriesSelector);
  const registrationProgress = useSelector(registrationProgressSelector);
  const isNotAuthenticatedOrVerified = useSelector(isNotAuthenticatedOrVerifiedSelector);

  const { eventName, id: eventId } = event;

  const [loading, setLoading] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const prices = category?.regPrice?.prices;
  let activeLatestPrice = getActiveLatestPrice(prices, ReturnType.ValueWithValueValidity, true);

  const regularPrice = activeLatestPrice?.find((price) => price?.priceType === PriceType.REGULAR_PRICE);

  // remove regular price in array
  activeLatestPrice = activeLatestPrice?.filter((price) => price?.priceType !== PriceType.REGULAR_PRICE);

  // reverse order
  activeLatestPrice = (activeLatestPrice || [])?.reverse();

  // have it as first element
  activeLatestPrice.unshift(regularPrice);

  const totalSlotsAvailable =
    totalSlotsAndRegistrants(categories, isSlotSameForAllCategories, category?.id)?.availableSlots || 0;

  const {
    isAvailable: isCategoryAvailable,
    registrationButtonText,
    status,
  } = availabilityChecker(event, undefined, category?.id, { hasResults: Boolean(results?.categoryId) });

  const hasResults = results && results?.categoryId && status === EVENT_STATUS.DONE;

  const shouldEnableButton = isCategoryAvailable || hasResults;

  const handleRedirectToOldRegistration = useCallback(() => {
    const queryParams = { [QueryParams.BACK_TO_EVENT]: true };
    const redirectPath = `${generatePath(getPath(Routes.EVENTREGISTRATION), {
      id: eventId,
      title: titleUrlFormatter(eventName),
      progressPage: registrationProgress,
    })}`;

    historyPush({
      pathname: redirectPath,
      search: qs.stringify(queryParams),
    });
  }, [historyPush, registrationProgress, eventId, eventName]);

  const handlePreviewResults = useCallback(() => {
    const queryParams = { [QueryParams.CATEGORY]: snakeCase(categoryTypeAndValue || '') };
    const redirectPath = `${generatePath(getPath(Routes.EVENT_RACE_RESULTS), {
      eventId,
    })}`;

    historyPush({
      pathname: redirectPath,
      search: qs.stringify(queryParams),
    });
  }, [historyPush, categoryTypeAndValue, eventId]);

  const handleModalState = useCallback(() => {
    setOpenBackModalForReset((prev) => !prev);
  }, []);

  const createDraftOrderWithRedirect = useCallback(async () => {
    setLoading(true);
    await dispatch(resetRegistration());
    dispatch(increaseUserChoiceCategoryById(category?.id));
    await dispatch(createDraftRegistration(eventId, { hasOwnRedirect: true }));
    setLoading(false);
  }, [category, setLoading, dispatch, eventId]);

  const handleClickRegister = useCallback(async () => {
    if (registrationDetails?.id) {
      setOpenBackModalForReset((prev) => !prev);
    } else {
      await createDraftOrderWithRedirect();
    }
  }, [registrationDetails]);

  const handleAuthBackButton = () => {
    setOpenAuth((prev) => !prev);
  };

  const getOnHandleClick = (() => {
    if (isPreview) {
      return emptyFunction;
    }

    if (hasResults) {
      return handlePreviewResults;
    }
    if (isNotAuthenticatedOrVerified) {
      return handleAuthBackButton;
    }

    return handleClickRegister;
  })();

  const handleSignInRedirect = () => {
    handleAuthBackButton();
    handleClickRegister();
  };

  return (
    <>
      {useAuthModalHook({
        handleBackButton: handleAuthBackButton,
        openProp: openAuth,
        shouldAutoRun: false,
        signInRedirect: handleSignInRedirect,
      })}
      {openBackModalForReset && (
        <ModalResetProgress
          onClose={handleModalState}
          open={openBackModalForReset}
          handleSubmit={createDraftOrderWithRedirect}
          button2="Continue Registering New"
          dialogTitle={'Pending Registration detected'}
          dialogContentText={`We found out you have a pending registration for this event would you like to delete it and continue registering with \n\n${categoryTypeAndValue} category?`} // TODO check with the pending registrants
          button3="Check Pending"
          handleButtonThreeClick={handleRedirectToOldRegistration}
          button2Loading={loading}
          disableButton1={loading}
          disableButton3={loading}
        />
      )}
      <Grid item xs={12} sm={4} sx={{ ml: 2, mr: 2 }} minHeight={10}>
        <Card>
          <CardHeader title={categoryTypeAndValue || ''} />
          <CardContent>
            {activeLatestPrice?.map((price, index, arr) => (
              <p key={index}>
                {`${price?.priceType}`}:{' '}
                {index + 1 !== arr?.length ? (
                  <del>{price?.type}</del>
                ) : (
                  <span color="red">
                    <b>{price?.type}</b>
                  </span>
                )}
                {index + 1 === arr?.length && (
                  <span fontSize="1em">{` (Until ${moment(price?.validTo || '').format(
                    'MMMM D, YYYY h:mm a'
                  )}) `}</span>
                )}
              </p>
            ))}

            <Divider sx={{ borderStyle: 'dashed', m: 2 }} />

            {isCategoryAvailable && <p>{`Slots Available: ${totalSlotsAvailable}`}</p>}

            <LoadingButton
              variant="contained"
              color="primary"
              sx={{ mt: 2, p: 1 }}
              fullWidth
              onClick={getOnHandleClick}
              disabled={!shouldEnableButton}
              loading={loading}
            >
              {registrationButtonText}
            </LoadingButton>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

const EventInformation = ({ event, isPreview }) => {
  const [value, setValue] = useState('1');

  const Tabs = ACCOUNT_TABS(event);

  const isDesktop = useResponsive('up', 'sm');

  const isPreviewAndMobile = isPreview && isDesktop;

  return (
    <Card sx={{ m: isPreviewAndMobile ? 2 : 0 }}>
      <TabContext value={value}>
        <Box sx={{ bgcolor: 'background.neutral' }}>
          <TabList
            onChange={(e, value) => setValue(value)}
            allowScrollButtonsMobile
            variant="scrollable"
            sx={{ pl: isDesktop ? 3 : 0 }}
          >
            {Tabs.map((tab, index) => (
              <Tab disableRipple value={`${index + 1}`} label={`${capitalCase(tab?.value)}`} />
            ))}
          </TabList>
        </Box>

        <Divider />

        {Tabs.map((tab, index) => (
          <>
            <TabPanel value={`${index + 1}`}>
              <Box sx={{ p: 3 }}>
                <>{tab.component}</>
                {/* <CollapsibleTable /> */}
                {/* <ProductSpecificationSheet /> */}
              </Box>
            </TabPanel>
          </>
        ))}
      </TabContext>
    </Card>
  );
};

// const CollapsibleTable = ({ data }) => {
//   const [expandedRow, setExpandedRow] = useState(null);

//   const handleRowClick = (rowIndex) => {
//     setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
//   };

//   return (
//     <TableContainer component={Paper}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>Dessert (100g serving)</TableCell>
//             <TableCell>Calories</TableCell>
//             <TableCell>Fat (g)</TableCell>
//             <TableCell>Carbs (g)</TableCell>
//             <TableCell>Protein (g)</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {dessertData.map((row, index) => (
//             <React.Fragment key={index}>
//               <TableRow onClick={() => handleRowClick(index)}>
//                 <TableCell>{row.name}</TableCell>
//                 <TableCell>{row.calories}</TableCell>
//                 <TableCell>{row.fat}</TableCell>
//                 <TableCell>{row.carbs}</TableCell>
//                 <TableCell>{row.protein}</TableCell>
//               </TableRow>
//             </React.Fragment>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// };

// // Example data (you can replace this with your actual data)
// const dessertData = [
//   {
//     name: 'Frozen yoghurt',
//     calories: 159,
//     fat: 6,
//     carbs: 24,
//     protein: 1,
//     additionalDetails: 'Frozen yoghurt details...',
//   },
//   // Add more dessert items here...
// ];

// const ProductSpecificationSheet = () => {
//   return (
//     <Box>
//       <Typography variant="h6">Specifications</Typography>
//       <ul>
//         <li>Manufacturer: Your Manufacturer Name</li>
//         <li>Serial Number: 358607726380311</li>
//         <li>Ships From: United States</li>
//       </ul>

//       <Typography variant="h6">Product Details</Typography>
//       <ul>
//         <li>The foam sockliner feels soft and comfortable.</li>
//         <li>Pull tab included.</li>
//         <li>Not intended for use as Personal Protective Equipment.</li>
//         <li>Color Shown: White/Black/Oxygen Purple/Action Grape</li>
//         <li>Style: 921826-109</li>
//         <li>Country/Region of Origin: China</li>
//       </ul>

//       <Typography variant="h6">Benefits</Typography>
//       <ul>
//         <li>
//           Mesh and synthetic materials on the upper keep the fluid look of the OG while adding comfort and durability.
//         </li>
//         <li>
//           Originally designed for performance running, the full-length Max Air unit adds soft, comfortable cushioning
//           underfoot.
//         </li>
//         <li>The foam midsole feels springy and soft.</li>
//         <li>The rubber outsole adds traction and durability.</li>
//       </ul>

//       <Typography variant="h6">Delivery and Returns</Typography>
//       <ul>
//         <li>Orders of $200 or more get free standard delivery.</li>
//         <li>Standard delivery: 4-5 Business Days</li>
//         <li>Express delivery: 2-4 Business Days</li>
//         <li>Orders are processed and delivered Monday-Friday (excluding public holidays).</li>
//       </ul>
//     </Box>
//   );
// };
