import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Box, Avatar, Typography } from '@mui/material';
import { useRenderImageHook } from 'hooks/useS3hook';
import Image from 'components/Image';
import { S3ImageSizes } from 'constants';

EventHero.propTypes = {
  event: PropTypes.object.isRequired,
};

export default function EventHero({ event }) {
  const { organization, mainEventImage: eventImages, eventName: title } = event;
  const mainEventImage = eventImages?.items?.[0] || {}; // profile image should be one
  const organizationProfileUrl = organization?.organizationProfilePhotoURLs?.items?.[0] || {}; // profile image should be one

  const eventImage = useRenderImageHook({ imageUrl: mainEventImage?.urlImage });

  const organizationProfilePhoto = useRenderImageHook({
    imageUrl: organizationProfileUrl?.urlImage,
    size: S3ImageSizes._300x300,
  });

  return (
    <Box sx={{ position: 'relative' }}>
      {/* <TitleStyle>asdfasdf</TitleStyle> */}

      <FooterStyle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            alt={`${organization?.organizationName}`}
            src={organizationProfilePhoto}
            sx={{ width: 48, height: 48 }}
          />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle1" sx={{ color: 'common.white' }}>
              {organization?.organizationName}
            </Typography>
          </Box>
        </Box>
      </FooterStyle>

      <OverlayStyle />
      <Image alt={title ?? 'Event Cover Image'} src={eventImage} ratio="16/9" />
    </Box>
  );
}

const OverlayStyle = styled('h1')(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 9,
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.1),
}));

const FooterStyle = styled('div')(({ theme }) => ({
  bottom: 0,
  zIndex: 10,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'flex-end',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(10),
  },
}));

// const TitleStyle = styled('h1')(({ theme }) => ({
//   ...theme.typography.h2,
//   top: 0,
//   zIndex: 10,
//   width: '100%',
//   position: 'absolute',
//   padding: theme.spacing(3),
//   color: theme.palette.common.white,
//   [theme.breakpoints.up('lg')]: {
//     padding: theme.spacing(10),
//   },
// }));

// ----------------------------------------------------------------------
