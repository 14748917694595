import { styled } from '@mui/material/styles';
import { Box, Link, Button, Divider, Typography, Stack } from '@mui/material';
import Iconify from 'components/Iconify';
import { DialogAnimate } from 'components/animate';
import { SeverErrorIllustration } from 'assets';
import { generatePath, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Routes, ProgressPage } from 'constants';
import { getPath, titleUrlFormatter } from 'utils';

// ----------------------------------------------------------------------

const DialogStyle = styled(DialogAnimate)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100% - 48px)',
      maxHeight: 'calc(100% - 48px)',
    },
  },
}));

// ----------------------------------------------------------------------

export default function CheckoutOrderCompleteFailed({ ...other }) {
  const history = useHistory();
  const { id: eventId, title: eventName } = useParams();

  const handleRedirect = () => {
    history.push(
      generatePath(getPath(Routes.EVENTREGISTRATION), {
        title: titleUrlFormatter(eventName),
        id: eventId,
        progressPage: ProgressPage.checkout,
      })
    );
  };

  return (
    <DialogStyle fullScreen {...other}>
      <Box sx={{ p: 4, maxWidth: 480, margin: 'auto' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4" paragraph>
            Sorry we cannot process you registration right now
          </Typography>

          <SeverErrorIllustration sx={{ height: 260, my: 10 }} />

          <Typography align="left" paragraph>
            Common Issue might be &nbsp;
          </Typography>

          <Typography align="left" sx={{ color: 'text.secondary' }}>
            1. &nbsp; &nbsp; Price changes upon checkout.
            <br /> <br />
            2. &nbsp; &nbsp; Slots are full. <br /> <br /> 3. &nbsp; &nbsp; You don't have enough balance on your
            account'
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Stack direction={{ xs: 'column-reverse', sm: 'row' }} justifyContent="space-between" spacing={2}>
          <Button color="inherit" onClick={handleRedirect} startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}>
            Back to checkout
          </Button>
        </Stack>
      </Box>
    </DialogStyle>
  );
}
