import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getPath } from 'utils/routes';
import { Routes } from 'constants/index';

export const SecureRoutes = ({
  userAuth,
  isPublic = false,
  component: Component,
  organizersRoute = false,
  isAdminPortalRoute = false,
  path,
  ...routeProps
}) => {
  const { isAuth, isEmailConfirmed } = userAuth;
  const securedUserRoute = isAuth && isEmailConfirmed;

  if (isPublic) {
    return <Route path={path} {...routeProps} render={() => Component} />;
  }

  // Organizer's Routes Configurations
  if (organizersRoute && securedUserRoute) {
    return <Route path={path} {...routeProps} render={() => Component} />;
  }

  if (isAdminPortalRoute) {
    return <Route path={path} {...routeProps} render={() => Component} />;
  }

  // User's Routes Configurations
  return isAuth ? (
    <Route path={path} {...routeProps} render={() => Component} />
  ) : (
    <Redirect to={getPath(Routes.EVENTS)} exact />
  );
};
