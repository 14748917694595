import { handleActions } from 'redux-actions';
import { v4 as uuidv4 } from 'uuid';
import { CREATE_ALERT, REMOVE_ALERT, REMOVE_ALL_ALERTS } from './types';

const initialState = {
  alerts: [],
};

const ACTION_HANDLERS = {
  [CREATE_ALERT]: {
    next: (state, action) => {
      return [...(state.alerts || []), { id: uuidv4(), ...action.payload.alert }];
    },
  },
  [REMOVE_ALERT]: {
    next: (state, action) => {
      const id = action.payload;
      const alerts = state.filter((alert) => alert.id !== id) || state.alerts || [];
      return alerts;
    },
  },
  [REMOVE_ALL_ALERTS]: () => ({
    next: (state, action) => ({
      alerts: [],
    }),
  }),
};

export default handleActions(ACTION_HANDLERS, initialState);
